<a id="btnDownload" class="d-none" href="javascript:void('');"
   [attr.download]="getNameFile('user')">DownloadFakeBtn</a>
<div class="page-header full bordered">
    <div class="container justify-content-between">
        <h2 class="page-header__title single">Usuários</h2>


        <div class="button_section">

            <div class="import-status" *ngIf="currentUser?.profile == 'admin'">
                <div class="status-date">
                    {{ 'lastImportInfo' | translate }}: <span>{{ importStatus.createdAt | date: "dd/MM/yyyy 'às' HH:mm" : 'UTC' }}</span>
                </div>
                <div class="status-text">
                    Status: <span [ngClass]="importStatus.status">{{ importStatus.status | translate }}</span>
                </div>
            </div>

            <a class="btn btn-gray button_section_user-buttons d-flex align-items-center justify-content-center"
               type="button" href="assets/modelo_import_user.xlsx"
               *ngIf="currentUser?.profile == 'admin'">
                <span class="mr-2"> {{ 'model' | translate }}</span>
                <svg-icon src="assets/svg/excel.svg"></svg-icon>
            </a>

            <label for="import"
                   class="btn btn-gray mb-0 button_section_user-buttons d-flex align-items-center justify-content-center"
                   *ngIf="currentUser?.profile == 'admin'">
                <span class="mr-2"> {{ 'importar'|translate }}</span>
                <svg-icon src="assets/svg/upload.svg"></svg-icon>
                <input class="d-none" type="file" id="import" name="import" (change)="imporExcel($event)"
                       [disabled]="importStatus.status == 'processing'"
                       accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
            </label>

            <button class="btn btn-gray mb-0 button_section_user-buttons d-flex align-items-center justify-content-center"
                    *ngIf="currentUser?.profile == 'admin'" (click)="getExcel()">
                <span class="mr-2">{{ 'excel' | translate }}</span>
                <svg-icon src="assets/svg/download.svg"></svg-icon>
            </button>

<!--            <button class="btn btn-gray mb-0 button_section_user-buttons d-flex align-items-center justify-content-center"-->
<!--                    *ngIf="currentUser?.profile == 'admin'" (click)="getExcel()">-->
<!--                <span class="mr-2">{{ 'importExcel' | translate }}</span>-->
<!--                <svg-icon src="assets/svg/download.svg"></svg-icon>-->
<!--            </button>-->

            <button class="btn btn-success btn-sm order-3 add-profile"
                    *ngIf="this.currentUser?.profile !== 'consultant'"
                    (click)="actionNew()"
                    [disabled]="importStatus.status == 'processing'">
                {{ 'adicionar' | translate }}
            </button>
        </div>
    </div>
</div>

<div class="page-content full">
    <div class="container">

        <div class="d-flex flex-wrap align-items-end page-content__filter mt-3 mt-md-5" style="gap: 25px">
            <div class="flex-grow-1">
                <div class="input-material mb-0">
                    <input id="keyword" [(ngModel)]="filter.keyword" class="form-control" type="text" required/>
                    <label for="keyword">
                        <svg-icon src="assets/svg/search.svg"></svg-icon>
                        {{ 'buscar' | translate }}
                    </label>
                </div>
            </div>
            <div class="flex-grow-1 d-flex flex-column">
                <div class="input-material mb-0">
                    <select id="country" class="form-control" name="type" [(ngModel)]="filter.country">
                        <option selected
                                [ngValue]="null">{{ 'minhalista.input3.subtitle' | translate }}
                        </option>
                        <option *ngFor="let c of countries | async" [ngValue]="c.id">{{ c.name }}</option>
                    </select>
                    <label for="country" class="mb-0 mr-3">País</label>
                </div>
            </div>
            <div class="flex-grow-1">
                <div class="input-material mb-0">
                    <select id="profile" class="form-control" name="type" [(ngModel)]="filter.profile">
                        <option selected
                                [ngValue]="null">{{ 'minhalista.input3.subtitle' | translate }}
                        </option>
                        <option [ngValue]="'user'">Colaborador</option>
                        <option [ngValue]="'hr_development'">{{ getProfileNameByRole('hr_development') }}</option>
                        <option [ngValue]="'manager'">{{ getProfileNameByRole('manager') }}</option>
                        <option [ngValue]="'director'">{{ getProfileNameByRole('director') }}</option>
                        <option [ngValue]="'consultant'">{{ getProfileNameByRole('consultant') }}</option>
                        <option [ngValue]="'admin'">{{
                                getProfileNameByRole('admin' +
                                    '')
                            }}
                        </option>
                    </select>
                    <label for="profile" class="mb-0 mr-3">Profile</label>
                </div>
            </div>
            <div class="flex-grow-1">
                <div class="input-material mb-0">
                    <select id="unity" class="form-control" name="type" [(ngModel)]="filter.unity">
                        <option selected
                                [ngValue]="null">{{ 'minhalista.input3.subtitle' | translate }}
                        </option>
                        <option *ngFor="let u of unities | async" [ngValue]="u.id">{{ u.name }}</option>
                    </select>
                    <label for="unity" class="mb-0 mr-3">Unidade</label>
                </div>
            </div>
            <!-- STATUS -->
            <div class="flex-grow-1">
                <div class="input-material mb-0">
                    <select id="status-filter" class="form-control" name="status-filter" [(ngModel)]="filter.status">
                        <option [ngValue]="null" [selected]="!filter.status">
                            {{ 'minhalista.input3.subtitle' | translate }}
                        </option>
                        <option [ngValue]="'active'">Ativo</option>
                        <option [ngValue]="'inactive'">Inativo</option>
                    </select>
                    <label for="status-filter" class="mb-0 mr-3">Status</label>
                </div>
            </div>
            <div class="flex-grow-1">
                <button (click)="onFilter()"
                        class="btn btn-block btn-primary">{{ 'solicitacaolista.filter' | translate }}
                </button>
            </div>
            <div class="flex-grow-1">
                <button (click)="cleanFilter()"
                        class="btn btn-block btn-primary">{{ 'solicitacaolista.cleanFilter' | translate }}
                </button>
            </div>
        </div>

        <div class="page-content__list mt-4 mb-3">
            <div class="table-responsive">
                <table class="table" cellpadding="0" cellspacing="0" border="0">
                    <thead>
                    <tr>
                        <!--                <th scope="col">ID</th>-->
                        <th scope="col">Usuário</th>
                        <th scope="col">Status</th>
                        <th scope="col">Profile</th>
                        <th scope="col">E-mail</th>
                        <th scope="col">Unidade</th>
                        <th scope="col">País</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngIf="this.isNullOrUndefined(userPage.results)">
                        <td colspan="7">
                            <p class="text-center py-5">Nenhum resultado encontrado.</p>
                        </td>
                    </tr>
                    <tr *ngFor="let x of userPage.results|
                paginate: {itemsPerPage: userPage.pageSize,
                currentPage: userPage.currentPage,
                totalItems: userPage.totalCount }">
                        <td>{{ x.name }}</td>
                        <td>{{ getProfileNameByRole(x.status) }}</td>
                        <td>{{ getProfileNameByRole(x.profile) }}</td>
                        <td>{{ x.email }}</td>
                        <td>{{ x.unity?.name }}</td>
                        <td>{{ x.country?.name }}</td>
                        <td>
                            <button (click)="actionEdit(x)" [disabled]="importStatus.status == 'processing'">
                                <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                            </button>
<!--                            <button (click)="actionDelete(x.id)" *ngIf="this.currentUser?.profile !== 'consultant'">-->
<!--                                <svg-icon src="assets/svg/trash.svg"></svg-icon>-->
<!--                            </button>-->
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="text-right mt-3">
                <pagination-controls
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
        </div>

    </div>
</div>


<!--MODAL USER-->
<div class="modal-custom modal fade" id="modalUser" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="header-title">
                    Usuário
                </h3>
                <a href="javascript:void('');"
                   class="btn "
                   type="button" (click)="closeModal('modalUser')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row my-3">
                        <!--NAME-->
                        <div class="col-12 col-sm-8">
                            <div class="input-material">
                                <input id="nameUser" class="form-control" type="text"
                                       required [(ngModel)]="model.name" maxlength="150"/>
                                <label for="nameUser">Nome</label>
                            </div>
                        </div>
                        <!--RE-->
                        <div class="col-12 col-sm-4">
                            <div class="input-material">
                                <input id="reUser" class="form-control" type="text"
                                       required [(ngModel)]="model.re" maxlength="15"/>
                                <label for="reUser">RE</label>
                            </div>
                        </div>
                        <!--EMAIL-->
                        <div class="col-12">
                            <div class="input-material">
                                <input id="userEmail" class="form-control" type="text"
                                       required [(ngModel)]="model.email" maxlength="150"/>
                                <label for="userEmail">E-mail</label>
                            </div>
                        </div>
                        <!-- PROFILE DROPDOWN -->
                        <div class="col-12 col-sm-6">
                            <div class="input-material flex-grow-1">
                                <select id="profileUser" class="form-control" name="type" [(ngModel)]="model.profile">
                                    <option selected
                                            [ngValue]="undefined">{{ 'minhalista.input3.subtitle' | translate }}
                                    </option>
                                    <option [ngValue]="'user'">Colaborador</option>
                                    <option [ngValue]="'hr_development'">{{ getProfileNameByRole('hr_development') }}</option>
                                    <option [ngValue]="'manager'">{{ getProfileNameByRole('manager') }}</option>
                                    <option [ngValue]="'director'">{{ getProfileNameByRole('director') }}</option>
                                    <option [ngValue]="'consultant'">{{ getProfileNameByRole('consultant') }}</option>
                                    <option [ngValue]="'admin'">{{ getProfileNameByRole('admin') }}</option>
                                </select>
                                <label for="profileUser" class="mb-0 mr-3">Perfil</label>
                            </div>
                        </div>
                        <!-- COUNTRY DROPDOWN -->
                        <div class="col-12 col-sm-6">
                            <div class="input-material flex-grow-1">
                                <select id="countryUser" class="form-control" name="type" [(ngModel)]="userCountry">
                                    <option selected
                                            [ngValue]="null">{{ 'minhalista.input3.subtitle' | translate }}
                                    </option>

                                    <option *ngFor="let c of countries | async" [ngValue]="c.id">{{ c.name }}</option>
                                </select>
                                <label for="countryUser" class="mb-0 mr-3">País</label>
                            </div>
                        </div>
                        <!-- UNITY DROPDOWN -->
                        <div class="col-12 col-sm-6">
                            <div class="input-material flex-grow-1">
                                <select id="unityUser" class="form-control" name="type" [(ngModel)]="userUnity">
                                    <option selected
                                            [ngValue]="null">{{ 'minhalista.input3.subtitle' | translate }}
                                    </option>
                                    <option *ngFor="let u of unities | async" [ngValue]="u.id">{{ u.name }}</option>
                                </select>
                                <label for="unityUser" class="mb-0 mr-3">Unidade</label>
                            </div>
                        </div>
                        <!--COST CENTER-->
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <input id="userCost" class="form-control" type="text"
                                       required [(ngModel)]="model.costCenter" maxlength="150"/>
                                <label for="userCost">Centro de Custo</label>
                            </div>
                        </div>
                        <!-- MANAGER -->
                        <div class="col-12 col-sm-6" *ngIf="model.profile != 'manager'">
                            <div class="input-material">
                                <ng-multiselect-dropdown
                                        [placeholder]="'&nbsp;'"
                                        [settings]="dropdownSettingsUnities"
                                        [data]="managers"
                                        [(ngModel)]="manager">
                                </ng-multiselect-dropdown>
                                <label class="disabled">Gestor</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="input-material">
                                <ng-multiselect-dropdown
                                        [placeholder]="'&nbsp;'"
                                        [settings]="dropdownSettingsUnities"
                                        [data]="directors"
                                        [(ngModel)]="director">
                                </ng-multiselect-dropdown>
                                <label class="disabled">Diretor</label>
                            </div>
                        </div>
                        <!-- STATUS -->
                        <div class="col-6">
                            <div class="input-material flex-grow-1">
                                <select id="statusUser" class="form-control" name="statusUser" [(ngModel)]="userStatus">
                                    <option [ngValue]="'active'" [selected]="userStatus === 'active'">Ativo</option>
                                    <option [ngValue]="'inactive'" [selected]="userStatus === 'inactive'">Inativo</option>
                                </select>
                                <label for="statusUser" class="mb-0 mr-3">Status</label>
                            </div>
                        </div>
                        <!-- COMPETENCY -->
                        <div class="col-6">
                            <div class="input-material">
                                <input class="form-control" id="admissionDate" name="admissionDate" type="text" maxlength="250"
                                       [clearIfNotMatch]="true"
                                       [mask]="'d0/M0/0000'"
                                       [leadZeroDateTime]="true"
                                       [dropSpecialCharacters]="false"
                                       [(ngModel)]="admissionDate"/>
                                <label class="disabled">{{'dataadmissao'|translate}}*</label>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="input-material flex-grow-1">
                                <select id="competencyTest" class="form-control" name="competencyTest" [(ngModel)]="competencyTest">
                                    <option [ngValue]="'SIM'" [selected]="competencyTest === 'SIM'">Sim</option>
                                    <option [ngValue]="'NÃO'" [selected]="competencyTest === 'NÃO'">Não</option>
                                    <option [ngValue]="'N/A'" [selected]="competencyTest === 'N/A'">N/A</option>
                                </select>
                                <label for="competencyTest" class="mb-0 mr-3">{{'treinamento.competencyTest'|translate}}</label>
                            </div>
                        </div>
                        <!-- SAVE BTN -->
                        <div class="col-12 my-3">
                            <button class="btn btn-block btn-warning mt-3"
                                    *ngIf="this.currentUser?.profile !== 'consultant'" (click)="actionSave()">Salvar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


