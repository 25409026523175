import { Injectable } from '@angular/core';
import {Solicitation, SolicitationLogByIdGQL} from "../../generated/graphql";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SolicitationLogService {
  constructor(private solicitationLogByIdGQL: SolicitationLogByIdGQL) {}

  getSolicitationLog(logId: string): Observable<Solicitation> {
    return this.solicitationLogByIdGQL
        .watch({ logId })
        .valueChanges.pipe(map(({ data }) => data.solicitationLogById.data as Solicitation));
  }
}
