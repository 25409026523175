import { Component, OnInit } from '@angular/core';
import {
  ChangeSolicitationStatusGQL,
  ColaboratorSolicitationsByUserIdGQL, CreateAdminObservationGQL, FlowChangeGQL,
  GetSolicitationGQL, GetSolicitationQuery, LogBySolicitationIdGQL, ReturnActionStepGQL, Solicitation,
  SolicitationLog, SolicitationLogByIdGQL, User
} from '../../../generated/graphql';
import {ActivatedRoute, Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {UserService} from '../../service/user.service';
import {TranslateService} from '@ngx-translate/core';
import {BaseComponent} from '../../base/base.component';
import {QueryRef} from "apollo-angular";
import {ChangeSolicitation} from "../../model/model";

@Component({
  selector: 'app-log-bolsa-estudo',
  templateUrl: './log-bolsa-estudo.component.html',
  styleUrls: ['./log-bolsa-estudo.component.scss']
})
export class LogBolsaEstudoComponent extends BaseComponent implements OnInit {

  solicitationLog: Solicitation = new Solicitation();
  parmId: string;
  user: User = new User();
  showTooltip: boolean[] = [];
  yearNow: any;
  solicitationQuery: QueryRef<GetSolicitationQuery>;
  response: any;
  documentType = 'CNPJ';
  observationAdmin: any;

  constructor(public router: Router,
              public router2: ActivatedRoute,
              private solicitationLogByIdGQL: SolicitationLogByIdGQL,
              public loading: NgxSpinnerService,
              public userService: UserService,
              public translate: TranslateService) {
    super(router, translate);
  }

  ngOnInit() {
    this.router2.params.subscribe(url => {
          if (!this.isNullOrUndefined(url)) {
            this.parmId = url.id;

            this.userService.user.subscribe({
              next: data => {
                this.user = data;
                this.getSolicitationLog();
              }, error: err => super.onError(err)
            });

            // this.dataChangeSolicitation.id = url.id;
            this.yearNow = (new Date()).getFullYear();

            this.handlerTitlesBoxVertical();
            setTimeout(() => {
              this.handlerTitlesBoxVertical();
            }, 1000);
          }
        }
    );
  }

  getSolicitationLog() {
    this.solicitationLogByIdGQL
        .watch({logId: this.parmId})
        .valueChanges
        .subscribe(({ data }) => {
          this.solicitationLog = data.solicitationLogById.data as Solicitation;
          console.log('SOLICITATION LOG', data);
        });
  }

  getNameRejected() {
    let lastRejectedUser = null;
    for (let i = this.solicitationLog?.solicitationStep.length - 1; i >= 0; i--) {
      const step = this.solicitationLog?.solicitationStep[i];
      if (step.status === 'rejected') {
        lastRejectedUser = step.user?.name;
        break;
      }
    }
    return lastRejectedUser;
  }

  onShowTooltip(index: number, show: boolean): void {
    this.showTooltip[index] = show;
  }

  svgType(type) {
    switch (type) {
      case 'xlsx':
      case 'xls':
        return 'assets/svg/icon-metro-file-excel.svg';
      case 'docx':
      case 'doc':
      case 'txt':
        return 'assets/svg/icon-metro-file-word.svg';
      case 'png':
      case 'jpg':
        return 'assets/svg/icon-metro-file-image.svg';
      case 'ppt':
      case 'pptx':
        return 'assets/svg/icon-metro-file-powerpoint.svg';
      default:
        return 'assets/svg/icon-metro-file-pdf.svg';
    }
  }

}
