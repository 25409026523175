<div class="page-header">
    <div class="container justify-content-center">
        <a [routerLink]="['/lista-de-solicitacoes']" class="page-header__back">
            <svg-icon src="assets/svg/back.svg"></svg-icon>
        </a>
        <h2 class="page-header__title"><strong>
            {{ (solicitationLog.father?.type === 'scholarship' ? 'versaosolicitacaoreembolso' : 'versaosolicitacaoreembolso') | translate }}
        </strong></h2>

        <ng-container *ngIf="user?.profile === 'admin' && solicitationLog?.status === 'approved'">
            <div class="page-header__btn">
                <button class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 mr-md-0 fs-16"
                        [routerLink]="solicitationLog.father?.type === 'language' ? ('/reembolsos-idiomas-admin/' + solicitationLog.id) : ('/reembolsos-admin/' + solicitationLog.id)">
                    Editar formulário
                </button>
            </div>
        </ng-container>
    </div>
</div>

<div class="reason-rejected" *ngIf="solicitationLog?.status === 'rejected'">
    <div class="accordion" id="accordionExample" style="width: 100%">
        <div style="background: #D81212;">
            <button class="btn btn-block text-left review-button" type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div class="d-flex justify-content-between" style="align-items: self-end">
                    <h4 class="text-primary font-weight-bolder">
                        <strong>{{ 'reprovadopor'|translate }} {{ getNameRejected() }}</strong>
                    </h4>
                    <h4 class="text-primary font-weight-bolder"><strong>{{ 'motivoreprova'|translate }}</strong></h4>
                    <svg-icon src="assets/svg/expand.svg"></svg-icon>
                </div>
            </button>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                 data-parent="#accordionExample"
                 style="background: #FFA500;">
                <div class="card-body pt-0 pb-2" style="background: #D81212;">
                    <span class="text-primary" style="font-size: 18px"> {{ solicitationLog?.response }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="page-content">
    <div class="container">
        <div class="page-content__status">
            <div class="custom-nav full mt-3 pl-5 pl-lg-0 pt-4 pr-0 pr-md-3 pt-lg-0">
                <!-- VARIAÇÃO DE STATUS: approving, rejected e approved -->
                <div class="infos infos--single justify-content-between no-border" [ngClass]="solicitationLog?.status">
                    <span class="number">
                        <span class="page-header__number mt-1">Nº <strong>{{ solicitationLog?.code }}</strong></span>
                    </span>
                    <span class="ml-3 text flex-column align-items-start tag tag-secondary tag-left color">
                        <span class="head small">Status</span>
                        <strong>{{ getStatusName(solicitationLog?.status) }}</strong>
                    </span>
                    <span class="text flex-column align-items-start">
                        <span class="head small">{{ 'atualizado' | translate }}</span>
                        <span class="color">{{ solicitationLog?.updatedAt | date:'dd/MM/yyyy':'UTC+0' }}</span>
                    </span>
                    <span class="text flex-column align-items-start mr-lg-auto">
                        <span class="head small">{{ 'criado' | translate }}</span>
                        <span class="text-primary">{{ solicitationLog?.createdAt | date:'dd/MM/yyyy':'UTC+0' }}</span>
                    </span>
                </div>
                <ul class="nav nav-tabs custom-nav__menu">
                    <li class="nav-item " *ngFor="let s of solicitationLog?.solicitationStep; let i = index">
                        <span class="nav-link" [ngClass]="{'active':s.step === solicitationLog?.step,
                              'done':s.status === 'approved',
                              'error':s.status === 'rejected'}">
<!--                            <span>2</span>-->
                            <div class="bubble"></div>
                            <span class="tooltip-label label is-cursor"
                                  (mouseenter)="onShowTooltip(i, true)"
                                  (mouseleave)="onShowTooltip(i, false)">
                                {{getProfileNameByRole(s.role)}}</span>
                            <span class="responsible-name"*ngIf="showTooltip[i]">{{s.responsibleName}}</span>
                        </span>
                    </li>
                </ul>
            </div>
        </div>

        <ng-container *ngFor="let c of solicitationLog?.father?.colaborators">
            <article *ngIf="this.isNullOrUndefined(solicitationLog.colaboratorRefund?.id)
            || solicitationLog.colaboratorRefund?.id == c.id" class="page-content__details box mb-md-4">
                <div class="page-content__details__tab">{{ 'colaborador'|translate }}</div>
                <div class="row">
                    <div class="col-6 col-sm-4 col-lg-4">
                        <h4 class="title">{{ 'nome'|translate }}</h4>
                        <p class="text">{{ c.name }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">RE</h4>
                        <p class="text">{{ c.re }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{ 'unidade'|translate }}</h4>
                        <p class="text">{{ c.unity.name }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-6 col-sm-4">
                        <h4 class="title">{{ 'responsible'|translate }}</h4>
                        <p class="text">{{ c.directorArea }}</p>
                    </div>
                    <div class="col-6 col-sm-4">
                        <h4 class="title">{{ 'departamento/setor'|translate }}</h4>
                        <p class="text">{{ c.areaManager }}</p>
                    </div>
                </div>
            </article>
        </ng-container>

        <article class="page-content__details box mb-md-4">
            <div class="page-content__details__tab">{{ 'curso'|translate }}</div>
            <div class="row">
                <ng-container *ngIf="solicitationLog.father.type === 'scholarship'">
                    <div class="col-6 col-sm-4">
                        <h4 class="title">{{ 'typeOfCourse'|translate }}</h4>
                        <p class="text">{{ solicitationLog?.father?.course?.type | translate }}</p>
                    </div>
                </ng-container>
                <ng-container *ngIf="solicitationLog.father.type === 'language'">
                    <div class="col-6 col-sm-4">
                        <h4 class="title">{{ 'bolsa.idiomaPretendeCursar' | translate }}</h4>
                        <p class="text">{{ solicitationLog?.father?.course?.language | translate }}</p>
                    </div>
                </ng-container>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'nomeinstituicao'|translate }}</h4>
                    <p class="text">{{ solicitationLog?.father?.course?.name }}</p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'datamatricula'|translate }}</h4>
                    <p class="text">{{ solicitationLog?.father?.course?.start| date:'dd/MM/yyyy':'UTC+0' }}</p>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'dataprocesso'|translate }}</h4>
                    <strong class="text">{{ solicitationLog?.father?.course?.registrationDate | date:'dd/MM/yyyy':'UTC+0' }}</strong>
                </div>
                <!--                <div class="col-6 col-sm-4">-->
                <!--                    <h4 class="title">{{'valorpago'|translate}}</h4>-->
                <!--                    <strong class="text">{{solicitationLog?.father?.course?.value | currency:getSymbolCurrency(solicitationLog?.course?.typeOfCurrency)}}</strong>-->
                <!--                </div>-->
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'valorrembolso'|translate }}</h4>
                    <strong class="text">{{ solicitationLog?.value | currency }}</strong>
                </div>
            </div>
        </article>

        <article class="page-content__details box mb-md-4 d-flex"
                 *ngIf="!this.isNullOrUndefined(solicitationLog?.signature)">
            <div class="page-content__details__tab">{{ 'signature'|translate }}</div>
            <img class="signature" src="{{solicitationLog?.signature}}" alt="signature"/>
        </article>

        <article class="page-content__details box mb-md-4" *ngIf="!isNullOrUndefined(solicitationLog?.receipts)">
            <div class="page-content__details__tab">{{ 'comprovante'|translate }}</div>
            <div class="row justify-content-center pt-3">
                <div class="col-12 col-md-4 text-center" *ngFor="let r of solicitationLog?.receipts">
                    <h4 class="title large">{{ getReceiptName(r.type) }}</h4>
                    <a [href]="r.url" target="_blank" class="attachment d-flex flex-column align-items-center">
                        <img class="attach" *ngIf="r.extension !== 'pdf'" src="{{r.url}}" alt="receipts"/>
                        <!--                        <svg-icon [svgStyle]="{'width':'30px','height':'30px'}" src="assets/svg/icon-metro-file-pdf.svg"></svg-icon>-->
                        <svg-icon class="my-2" [svgStyle]="{'width':'30px','height':'30px'}"
                                  [src]="svgType(r.extension)"></svg-icon>
                        <p>{{ r.name }}.{{ r.extension }}</p>
                    </a>
                </div>
            </div>
        </article>

        <ng-container *ngIf="user.profile === 'admin' && !isNullOrUndefined(solicitationLog?.adminObservation)">
            <article class="page-content__details box mb-md-4">
                <div class="page-content__details__tab">{{ 'observacao' | translate }}</div>
                <div class="row pt-md-4">
                    <div class="col-6 col-sm-6">
                        <h4 class="title">{{ 'observacao' | translate }}</h4>
                        <p class="text">{{ solicitationLog?.adminObservation }}</p>
                    </div>
                </div>
            </article>
        </ng-container>
    </div>
</section>
