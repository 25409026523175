import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxSpinnerModule} from 'ngx-spinner';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {LogoutComponent} from './views/logout/logout.component';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import { RegisterComponent } from './views/register/register.component';
import { HomeComponent } from './views/home/home.component';
import {AutocompleteComponent} from './util/autocomplete/autocomplete.component';
import { MyListComponent } from './views/my-list/my-list.component';
import { NotificationComponent } from './views/notification/notification.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { RegisterDetailComponent } from './views/register-detail/register-detail.component';
import { RequestsComponent } from './views/requests/requests.component';
import { UserComponent } from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import { BolsaDeEstudosComponent } from './views/bolsa-de-estudos/bolsa-de-estudos.component';
import { TreinamentosComponent } from './views/treinamentos/treinamentos.component';
import { BolsaDeIdiomasComponent } from './views/bolsa-de-idiomas/bolsa-de-idiomas.component';
import { ReembolsosComponent } from './views/reembolsos/reembolsos.component';
import { ListaDeSolicitacoesComponent } from './views/lista-de-solicitacoes/lista-de-solicitacoes.component';
import { DetalheDaBolsaComponent } from './views/detalhe-da-bolsa/detalhe-da-bolsa.component';
import { DetalheBolsaDeEstudosComponent } from './views/detalhe-bolsa-de-estudos/detalhe-bolsa-de-estudos.component';
import { DetalheReembolsoComponent } from './views/detalhe-reembolso/detalhe-reembolso.component';
import { DetalheBolsaDeIdiomaComponent } from './views/detalhe-bolsa-de-idioma/detalhe-bolsa-de-idioma.component';
import { DetalheTreinamentoComponent } from './views/detalhe-treinamento/detalhe-treinamento.component';
import {GraphQLModule} from './graphql.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {NgxMaskModule} from "ngx-mask";
import {CURRENCY_MASK_CONFIG, CurrencyMaskModule} from "ng2-currency-mask";
import {OnlyNumberDirective} from "./directive/only-number.directive";
import { ReembolsosIdiomasComponent } from './views/reembolsos-idiomas/reembolsos-idiomas.component';
import { ConfigPoliticComponent } from './views/config-politic/config-politic.component';
import { ConfigSalaryComponent } from './views/config-salary/config-salary.component';
import {LoginComponent} from "./views/login/login.component";
import {PaintScreenComponent} from "./views/paint-screen/paint-screen.component";
import { LogBolsaEstudoComponent } from './views/log-bolsa-estudo/log-bolsa-estudo.component';
import { LogTreinamentoComponent } from './views/log-treinamento/log-treinamento.component';
import { LogIdiomaComponent } from './views/log-idioma/log-idioma.component';
import { LogReembolsoComponent } from './views/log-reembolso/log-reembolso.component';

registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    SidebarComponent,
    LogoutComponent,
    RegisterComponent,
    HomeComponent,
    AutocompleteComponent,
    MyListComponent,
    NotificationComponent,
    RegisterDetailComponent,
    UserComponent,
    AuthenticateComponent,
    RequestsComponent,
    BolsaDeEstudosComponent,
    TreinamentosComponent,
    BolsaDeIdiomasComponent,
    ReembolsosComponent,
    ListaDeSolicitacoesComponent,
    DetalheDaBolsaComponent,
    DetalheBolsaDeEstudosComponent,
    DetalheReembolsoComponent,
    DetalheBolsaDeIdiomaComponent,
    DetalheTreinamentoComponent,
    OnlyNumberDirective,
    ReembolsosIdiomasComponent,
    ConfigPoliticComponent,
    ConfigSalaryComponent,
    LoginComponent,
    PaintScreenComponent,
    LogBolsaEstudoComponent,
    LogTreinamentoComponent,
    LogIdiomaComponent,
    LogReembolsoComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        FormsModule,
        NgxSpinnerModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgApexchartsModule,
        NgxPaginationModule,
        CurrencyMaskModule,
        NgxMaskModule.forRoot(),
        GraphQLModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgxMaskModule
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HeaderHttpInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL'
    },
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: {   // BRL
        align: 'left',
        allowNegative: true,
        decimal: ',',
        precision: 2,
        prefix: 'R$ ',
        suffix: '',
        thousands: '.'
      }
    },
    TranslateService,
    AppDataService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
