<div class="page-header full bordered">
    <div class="container justify-content-between">
        <h2 class="page-header__title single">{{ 'solicitacaolista.title' | translate }}</h2>
        <button class="btn btn-light btn-sm order-3"
                *ngIf="!isNullOrUndefined(this.currentUser)&&this.currentUser?.profile === 'admin'"
                (click)="getExcel()">{{ 'excel' | translate }}
        </button>
    </div>
</div>
<div class="page-content full">
    <div class="container">
        <div class="d-flex flex-wrap align-items-end page-content__filter mt-3 mt-md-5" style="gap: 25px">
            <div class="flex-grow-1">
                <div class="input-material mb-0">
                    <input id="keyword" [(ngModel)]="filter.keyword" class="form-control" type="text" required/>
                    <label for="keyword">
                        <svg-icon src="assets/svg/search.svg"></svg-icon>
                        {{ 'buscar' | translate }}
                    </label>
                </div>
            </div>
            <div class="flex-grow-1 d-flex flex-column">
                <span class="flex-grow-1 mb-2">{{ 'solicitacaolista.category' | translate }}</span>
                <div class="d-flex justify-content-between">
                    <a class="input_check" title="Bolsa de Estudos">
                        <input (change)="getSearch()" [(ngModel)]="category[0].checked" class="form-check-input"
                               type="checkbox" value=""
                               id="flexCheckStudy">
                        <label for="flexCheckStudy" class="checkmark mb-0">
                            <svg-icon src="assets/svg/study.svg"></svg-icon>
                        </label>
                    </a>
                    <a class="input_check" title="Treinamento">
                        <input (change)="getSearch()" [(ngModel)]="category[1].checked" class="form-check-input"
                               type="checkbox" value=""
                               id="flexCheckTraining">
                        <label for="flexCheckTraining" class="checkmark mb-0">
                            <svg-icon src="assets/svg/training.svg"></svg-icon>
                        </label>
                    </a>
                    <a class="input_check" title="Idioma">
                        <input (change)="getSearch()" [(ngModel)]="category[2].checked" class="form-check-input"
                               type="checkbox" value=""
                               id="flexCheckLanguage">
                        <label for="flexCheckLanguage" class="checkmark mb-0">
                            <svg-icon src="assets/svg/language.svg"></svg-icon>
                        </label>
                    </a>
                    <a class="input_check" title="Reembolso">
                        <input (change)="getSearch()" [(ngModel)]="category[3].checked" class="form-check-input"
                               type="checkbox" value=""
                               id="flexCheckReimbursement">
                        <label for="flexCheckReimbursement" class="checkmark mb-0">
                            <svg-icon src="assets/svg/reimbursement.svg"></svg-icon>
                        </label>
                    </a>
                </div>
            </div>
            <div class="flex-grow-1">
                <div class="input-material mb-0">
                    <select class="form-control" [(ngModel)]="filter.status" required>
                        <option selected
                                [ngValue]="null">Todos
                        </option>
                        <option [ngValue]="'completed'">{{ getStatusName('completed') }}</option>
                        <option [ngValue]="'approved'">{{ getStatusName('approved') }}</option>
                        <option [ngValue]="'pending'">{{ getStatusName('pending') }}</option>
                        <option [ngValue]="'rejected'">{{ getStatusName('rejected') }}</option>
                        <option [ngValue]="'canceled'">{{ getStatusName('canceled') }}</option>
                        <option [ngValue]="'review'">{{ getStatusName('review') }}</option>
                    </select>
                    <label>Status</label>
                </div>
            </div>
            <div class="flex-grow-1" *ngIf="currentUser?.profile === 'admin'">
                <div class="input-material mb-0" readonly="true" (click)="userSimulation()">
<!--                    <input class="form-control" readonly *ngIf="simulationUserName" [value]="simulationUserName">-->
<!--                    <select class="form-control" required>-->
<!--                        <option selected [ngValue]="null">Nenhum</option>-->
<!--                        <option *ngFor="let x of users" selected [ngValue]="x.id">{{ ( x.profile  | translate)+ ' | ' +x.name }}</option>-->
<!--                    </select>-->
                    <label>{{simulationUserName ? simulationUserName : 'Simulação'}}</label>
                </div>
            </div>
        </div>
        <div class="d-flex flex-wrap align-items-end page-content__filter mt-3" style="gap: 25px">
            <div *ngIf="currentUser?.profile === 'admin'" class="flex-grow-1">
                <div class="input-material mb-0">
                    <select class="form-control" [(ngModel)]="filter.role" required>
                        <option selected [ngValue]="undefined">{{ 'minhalista.input3.subtitle' | translate }}</option>
                        <option [ngValue]="'hr_development'">{{ getProfileNameByRole('hr_development') }}</option>
                        <option [ngValue]="'manager'">{{ getProfileNameByRole('manager') }}</option>
                        <option [ngValue]="'director'">{{ getProfileNameByRole('director') }}</option>
                    </select>
                    <label>{{ 'etapa'|translate }}</label>
                </div>
            </div>
            <div class="flex-grow-1">
                <div class="input-material mb-0">
                    <input id="dateRange" class="form-control js-daterangepicker" type="text" required/>
                    <label for="dateRange">
                        <svg-icon src="assets/svg/calendar.svg"></svg-icon>
                        <span>{{ 'solicitacaolista.period' | translate }}</span>
                    </label>
                </div>
            </div>
            <div class="flex-grow-1">
                <button (click)="filter.page = 0; getSearch()"
                        class="btn btn-block btn-primary">{{ 'solicitacaolista.filter' | translate }}
                </button>
            </div>
            <div class="flex-grow-1">
                <button (click)="cleanFilter()"
                        class="btn btn-block btn-primary">{{ 'solicitacaolista.cleanFilter' | translate }}
                </button>
            </div>
        </div>
        <div class="page-content__list mt-4 mb-3">
            <div class="row d-none d-lg-flex mb-2">
                <div class="col-md-2 text-center pr-0">
                    <span class="label">{{ 'solicitacaolista.category' | translate }}</span>
                </div>
                <div class="col-md-2 text-center">
                    <span class="label">{{ 'solicitacaolista.colaborador' | translate }}</span>
                </div>
                <div class="col-md-3 text-center">
                    <span class="label">{{ 'solicitacaolista.curso' | translate }}</span>
                </div>
                <div class="col-md-3 text-center">
                    <div class="row">
                        <div class="col-md-4 text-center">
                            <span class="label">{{ 'solicitacaolista.createdAt' | translate }}</span>
                        </div>
                        <div class="col-md-4 text-center">
                            <span class="label">{{ 'solicitacaolista.inicio' | translate }}</span>
                        </div>
                        <div class="col-md-4 text-center">
                            <span class="label">{{ 'solicitacaolista.fim' | translate }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-2 text-center pl-0">
                    <span class="label">Status</span>
                </div>
            </div>

            <!--<div *ngFor="let x of list | async">
                <span>{{x.name}}</span>
            </div>-->
            <div class="d-block text-center py-5" *ngIf="isLoaderSpinner">
                <div class="spinner-border"></div>
            </div>
            <div class="page-content__item" *ngFor="let x of list.results|
                paginate: {itemsPerPage: list.pageSize,
                id: 'solicitation',
                currentPage: list.currentPage,
                totalItems: list.totalCount }">
                <div class="row">
                    <div class="col-md-2 text-center">
                        <div class="text flex-md-row justify-content-between">
                            <div class="d-flex flex-column">
                                <div class="icon d-flex flex-column">
                                    <svg-icon [src]="svgType(x.type)"></svg-icon>
                                </div>
                                <p>{{ getTranslate(x.type) }}</p>
                            </div>
                            <span class="text number">{{ x.code }}</span>
                        </div>
                    </div>
                    <div class="col-md-2 text-center d-flex flex-column">
                        <span class="text name">{{ x?.user.name }}</span>
                    </div>
                    <div class="col-md-3 text-center d-flex justify-content-center">
                        <ng-container *ngIf="!isNullOrUndefined(x.course)">
                            <span class="text">{{ !isNullOrUndefined(x.course.name) ? (x.course?.name) : (x.course.language | translate) }}</span>
                        </ng-container>
                        <ng-container *ngIf="isNullOrUndefined(x.course)">
                            <span class="text">-</span>
                        </ng-container>
                    </div>
                    <div class="col-md-3 text-center">
                        <div class="row h-full">
                            <div class="col-md-4 text-center d-flex justify-content-center p-4 ">
                                <span class="text">{{ x.createdAt | date: 'dd/MM/yyyy':'UTC+0' }}</span>
                            </div>
                            <div class="col-md-4 text-center d-flex justify-content-center p-4">
                                <span class="text">{{ x.course?.start !== null ? (x.course?.start | date: 'dd/MM/yyyy':'UTC+0') : '-' }}</span>
                            </div>
                            <div class="col-md-4 text-center last d-flex justify-content-center p-4">
                                <span class="text">{{ x.course?.end !== null ? (x.course?.end | date: 'dd/MM/yyyy':'UTC+0') : '-' }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 text-center d-flex flex-column flex-md-row justify-content-center justify-content-md-between align-items-center last">
                        <span class="text tag" [ngClass]="x.status">
                            {{ getStatusName(x.status) }}
                            <br><ng-container
                                *ngIf="x.status == 'pending'">{{ getProfileNameByRole((currentUser.profile !== 'admin' && currentUser.profile !== 'hr_development') && x.role === 'admin' ? 'hr_development' : x.role) }}</ng-container>
                        </span>
                        <div class="d-flex" style="gap: 15px">
                            <a *ngIf="this.currentUser?.profile == 'admin' || this.currentUser?.profile == 'hr_development'"
                               href="javascript:void(0)" (click)="this.historySolicitation(x.id)" class="is-cursor">
                                <svg-icon src="assets/svg/history.svg"></svg-icon>
                            </a>
                            <a *ngIf="x.status == 'rejected' && this.currentUser.profile != 'user'"
                               href="javascript:void(0)" (click)="reopenSolicitation(x.id)" class="is-cursor">
                                <svg-icon src="assets/svg/refresh.svg"></svg-icon>
                            </a>
                            <a href="javascript:void(0)"
                               [routerLink]="getRouter(x.type,x.status,x.user.id,x.id,x.user.re)"
                               class="is-cursor">
                                <svg-icon *ngIf="x.status == 'pending'" src="assets/svg/message.svg"></svg-icon>
                                <svg-icon *ngIf="x.status != 'pending'" src="assets/svg/external.svg"></svg-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right mt-3">
                <pagination-controls
                        [id]="'solicitation'"
                        [previousLabel]="labels.previousLabel"
                        [nextLabel]="labels.nextLabel"
                        [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                        [screenReaderPageLabel]="labels.screenReaderPageLabel"
                        [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                        (pageChange)="pageChanged($event, 'solicitation')"></pagination-controls>
            </div>
            <div class="row" *ngIf="isNullOrUndefined(list,true)||isNullOrUndefined(list?.results)">
                <div class="col-12 text-center">
                    <p class="my-5">Nenhum registro encontrado</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="history" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 1140px;" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'historySolicitation' | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body">
                <div class="w-100">
                    <div class="row mb-4">
                        <dl class="col-3">
                            <dt>{{ 'solicitacao.code'| translate }}</dt>
                            <dd>{{ solicitationHistory.code != null ? solicitationHistory.code : '-' }}</dd>
                        </dl>
                        <dl class="col-3">
                            <dt>{{ 'solicitacaolista.category'| translate }}</dt>
                            <dd>{{ solicitationHistory.type !== null ? getTranslate(solicitationHistory.type) : '-' }}</dd>
                        </dl>
                        <dl class="col-3">
                            <dt>{{ 'colaborador' | translate }}</dt>
                            <dd>{{ solicitationHistory.user?.name != null ? solicitationHistory.user?.name : '-' }}</dd>
                        </dl>
                        <dl class="col-3">
                            <dt>{{ 'curso' | translate }}</dt>
                            <dd>{{ !isNullOrUndefined(solicitationHistory.course?.name) ? (solicitationHistory.course?.name) : (solicitationHistory.course?.language | translate) }}</dd>
                        </dl>
                    </div>
                    <div class="card mb-3 card-body" *ngFor="let x of solicitationHistory.solicitationHistory">
                        <div class="row">
                            <dl class="col-auto">
                                <dt>{{ 'userObject.perfil' | translate }}</dt>
                                <dd>{{ getProfileNameByRole(x.role) }}</dd>
                            </dl>
                            <dl class="col">
                                <dt>{{ 'review' | translate }}</dt>
                                <dd>{{ x.answer }}</dd>
                            </dl>
                            <dl class="col-auto">
                                <dt>{{ 'criado' | translate }}</dt>
                                <dd>{{ x.createdAt  | date: 'dd/MM/yyyy':'UTC+0' }}</dd>
                            </dl>
                            <dl class="col-auto">
                                <dt [ngClass]="x.status">{{ 'status' | translate }}</dt>
                                <dd><span [ngClass]="x.status">{{ getStatusName(x.status) }}</span></dd>
                            </dl>
                        </div>
                    </div>
                    <div class="page-content__form full overflow-auto ml-0 mr-0">
                        <table class="table table-custom  table-striped">
                            <thead class="white-space-nowrap">
                            <tr>
                                <th scope="col">{{ 'step' | translate }}</th>
                                <th scope="col">Status</th>
                                <th scope="col">{{ 'usuario' | translate }}</th>
                                <th scope="col">{{ 'dataDeAprovacao' | translate }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container>
                                <tr *ngFor="let s of solicitationHistory?.solicitationStep" >
                                    <td scope="row">{{ s?.role | translate }}</td>
                                    <td>{{ s?.status | translate }}</td>
                                    <td>{{ !isNullOrUndefined(s?.user?.name) ? s?.user?.name : '-' }}</td>
                                    <td>{{ s?.dateOfApproved | date:'dd/MM/yyyy':'UTC+0' }}</td>
                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="simulation" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Busque por um usuário</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body modal-body-custom flex-row flex-wrap">
                <div class="full mt-0 ml-0 mr-0 col-12">

                    <div class="d-flex gap-20">
                        <div class="input-material mb-0 flex-grow-1">
                            <input id="user-keyword" [(ngModel)]="filterUser.keyword" class="form-control" type="text" required/>
                            <label for="user-keyword">
                                <svg-icon src="assets/svg/search.svg"></svg-icon>
                                {{ 'buscar' | translate }}
                            </label>
                        </div>

                        <div class="d-flex align-items-center btn btn-primary" (click)="onSearchUser()">
                            Buscar
                        </div>
                    </div>

                    <div class="table-responsive">
                        <table class="table" cellpadding="0" cellspacing="0" border="0">
                            <thead>
                            <tr style="color: #fff">
                                <!--                <th scope="col">ID</th>-->
                                <th scope="col">Usuário</th>
                                <th scope="col">RE</th>
                            </tr>
                            </thead>
                            <tbody class="border">
                            <tr *ngIf="this.isNullOrUndefined(userPage.results)">
                                <td colspan="7">
                                    <p class="text-center py-5">Nenhum resultado encontrado.</p>
                                </td>
                            </tr>
                            <tr *ngFor="let x of userPage.results|
                            paginate: {itemsPerPage: userPage.pageSize,
                            id: 'simulation',
                            currentPage: userPage.currentPage,
                            totalItems: userPage.totalCount }"
                            class="is-cursor hover-tr"
                            (click)="setSimulationUser(x.id, x.name)">
                                <td>{{ x.name }}</td>
                                <td>{{ x.re }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-right mt-3" *ngIf="userPage.currentPage">
                        <pagination-controls
                                [id]="'simulation'"
                                [previousLabel]="labels.previousLabel"
                                [nextLabel]="labels.nextLabel"
                                [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                                [screenReaderPageLabel]="labels.screenReaderPageLabel"
                                [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                                (pageChange)="pageChanged($event, 'simulation')"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
