<div class="page-header">
    <div class="container justify-content-center">
        <a [routerLink]="['/lista-de-solicitacoes']" class="page-header__back">
            <svg-icon src="assets/svg/back.svg"></svg-icon>
        </a>
        <h2 class="page-header__title"><strong>{{ 'bolsaidioma'|translate }}</strong></h2>
            <div class="page-header__btn">
                <ng-container *ngIf="user?.profile === 'admin'">
                    <button class="btn btn-primary btn-fixed btn-lg mb-md-3 mr-md-4 fs-16"
                            [routerLink]="'/bolsa-de-idiomas-admin/' + solicitation.id">Editar formulário
                    </button>
                </ng-container>
                <ng-container *ngIf="user?.profile === 'admin'">
                    <button class="btn btn-primary btn-fixed btn-lg cancel mb-3 mr-4 fs-16"
                            *ngIf="user?.profile === 'hr_development' || user?.profile === 'admin'"
                            (click)="handleSuspendSolicitation()">
                        Suspender
                    </button>
                </ng-container>
            </div>
    </div>
</div>

<div class="reason-rejected" *ngIf="solicitation?.status === 'rejected'">
    <div class="accordion" id="accordionExample" style="width: 100%">
        <div style="background: #D81212;">
            <button class="btn btn-block text-left review-button" type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div class="d-flex justify-content-between" style="align-items: self-end">
                    <h4 class="text-primary font-weight-bolder">
                        <strong>{{ 'reprovadopor'|translate }} {{ getNameRejected() }}</strong>
                    </h4>
                    <h4 class="text-primary font-weight-bolder"><strong>{{ 'motivoreprova'|translate }}</strong></h4>
                    <svg-icon src="assets/svg/expand.svg"></svg-icon>
                </div>
            </button>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                 data-parent="#accordionExample"
                 style="background: #FFA500;">
                <div class="card-body pt-0 pb-2" style="background: #D81212;">
                    <span class="text-primary" style="font-size: 18px"> {{ solicitation.response }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="page-content">
    <div class="container">
        <div class="page-content__status">
            <!-- VARIAÇÃO DE STATUS: approving, rejected e approved -->
            <div class="infos no-border" [ngClass]="solicitation?.status">
                <span class="number">
                    <span class="page-header__number mt-1">Nº <strong>{{ solicitation?.code }}</strong></span>
                </span>
                <span class="ml-3 text flex-column align-items-start tag tag-secondary tag-left color">
                    <span class="head small">Status</span>
                    <strong>{{ getStatusName(solicitation?.status) }}</strong>
                </span>
                <span class="text flex-column align-items-start mr-lg-auto">
                    <span class="head small">{{ 'atualizado' | translate }}</span>
                    <span class="color">{{ solicitation?.updatedAt | date:'dd/MM/yyyy':'UTC+0' }}</span>
                </span>
<!--                <button class="btn btn-primary mr-4"-->
<!--                        (click)="showVersionHistory()">{{ 'visualizarhistorico'|translate }}-->
<!--                </button>-->
                <button class="btn btn-primary mr-4"
                        *ngIf="user.profile === 'admin' && solicitation.status == 'pending'"
                        (click)="handleResendEmailSolicitationPending()">{{ 'resendemailsolicitationpending'|translate }}
                </button>
                <span class="text flex-column align-items-start mr-0">
                    <span class="head small">{{ 'criado' | translate }}</span>
                    <span class="text-primary">{{ solicitation?.createdAt | date:'dd/MM/yyyy':'UTC+0' }}</span>
                </span>
            </div>
            <div class="custom-nav full mt-3">
                <!-- VARIAÇÃO DE STATUS: active, error e done -->
                <ul class="nav nav-tabs custom-nav__menu">
                    <ng-container *ngFor="let s of solicitation?.solicitationStep; let i = index">
                        <li class="nav-item" *ngIf="!(s.role === 'admin' && !['admin', 'hr_development'].includes(user.profile))">
                            <span class="nav-link"
                                  [ngClass]="{
                                      'active': i === solicitation?.step ||
                                                (solicitation?.solicitationStep[solicitation?.step]?.role === 'admin' &&
                                                !['admin', 'hr_development'].includes(user.profile) && s.role === 'hr_development'),
                                      'done': s.status === 'approved' ||
                                              (solicitation?.solicitationStep[solicitation?.step]?.role === 'admin' &&
                                              !['admin', 'hr_development'].includes(user.profile) && i <= solicitation?.step)
                                  }">
                                <ng-container *ngIf="user.profile === 'admin' && s.role === 'manager'">
                                    <div class="remove-flow" (click)="onSetChangeFlow(solicitation.id)">
                                        <svg-icon src="assets/svg/cancel.svg"></svg-icon>
                                    </div>
                                </ng-container>
                                <div class="bubble"></div>
                                <span class="tooltip-label label is-cursor">
                                    {{ getProfileNameByRole(s.role) }}
                                </span>
                                <span class="responsible-name">{{ formatResponsibleName(s.responsibleName) }}</span>
                            </span>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>

        <ng-container *ngFor="let c of solicitation?.colaborators">
            <article class="page-content__details box mb-md-4"
                     *ngIf="solicitation?.colaborators.length === 1 && c.isRequester">
                <div class="page-content__details__tab">{{ 'treinamento.requester'|translate }}</div>
                <div class="row">
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{ 'nome'|translate }}</h4>
                        <p class="text">{{ c.name }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3" *ngIf="!isNullOrUndefined(c.dateOfBirth)">
                        <h4 class="title">{{ 'bolsa.nascimento' | translate }}</h4>
                        <p class="text">{{ c.dateOfBirth | date:'dd/MM/yyyy':'UTC+0' }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-2">
                        <h4 class="title">RE</h4>
                        <p class="text">{{ c.re }}</p>
                    </div>
                </div>
                <ng-container *ngIf="false">
                    <hr>
                    <div class="row">
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{ 'corpele'|translate }}</h4>
                            <p class="text">{{ c.skinColor?.name }}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{ 'generosex'|translate }}</h4>
                            <p class="text">{{ c.genderIdentity?.name }}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{ 'orientsex'|translate }}</h4>
                            <p class="text">{{ c.sexualOrientation?.name }}</p>
                        </div>
                    </div>
                </ng-container>
                <hr>
                <div class="row">
                    <div class="col-6 col-sm-4 col-lg-2">
                        <h4 class="title">{{ 'unidade'|translate }}</h4>
                        <p class="text">{{ c.unity.name }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3 mt-sm-3 mt-lg-0">
                        <h4 class="title">{{ 'departamento/setor'|translate }}</h4>
                        <p class="text">{{ c.areaManager }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-2 mt-sm-3 mt-lg-0">
                        <h4 class="title">Ramal</h4>
                        <p class="text">{{ c.ramal }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{ 'cargo'|translate }}</h4>
                        <p class="text">{{ c.currentJob }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title mb-1">{{ 'bolsa.admissao' | translate }}</h4>
                        <p class="text" [ngClass]="user.profile == 'admin' && c.lessThanOneYear ? 'text-danger' : ''">{{ c.admissionDate | date:'dd/MM/yyyy':'UTC+0' }}</p>
                        <span class="admission-text" *ngIf="c.lessThanOneYear">
                          {{ 'admissaoMenorQue' | translate }}
                        </span>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{ 'centrocusto'|translate }}</h4>
                        <p class="text">{{ c.costCenter }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{ 'gerarea'|translate }}</h4>
                        <p class="text">{{ c.directorArea }}</p>
                    </div>
                </div>
            </article>
        </ng-container>

        <article class="page-content__details box mb-md-4" *ngIf="solicitation?.colaborators?.length > 1">
            <div class="page-content__details__tab">{{ 'treinamento.collaborator'| translate }}</div>
            <div class="row">
                <div class="col-12">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">{{ 'treinamento.name' | translate }}</th>
                            <th scope="col">{{ 'treinamento.re' | translate }}</th>
                            <th scope="col">{{ 'bolsa.nascimento' | translate }}</th>
                            <th scope="col">{{ 'treinamento.unity' | translate }}</th>
                            <th scope="col">{{ 'treinamento.departmentSector' | translate }}</th>
                            <th scope="col">{{ 'bolsa.admissao' | translate }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let c of solicitation?.colaborators">
                            <ng-container *ngIf="!c.isRequester">
                                <tr *ngIf="solicitation?.step === 0 || (solicitation?.step > 0 && c.status !== 'rejected')">
                                    <th scope="row">{{ c.name }}</th>
                                    <td>{{ c.re }}</td>
                                    <td>{{ c.dateOfBirth | date: 'dd/MM/yyyy':'UTC+0' }}</td>
                                    <td>{{ c.unity.name }}</td>
                                    <td>{{ c.directorArea }}</td>
                                    <td class="" style="gap: 10px" [ngClass]="user.profile == 'admin' && c.lessThanOneYear ? 'text-danger' : ''">
                                        {{ c.admissionDate | date:'dd/MM/yyyy':'UTC+0' }}
                                        <span class="admission-text d-flex" *ngIf="user.profile == 'admin' && c.lessThanOneYear">
                                          {{ 'admissaoMenorQue' | translate }}
                                        </span>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </article>


        <article class="page-content__details box mb-md-4">
            <div class="page-content__details__tab">{{ 'curso'|translate }}</div>
            <div class="row">
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{ 'bolsa.idiomaPretendeCursar'|translate }}</h4>
                    <p class="text">{{ solicitation?.course?.language | translate }}</p>
                </div>
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{ 'nivelconhecimento'|translate }}</h4>
                    <p class="text">{{ solicitation?.course?.level | translate }}</p>
                </div>
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{ 'dataini'|translate }}</h4>
                    <p class="text">{{ solicitation?.course?.start | date:'dd/MM/yyyy':'UTC+0' }}</p>
                </div>

                <ng-container *ngIf="user.profile === 'admin' && isStepAdmin">
                    <div class="col-6 col-sm-3">
                        <h4 class="title">{{ 'bolsa.percentRefund' | translate }}</h4>
                        <div class="d-flex">
                            <strong class="text">{{ solicitation?.percentRefund ? solicitation?.percentRefund + '%' : '' }}</strong>
                            <button class="btn-link"
                                    style="margin-top: 5px; margin-left: 6px"
                                    (click)="onUpdatePercentageRefund()"
                                    [disabled]="">
                                {{isNullOrUndefined(solicitation?.percentRefund) ? 'Definir Percentual' : ('alterar' | translate) }}
                            </button>
                        </div>
                    </div>
                </ng-container>
            </div>
        </article>

        <article class="page-content__details box mb-md-4">
            <div class="page-content__details__tab">{{ 'instituicao'|translate }}</div>
            <div class="row">
                <div class="col-6">
                    <h4 class="title">{{ 'nomeinstituicao'|translate }}</h4>
                    <p class="text">{{ solicitation?.institution.name }}</p>
                </div>
                <div class="col-6">
                    <h4 class="title">CNPJ</h4>
                    <p class="text">{{ solicitation?.institution.cnpj_cpf }}</p>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'unidade'|translate }}</h4>
                    <p class="text">{{ solicitation?.institution.unity }}</p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'telefonecontato'|translate }}</h4>
                    <p class="text">{{ solicitation?.institution.phone|mask:'(99) 9999-9999|(99) 99999-9999' }}</p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'contatoinstituicao'|translate }}</h4>
                    <p class="text">{{ solicitation?.institution.responsible }}</p>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6">
                    <h4 class="title">{{ 'endereco'|translate }}</h4>
                    <p class="text">{{ solicitation?.institution.address }}</p>
                </div>
                <div class="col-6">
                    <h4 class="title">{{ 'emailcontato' | translate }}</h4>
                    <p class="text">{{ solicitation?.institution.email }}</p>
                </div>
            </div>
        </article>

        <article class="page-content__details box mb-md-4">
            <div class="page-content__details__tab">{{ 'comprovante'|translate }}</div>
            <div class="row justify-content-center pt-3">
                <div class="col-12 col-md-6 mb-4" *ngIf="!isNullOrUndefined(solicitation?.receiptJustification)">
                    <h4 class="title pt-5">{{ 'justificativasolicitacao'|translate }}</h4>
                    <p class="text">{{ solicitation?.receiptJustification }}</p>
                    <div class="flex-column d-flex justify-content-center gap-20 mt-5">
                        <ng-container *ngIf="solicitation.isAcceptPolicy">
                            <p class="text">Declaro estar ciente com a Política Bolsa de Idiomas</p>
                        </ng-container>
                        <ng-container *ngIf="solicitation.isAcceptTerms">
                            <p class="text">Declaro estar ciente com o Termo de Ciência</p>
                        </ng-container>
                    </div>
                </div>
                <div class="d-flex flex-column col-6 col-md-6">
                    <div class="col-6 text-center d-flex flex-column justify-content-center"
                         *ngFor="let r of solicitation?.receipts">
                        <h4 class="title large">{{ getReceiptName(r.type) }}</h4>
                        <a [href]="r.url" target="_blank" class="attachment" download>
                            <img class="attach" *ngIf="r.extension !== 'pdf'" src="{{r.url}}" alt="receipts"/>
                            <svg-icon [svgStyle]="{'width':'30px','height':'30px'}"
                                      [src]="svgType(r.extension)"></svg-icon>
                            <p>{{ r.name }}.{{ r.extension }}</p>
                        </a>
                    </div>
                </div>

            </div>

        </article>

        <article class="page-content__details box mb-md-4 d-flex"
                 *ngIf="!this.isNullOrUndefined(solicitation?.signature)">
            <div class="page-content__details__tab">{{ 'signature'|translate }}</div>
            <img class="signature" src="{{solicitation?.signature}}" alt="signature"/>
        </article>

        <ng-container *ngIf="flagLanguageCouses">
            <article class="page-content__details box mb-md-4  gap-20">
                <div class="page-content__details__tab">{{ 'escolaridade'|translate }}</div>
                <div class="row mb-3" *ngFor="let s of solicitation?.languageSchool">
                    <div class="col-6">
                        <h4 class="title">{{ 'curso'|translate }}</h4>
                        <p class="text">{{ s.name }}</p>
                    </div>
                    <div class="col-6">
                        <h4 class="title">{{ 'tempo'|translate }}</h4>
                        <p class="text">{{ s.time }}</p>
                    </div>
                </div>
            </article>
        </ng-container>

        <ng-container *ngIf="(user.profile === 'admin' || user.profile === 'hr_development') && !isNullOrUndefined(solicitation?.adminObservation)">
            <article class="page-content__details box mb-md-4">
                <div class="page-content__details__tab">{{ 'observacao' | translate }}</div>
                <div class="row pt-md-4">
                    <div class="col-6 col-sm-6">
                        <h4 class="title">{{ 'observacao' | translate }}</h4>
                        <p class="text">{{ solicitation?.adminObservation }}</p>
                    </div>
                </div>
            </article>
        </ng-container>

        <div class="d-flex align-items-center flex-wrap mb-4 w-100"
             [ngClass]="{'justify-content-end' : user?.profile !== 'admin', 'justify-content-between' : user?.profile === 'admin'}">

            <div class="page-content__buttons mb-0 justify-content-center">
                <ng-container *ngIf="user?.profile === 'admin'">
                    <button class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 fs-18"
                            (click)="returnAction(solicitation.id)">{{ 'novocadastro2.retornar' | translate }}
                    </button>

                    <ng-container *ngIf="isNullOrUndefined(solicitation?.adminObservation)">
                        <button class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 mr-md-0 fs-18"
                                (click)="showModal('observations')">
                            Adicionar Observação
                        </button>
                    </ng-container>
                </ng-container>
            </div>

            <div *ngIf="(user?.profile !== 'user' || user?.profile === 'director')
                 && (solicitation?.solicitationStep[solicitation.step].role === user.profile || user?.profile === 'admin')"
                 class="page-content__buttons mb-0 justify-content-center justify-content-md-end">
                <button (click)="actionModal('canceled')"
                        class="btn btn-light btn-fixed btn-lg cancel mb-3 mr-4 fs-18"
                        *ngIf="solicitation?.status === 'pending' || solicitation?.status === 'approved'">{{ 'cancelar2' | translate }}
                </button>
                <button (click)="actionModal('rejected')"
                        class="btn btn-light btn-fixed btn-lg decline mb-3 mr-4 fs-18"
                        *ngIf="solicitation?.status === 'pending'">{{ 'reprovar'|translate }}
                </button>
                <button (click)="actionModal('review')"
                        class="btn btn-warning btn-fixed btn-lg mb-3 mr-4 fs-18"
                        *ngIf="solicitation?.status === 'pending'">{{ 'solicitarrevisao'|translate }}
                </button>
                <button (click)="validateResponse('approved')"
                        class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 mr-md-0 fs-18"
                        *ngIf="solicitation?.status === 'pending'">{{ 'aprovar'|translate }}
                </button>
            </div>
        </div>
    </div>
</section>

<div id="history-version" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body modal-body-custom flex-row flex-wrap">
                <div class="mt-0 ml-0 mr-0 col-12">
                    <table class="table table-custom table-striped">
                        <thead>
                        <tr>
                            <th scope="col">{{ 'alteradopor'|translate }}</th>
                            <th scope="col">{{ 'dataversao'|translate }}</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="isNullOrUndefined(logsBySolicitationId.length < 1)">
                            <p class="mt-3">Nenhuma solicitação encontrada!</p>
                        </ng-container>
                        <ng-container *ngFor="let version of logsBySolicitationId">
                            <tr>
                                <td>{{ version.user.name }}</td>
                                <td>{{ version.createdAt | date: 'dd/MM/yyyy \'às\' hh:mm': 'UTC+0' }}</td>
                                <td><button type="button" class="btn-link" [routerLink]="'/log-idioma/' + version.id">{{'exibirversao'|translate}}</button></td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="canceled" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'bolsadeidioma' | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body flex-column">
                <p>{{ 'cancelaidioma'|translate }}</p>
                <div class="input-material w-full mt-3">
                    <textarea [(ngModel)]="response"
                              class="input-material form-control w-full small" rows="7"
                              required>{{ 'escreva' | translate }}</textarea>
                </div>
                <hr>
                <button (click)="validateResponse('canceled')" class="btn btn-block btn-warning mt-4">
                    {{ 'Salvar' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>


<div id="rejected" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'bolsadeidioma'|translate }}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body flex-column">
                <p>{{ 'reprovaidioma'|translate }}</p>
                <div class="input-material w-full mt-3">
                    <textarea [(ngModel)]="response"
                              class="input-material form-control w-full small" rows="7"
                              required>{{ 'escreva' | translate }}</textarea>
                </div>
                <hr>
                <button (click)="validateResponse('rejected')" class="btn btn-block btn-warning mt-4">
                    {{ 'fechar' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div id="review" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'bolsadeidioma'|translate }}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body flex-column">
                <p>{{ 'motivosolicitacao'|translate }}</p>
                <div class="input-material w-full mt-3">
                    <textarea [(ngModel)]="response"
                              class="input-material form-control w-full small" rows="7"
                              required>{{ 'escreva' | translate }}</textarea>
                </div>
                <hr>
                <button (click)="validateResponse('review')" class="btn btn-block btn-warning mt-4">
                    {{ 'fechar' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>


<div id="approved" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <span class="modal-body-success">
                    <svg-icon src="assets/svg/success.svg"></svg-icon>
                    <p>{{ 'oksuaaprovacao'|translate }}
                        <strong>{{ 'bolsadeidioma'|translate }}</strong> {{ 'foirealizada'|translate }}
                        <strong>{{ 'sucesso'|translate }}</strong>.</p>
                    <button class="btn btn-block btn-warning mt-4"
                            data-dismiss="modal">{{ 'fechar'|translate }}</button>
                </span>
            </div>
        </div>
    </div>
</div>

<div id="observations" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Adicionar Observação</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body modal-body-custom flex-row flex-wrap">
                <div class="page-content__form full mt-0 ml-0 mr-0 col-12">
                    <textarea [(ngModel)]="observationAdmin"
                              class="input-material form-control w-full small" rows="12"
                              required>{{ 'escreva' | translate }}</textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="onSendObservation(solicitation.id)" [disabled]="isNullOrUndefined(observationAdmin)"
                        class="btn btn-block btn-warning mt-4"
                        data-dismiss="modal">
                    {{ 'Salvar' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div id="update-refund" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'bolsa.percentRefund' | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body modal-body-custom flex-row flex-wrap">
                <div class="page-content__form full mt-0 ml-0 mr-0 col-12">
                    <input [(ngModel)]="percentageRefund"
                           mask="000"
                           suffix="%"
                           (ngModelChange)="onPercentageChange($event)"
                           class="input-material form-control w-full small" rows="12"
                           required />
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="handleUpdatePercentRefund()" [disabled]="isNullOrUndefined(percentageRefund)"
                        class="btn btn-block btn-warning mt-4"
                        data-dismiss="modal">
                    {{ 'Salvar' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
