import { Component, OnInit } from '@angular/core';
import {
  ChangeSolicitationStatusGQL, CreateAdminObservationGQL, CreateAdminObservationInput, FlowChangeGQL,
  GetSolicitationGQL,
  GetSolicitationQuery, LogBySolicitationIdGQL, ReturnActionStepGQL,
  Solicitation, SolicitationLog, SolicitationLogByIdGQL, UpdateColaboratorStatusGQL,
  User
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {ChangeColaborator, ChangeSolicitation} from "../../model/model";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {BaseComponent} from "../../base/base.component";
import {SolicitationLogService} from "../../service/solicitation-log.service";

@Component({
  selector: 'app-log-treinamento',
  templateUrl: './log-treinamento.component.html',
  styleUrls: ['./log-treinamento.component.scss']
})
export class LogTreinamentoComponent extends BaseComponent implements OnInit {

  solicitationLog: Solicitation = new Solicitation();
  parmId: string;
  user: User = new User();
  showTooltip: boolean[] = [];
  yearNow: any;
  solicitationQuery: QueryRef<GetSolicitationQuery>;
  response: any;
  documentType = 'CNPJ';
  observationAdmin: any;
  dataChangeSolicitation: ChangeSolicitation = new ChangeSolicitation();
  dataUpdateColaborator: ChangeColaborator = new ChangeColaborator();

  constructor(public router: Router,
              public router2: ActivatedRoute,
              private solicitationLogService: SolicitationLogService,
              public loading: NgxSpinnerService,
              private updateColaboratorStatusGQL: UpdateColaboratorStatusGQL,
              public userService: UserService,
              public returnActionStepGQL: ReturnActionStepGQL,
              public createAdminObservationGQL: CreateAdminObservationGQL,
              public flowChangeGQL: FlowChangeGQL,
              public translate: TranslateService) {
    super(router, translate);
  }

  ngOnInit() {

    this.router2.params.subscribe(url => {
          if (!this.isNullOrUndefined(url)) {
            this.parmId = url.id;
            this.userService.user.subscribe({
              next: data => {
                this.user = data;
                this.getSolicitationLog();
              }, error: err => super.onError(err)
            });


            this.yearNow = (new Date()).getFullYear();

            this.handlerTitlesBoxVertical();
            setTimeout(() => {
              this.handlerTitlesBoxVertical();
            }, 1000);
          }
        }
    );
  }

  getSolicitationLog() {
    this.solicitationLogService.getSolicitationLog(this.parmId).subscribe(solicitation => {
      this.solicitationLog = solicitation;
      console.log('SOLICITATION LOG', solicitation);
    });
  }

  handlerStepAdmin() {
    this.solicitationLog.solicitationStep = this.solicitationLog.solicitationStep.filter((e) => e.role !== 'admin');
  }

  // actionModal(isModal) {
  //   this.response = '';
  //   $('#' + isModal).modal('show');
  // }

  // validateResponse(status: string) {
  //   this.dataChangeSolicitation.status = status;
  //   if (status === 'approved') {
  //     return this.onChangeSolicitation();
  //   }
  //
  //   const arrValidateFields = [
  //     {value: this.response, text: 'Justificativa* <br />'},
  //   ];
  //   const stringError = this.validateField(arrValidateFields);
  //   if (!super.isNullOrUndefined(stringError)) {
  //     this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')}: <br><br> ${stringError}`, 'warning');
  //     return;
  //   }
  //   super.closeModal('rejected');
  //   this.dataChangeSolicitation.response = this.response;
  //   this.onChangeSolicitation();
  // }

  // onChangeSolicitation(): void {
  //   this.changeSolicitationStatusGQL.mutate({data: this.dataChangeSolicitation}).subscribe(
  //       (data) => {
  //         // this.solicitation = data;
  //         this.solicitationQuery.refetch();
  //         this.router.navigate(['/lista-de-solicitacoes']);
  //         if (this.dataChangeSolicitation.status === 'approved') {
  //           $('#approved').modal('show');
  //         }
  //       }, (err) => {
  //       }
  //   );
  // }

  handlerColaborators(colaborators) {
    this.dataUpdateColaborator.id = colaborators.id;
    if (colaborators.status === 'approved') {
      this.onUpdateColaborator('rejected');
      return;
    }
    this.onUpdateColaborator('approved');
  }

  handlerColaboratorsRegistrate(colaborators) {
    this.dataUpdateColaborator.id = colaborators.id;
    if (colaborators.status === 'approved') {
      this.onUpdateColaborator('registered');
      return;
    } else {
      this.onUpdateColaborator('unregistered');
    }
  }

  onUpdateColaborator(status) {
    this.dataUpdateColaborator.status = status;
    this.updateColaboratorStatusGQL.mutate({data: this.dataUpdateColaborator}).subscribe(
        (data) => {
          this.router2.params.subscribe(url => {
            if (!this.isNullOrUndefined(url)) {
              this.solicitationQuery.refetch({id: url.id});
            }
          });
        }, (err) => {
        }
    );
  }

  svgType(type) {
    switch (type) {
      case 'xlsx':
      case 'xls':
        return 'assets/svg/icon-metro-file-excel.svg';
      case 'docx':
      case 'doc':
      case 'txt':
        return 'assets/svg/icon-metro-file-word.svg';
      case 'png':
      case 'jpg':
        return 'assets/svg/icon-metro-file-image.svg';
      case 'ppt':
      case 'pptx':
        return 'assets/svg/icon-metro-file-powerpoint.svg';
      default:
        return 'assets/svg/icon-metro-file-pdf.svg';
    }
  }

  getNameRejected() {
    let lastRejectedUser = null;
    for (let i = this.solicitationLog?.solicitationStep.length - 1; i >= 0; i--) {
      const step = this.solicitationLog?.solicitationStep[i];
      if (step.status === 'rejected') {
        lastRejectedUser = step.user?.name;
        break;
      }
    }
    return lastRejectedUser;
  }

  returnAction(id: any) {
    super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('returnQuestion'), () => {
      this.returnActionStepGQL.mutate({id}).subscribe(({data}) => {
        this.solicitationQuery?.refetch();
        this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('returnConclued'), 'success');
      });
    });

  }

  onSendObservation(id: any) {
    const obj = new CreateAdminObservationInput();
    obj.observation = this.observationAdmin;
    obj.id = id;
    this.createAdminObservationGQL.mutate({input: obj}).subscribe(({data}) => {
      this.solicitationQuery?.refetch();
      this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('observacaoenviada'), 'success');
      this.observationAdmin = '';
    });
  }

  onSetChangeFlow(id) {
    super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('mudarFluxo'), () => {
      this.flowChangeGQL.mutate({id}).subscribe(({data}) => {
        this.solicitationQuery?.refetch();
        this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('fluxoAlterado'), 'success');
      });
    });
  }

  onShowTooltip(index: number, show: boolean): void {
    this.showTooltip[index] = show;
  }

}
