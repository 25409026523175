import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {
    ChangeSolicitationStatusGQL,
    ColaboratorSolicitationsByUserIdGQL,
    ColaboratorSolicitationsByUserIdQuery,
    ColaboratorSolicitationsByUserIdQueryVariables,
    CreateAdminObservationGQL,
    CreateAdminObservationInput,
    FlowChangeGQL,
    GetSolicitationGQL,
    GetSolicitationQuery,
    LogBySolicitationIdGQL,
    LogBySolicitationIdQuery, ResendEmailSolicitationPendingGQL,
    ReturnActionStepGQL,
    Solicitation,
    SolicitationLog,
    SolicitationStep,
    SuspensionSolicitationGQL,
    UpdatePercentRefundAdminGQL,
    UpdatePercentRefundInput,
    User
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {ChangeSolicitation} from '../../model/model';
import {NgxSpinnerService} from 'ngx-spinner';

declare var $: any;

@Component({
    selector: 'app-detalhe-bolsa-de-estudos',
    templateUrl: './detalhe-bolsa-de-estudos.component.html',
    styleUrls: ['./detalhe-bolsa-de-estudos.component.scss']
})
export class DetalheBolsaDeEstudosComponent extends BaseComponent implements OnInit, OnDestroy {

    colaboratorSolicitations: Solicitation[] = [];
    colaboratorSolicitationsQuery: QueryRef<ColaboratorSolicitationsByUserIdQuery, ColaboratorSolicitationsByUserIdQueryVariables>;
    solicitation: Solicitation = new Solicitation();
    yearNow: any;
    solicitationQuery: QueryRef<GetSolicitationQuery>;
    dataChangeSolicitation: ChangeSolicitation = new ChangeSolicitation();
    response: any;
    user: User = new User();
    parmId: string;
    documentType = 'CNPJ';
    observationAdmin: any;
    showTooltip: boolean[] = [];
    logsBySolicitationId: SolicitationLog[] = [];
    percentageRefund = null;
    isCurrentProfileStep = false;
    isStepAdmin = false;


    constructor(public router: Router,
                public router2: ActivatedRoute,
                private getSolicitationGQL: GetSolicitationGQL,
                public loading: NgxSpinnerService,
                private changeSolicitationStatusGQL: ChangeSolicitationStatusGQL,
                private colaboratorSolicitationsByUserIdGQL: ColaboratorSolicitationsByUserIdGQL,
                private suspensionSolicitationGQL: SuspensionSolicitationGQL,
                private updatePercentRefundAdminGQL: UpdatePercentRefundAdminGQL,
                public returnActionStepGQL: ReturnActionStepGQL,
                public createAdminObservationGQL: CreateAdminObservationGQL,
                public logBySolicitationIdGQL: LogBySolicitationIdGQL,
                public flowChangeGQL: FlowChangeGQL,
                public userService: UserService,
                public translate: TranslateService,
                public resendEmailSolicitationPendingGQL: ResendEmailSolicitationPendingGQL,
    ) {
        super(router, translate);
    }

    ngOnInit() {
        this.router2.params.subscribe(url => {
                if (!this.isNullOrUndefined(url)) {
                    this.parmId = url.id;

                    this.userService.user.subscribe({
                        next: data => {
                            this.user = data;
                            this.getSolicitation();
                        }, error: err => super.onError(err)
                    });

                    this.dataChangeSolicitation.id = url.id;
                    this.yearNow = (new Date()).getFullYear();

                    this.handlerTitlesBoxVertical();

                    setTimeout(() => {
                        this.handlerTitlesBoxVertical();
                    }, 1000);
                }
            }
        );
    }

    getSolicitation() {
        this.solicitationQuery = this.getSolicitationGQL.watch({id: this.parmId});
        this.solicitationQuery.valueChanges.subscribe(({data}) => {
            this.solicitation = data.getSolicitation as Solicitation;
            console.log('SOLICITATION', this.solicitation);
            this.validateIsStepAdmin();
            this.validateIsCurrentProfileStep();
            this.removeAdminStep(this.solicitation);
            this.percentageRefund = this.solicitation.percentRefund;

            // if (this.user.profile !== 'admin') {
            //     this.handlerStepAdmin();
            // }
        });
    }

    handlerStepAdmin() {
        this.solicitation.solicitationStep = this.solicitation.solicitationStep.filter((e) => e.role !== 'admin');
    }


    ngOnDestroy(): void {
        this.destroyModal('resume');
        this.destroyModal('history-version');
        this.destroyModal('update-refund');
    }

    actionModal(isModal) {
        this.response = '';
        $('#' + isModal).modal('show');
    }

    validateResponse(status: string) {

        if (this.isStepAdmin && this.isNullOrUndefined(this.percentageRefund)) {
            // console.log('aqui');
            return this.showMessage('Defina um valor de reembolso', 'Para prosseguir, defina um percentual de reembolso!', 'warning');
        }

        this.dataChangeSolicitation.status = status;
        if (status === 'approved') {
            return this.onChangeSolicitation();
        }

        const arrValidateFields = [
            {value: this.response, text: 'Justificativa* <br />'},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')}: <br><br> ${stringError}`, 'warning');
            return;
        }
        super.closeModal(status);
        this.dataChangeSolicitation.response = this.response;
        this.onChangeSolicitation();
    }

    onChangeSolicitation(): void {
        this.changeSolicitationStatusGQL.mutate({data: this.dataChangeSolicitation}).subscribe(
            (data) => {
                this.router.navigate(['/lista-de-solicitacoes']);
                if (this.dataChangeSolicitation.status === 'approved') {
                    $('#approved').modal('show');
                }
            }, (err) => super.onError(err));
    }

    svgType(type) {
        switch (type) {
            case 'xlsx':
            case 'xls':
                return 'assets/svg/icon-metro-file-excel.svg';
            case 'docx':
            case 'doc':
            case 'txt':
                return 'assets/svg/icon-metro-file-word.svg';
            case 'png':
            case 'jpg':
                return 'assets/svg/icon-metro-file-image.svg';
            case 'ppt':
            case 'pptx':
                return 'assets/svg/icon-metro-file-powerpoint.svg';
            default:
                return 'assets/svg/icon-metro-file-pdf.svg';
        }
    }

    getNameRejected() {
        let lastRejectedUser = null;
        for (let i = this.solicitation?.solicitationStep.length - 1; i >= 0; i--) {
            const step = this.solicitation?.solicitationStep[i];
            if (step.status === 'rejected') {
                lastRejectedUser = step.user?.name;
                break;
            }
        }
        return lastRejectedUser;
    }

    saveConfirm() {
        $('#resume').modal();
        this.colaboratorSolicitationsQuery = this.colaboratorSolicitationsByUserIdGQL.watch({userId: this.solicitation.user?.id});
        this.colaboratorSolicitationsQuery.valueChanges.subscribe(({data}) => {
            this.colaboratorSolicitations = data.colaboratorSolicitationsByUserId as Solicitation[];
        }, error => super.onError(error));
    }

    returnAction(id: any) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('returnQuestion'), () => {
            this.returnActionStepGQL.mutate({id}).subscribe(({data}) => {
                this.solicitationQuery?.refetch();
                this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('returnConclued'), 'success');
            });
        });
    }

    onSendObservation(id: any) {
        const obj = new CreateAdminObservationInput();
        obj.observation = this.observationAdmin;
        obj.id = id;
        this.createAdminObservationGQL.mutate({input: obj}).subscribe(({data}) => {
            this.solicitationQuery?.refetch();
            this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('observacaoenviada'), 'success');
            this.observationAdmin = '';
        });
    }

    onSetChangeFlow(id) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('mudarFluxo'), () => {
            this.flowChangeGQL.mutate({id}).subscribe(({data}) => {
                this.solicitationQuery?.refetch();
                this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('fluxoAlterado'), 'success');
            });
        });
    }

    onShowTooltip(index: number, show: boolean): void {
        this.showTooltip[index] = show;
    }

    showVersionHistory() {
        $('#history-version').modal();
        this.logBySolicitationIdGQL.watch({solicitationId: this.parmId})
            .valueChanges
            .subscribe(({data}) => {
                this.logsBySolicitationId = data.logBySolicitationId as SolicitationLog[];
                console.log('LOGS SOLICITATION', this.logsBySolicitationId);
            });
    }

    removeAdminStep(solicitation: Solicitation) {
        if (this.user.profile === 'user') {
            // console.log('STEP', this.solicitation.step);
            const removedAdminStep = solicitation.solicitationStep.filter(step => step.role !== 'admin');
            // console.log(solicitation);
            solicitation.solicitationStep = removedAdminStep;
            this.solicitation = solicitation;
            // console.log('SOLICITATION ADMIN REMOVED', this.solicitation);
        }
        // quero remover o step que o role for 'admin'
    }


    handleSuspendSolicitation() {
        this.confirmMessage(this.translate.instant('baseComponent.warningAttention'),
            this.translate.instant('suspendersolicitacao'),
            () => {
            this.suspensionSolicitationGQL
                .mutate({solicitationId: this.parmId})
                .subscribe({
                    next: () => {
                        this.getSolicitation();
                    },
                    error: error => super.onError(error)
                });
        });
    }

    onUpdatePercentageRefund() {
        $('#update-refund').modal();

        this.percentageRefund = this.solicitation.percentRefund;
    }

    handleUpdatePercentRefund() {
        const updatePercentRefundAdminInput: UpdatePercentRefundInput = {
            percentRefund: Number(this.percentageRefund),
            id: this.parmId
        };
        this.updatePercentRefundAdminGQL
            .mutate({input: updatePercentRefundAdminInput})
            .subscribe(() => {
                this.showMessage(this.translate.instant('sucesso'),
                    this.translate.instant('updatedrefund'), 'success');
                this.closeModal('update-refund');
                this.solicitationQuery.refetch();
            });
    }

    onPercentageChange(value: any) {
        if (value > 100) {
            this.percentageRefund = 100;
        } else if (value < 0) {
            this.percentageRefund = 0;
        } else {
            this.percentageRefund = value;
        }
    }

    validateIsStepAdmin() {
        console.log('SOLICITATION NO IS STEP ADMIN', this.solicitation);

        if (!this.solicitation) {
            console.warn('Solicitation está undefined ou null');
            return false;
        }

        if (!this.solicitation.solicitationStep) {
            console.warn('Solicitation.solicitationStep está undefined ou vazio', this.solicitation);
            return false;
        }

        return this.isStepAdmin = this.solicitation.step === this.solicitation.solicitationStep.findIndex(
            step => step.role === 'admin'
        );
    }

    validateIsCurrentProfileStep() {
        const currentUserProfile = this.user.profile;
        const currentStep = this.solicitation.step;

        const currentStepData = this.solicitation.solicitationStep[currentStep];

        this.isCurrentProfileStep = currentStepData.role === currentUserProfile;
    }

    handleResendEmailSolicitationPending() {
        this.resendEmailSolicitationPendingGQL
            .mutate({solicitationId: this.parmId})
            .subscribe(({data}) => {
                this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('emailresend'), 'success');
            }, error => super.onError(error));
    }

    formatResponsibleName(name: string): string {
        if (!name) {
            // define a blank name if it is null or undefined/
            name = '';
        }

        const arrNames = name.split(' '); // Remove espaços extras

        if (arrNames.length === 1) {
            return arrNames[0]; // Retorna o nome caso haja apenas um nome
        }

        const firstName = arrNames[0]; // Primeiro nome
        const lastName = arrNames[arrNames.length - 1]; // Último nome

        return `${firstName} ${lastName.charAt(0)}.`; // Retorna no formato "Primeiro U."
    }
}
