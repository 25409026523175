<div class="page-header">
    <div class="container justify-content-center">
        <a [routerLink]="['/lista-de-solicitacoes']" class="page-header__back">
            <svg-icon src="assets/svg/back.svg"></svg-icon>
        </a>
        <h2 class="page-header__title"><strong>
            {{ (solicitation.father?.type === 'scholarship' ? 'reembolsobolsadeestudos' : 'reembolsobolsadeidioma') | translate }}
        </strong></h2>

        <ng-container *ngIf="user?.profile === 'admin'">
            <div class="page-header__btn">
                <button class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 mr-md-0 fs-16"
                        [routerLink]="solicitation.father?.type === 'language' ? ('/reembolsos-idiomas-admin/' + solicitation.id) : ('/reembolsos-admin/' + solicitation.id)">
                    Editar formulário
                </button>
            </div>
        </ng-container>
    </div>
</div>

<div class="reason-rejected" *ngIf="solicitation?.status === 'rejected'">
    <div class="accordion" id="accordionExample" style="width: 100%">
        <div style="background: #D81212;">
            <button class="btn btn-block text-left review-button" type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div class="d-flex justify-content-between" style="align-items: self-end">
                    <h4 class="text-primary font-weight-bolder">
                        <strong>{{ 'reprovadopor'|translate }} {{ getNameRejected() }}</strong>
                    </h4>
                    <h4 class="text-primary font-weight-bolder"><strong>{{ 'motivoreprova'|translate }}</strong></h4>
                    <svg-icon src="assets/svg/expand.svg"></svg-icon>
                </div>
            </button>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                 data-parent="#accordionExample"
                 style="background: #FFA500;">
                <div class="card-body pt-0 pb-2" style="background: #D81212;">
                    <span class="text-primary" style="font-size: 18px"> {{ solicitation?.response }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="page-content">
    <div class="container">
        <div class="page-content__status">
            <div class="custom-nav full mt-3 pl-5 pl-lg-0 pt-4 pr-0 pr-md-3 pt-lg-0">
                <!-- VARIAÇÃO DE STATUS: approving, rejected e approved -->
                <div class="infos infos--single justify-content-between no-border" [ngClass]="solicitation?.status">
                    <span class="number">
                        <span class="page-header__number mt-1">Nº <strong>{{ solicitation?.code }}</strong></span>
                    </span>
                    <span class="ml-3 text flex-column align-items-start tag tag-secondary tag-left color">
                        <span class="head small">Status</span>
                        <strong>{{ getStatusName(solicitation?.status) }}</strong>
                    </span>
                    <span class="text flex-column align-items-start">
                        <span class="head small">{{ 'atualizado' | translate }}</span>
                        <span class="color">{{ solicitation?.updatedAt | date:'dd/MM/yyyy':'UTC+0' }}</span>
                    </span>
                    <span class="text flex-column align-items-start mr-lg-auto">
                        <span class="head small">{{ 'criado' | translate }}</span>
                        <span class="text-primary">{{ solicitation?.createdAt | date:'dd/MM/yyyy':'UTC+0' }}</span>
                    </span>
<!--                    <button class="btn btn-primary mr-4"-->
<!--                            (click)="showVersionHistory()">{{ 'visualizarhistorico'|translate }}-->
<!--                    </button>-->
                </div>
                <ul class="nav nav-tabs custom-nav__menu">
                    <ng-container *ngFor="let s of solicitation?.solicitationStep; let i = index">
                        <li class="nav-item" *ngIf="!(s.role === 'admin' && !['admin', 'hr_development'].includes(user.profile))">
                            <span class="nav-link" [ngClass]="{'active':s.step === solicitation?.step,
                                  'done':s.status === 'approved',
                                  'error':s.status === 'rejected'}">
    <!--                            <span>2</span>-->
                                <div class="bubble"></div>
                                <span class="tooltip-label label is-cursor">
                                    {{getProfileNameByRole(s.role)}}</span>
                                <span class="responsible-name">{{s.responsibleName}}</span>
                            </span>
                        </li>
                    </ng-container>
                </ul>
            </div>
        </div>

        <ng-container *ngFor="let c of solicitation?.father?.colaborators">
            <article *ngIf="this.isNullOrUndefined(solicitation.colaboratorRefund?.id)
            || solicitation.colaboratorRefund?.id == c.id" class="page-content__details box mb-md-4">
                <div class="page-content__details__tab">{{ 'colaborador'|translate }}</div>
                <div class="row">
                    <div class="col-6 col-sm-4 col-lg-4">
                        <h4 class="title">{{ 'nome'|translate }}</h4>
                        <p class="text">{{ c.name }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">RE</h4>
                        <p class="text">{{ c.re }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{ 'unidade'|translate }}</h4>
                        <p class="text">{{ c.unity.name }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-6 col-sm-4">
                        <h4 class="title">{{ 'responsible'|translate }}</h4>
                        <p class="text">{{ c.directorArea }}</p>
                    </div>
                    <div class="col-6 col-sm-4">
                        <h4 class="title">{{ 'departamento/setor'|translate }}</h4>
                        <p class="text">{{ c.areaManager }}</p>
                    </div>
                </div>
            </article>
        </ng-container>

        <article class="page-content__details box mb-md-4">
            <div class="page-content__details__tab">{{ 'curso'|translate }}</div>
            <div class="row">
                <ng-container *ngIf="solicitation.father.type === 'scholarship'">
                    <div class="col-6 col-sm-4">
                        <h4 class="title">{{ 'typeOfCourse'|translate }}</h4>
                        <p class="text">{{ solicitation?.father?.course?.type | translate }}</p>
                    </div>
                </ng-container>
                <ng-container *ngIf="solicitation.father.type === 'language'">
                    <div class="col-6 col-sm-4">
                        <h4 class="title">{{ 'bolsa.idiomaPretendeCursar' | translate }}</h4>
                        <p class="text">{{ solicitation?.father?.course?.language | translate }}</p>
                    </div>
                </ng-container>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'nomeinstituicao'|translate }}</h4>
                    <p class="text">{{ solicitation?.father?.course?.name }}</p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'datamatricula'|translate }}</h4>
                    <p class="text">{{ solicitation?.father?.course?.start| date:'dd/MM/yyyy':'UTC+0' }}</p>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{ 'dataprocesso'|translate }}</h4>
                    <strong class="text">{{ solicitation?.father?.course?.registrationDate | date:'dd/MM/yyyy':'UTC+0' }}</strong>
                </div>
                <!--                <div class="col-6 col-sm-4">-->
                <!--                    <h4 class="title">{{'valorpago'|translate}}</h4>-->
                <!--                    <strong class="text">{{solicitation?.father?.course?.value | currency:getSymbolCurrency(solicitation?.course?.typeOfCurrency)}}</strong>-->
                <!--                </div>-->
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{ 'valorrembolso'|translate }}</h4>
                    <strong class="text">{{ solicitation?.value | currency }}</strong>
                </div>
                <ng-container *ngIf="user.profile === 'admin' || user.profile === 'hr_development'">
                    <div class="col-6 col-sm-3">
                        <h4 class="title">{{ 'percentrefund'|translate }}</h4>
                        <strong class="text">{{ solicitation?.percentRefund == null ? 0 : solicitation?.percentRefund + "%" }}</strong>
                    </div>
                    <div class="col-6 col-sm-3">
                        <h4 class="title">{{ 'amountrefund'|translate }}</h4>
                        <strong class="text">{{ solicitation?.amountRefund | currency }}</strong>
                    </div>
                </ng-container>
            </div>
        </article>

        <article class="page-content__details box mb-md-4 d-flex"
                 *ngIf="!this.isNullOrUndefined(solicitation?.signature)">
            <div class="page-content__details__tab">{{ 'signature'|translate }}</div>
            <img class="signature" src="{{solicitation?.signature}}" alt="signature"/>
        </article>

        <article class="page-content__details box mb-md-4" *ngIf="!isNullOrUndefined(solicitation?.receipts)">
            <div class="page-content__details__tab">{{ 'comprovante'|translate }}</div>
            <div class="row justify-content-center pt-3">
                <div class="col-12 col-md-4 text-center" *ngFor="let r of solicitation?.receipts">
                    <h4 class="title large">{{ getReceiptName(r.type) }}</h4>
                    <a [href]="r.url" target="_blank" class="attachment d-flex flex-column align-items-center">
                        <img class="attach" *ngIf="r.extension !== 'pdf'" src="{{r.url}}" alt="receipts"/>
                        <!--                        <svg-icon [svgStyle]="{'width':'30px','height':'30px'}" src="assets/svg/icon-metro-file-pdf.svg"></svg-icon>-->
                        <svg-icon class="my-2" [svgStyle]="{'width':'30px','height':'30px'}"
                                  [src]="svgType(r.extension)"></svg-icon>
                        <p>{{ r.name }}.{{ r.extension }}</p>
                    </a>
                </div>
            </div>
        </article>

        <ng-container *ngIf="(user.profile === 'admin' || user.profile === 'hr_development') && !isNullOrUndefined(solicitation?.adminObservation)">
            <article class="page-content__details box mb-md-4">
                <div class="page-content__details__tab">{{ 'observacao' | translate }}</div>
                <div class="row pt-md-4">
                    <div class="col-6 col-sm-6">
                        <h4 class="title">{{ 'observacao' | translate }}</h4>
                        <p class="text">{{ solicitation?.adminObservation }}</p>
                    </div>
                </div>
            </article>
        </ng-container>

        <div class="d-flex align-items-center flex-wrap mb-4 w-100"
             [ngClass]="{'justify-content-end' : user?.profile !== 'admin', 'justify-content-between' : user?.profile === 'admin'}">

            <div class="page-content__buttons mb-0 justify-content-center">
                <ng-container *ngIf="user?.profile === 'admin'">
                    <button class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 fs-18"
                            (click)="returnAction(solicitation.id)">{{ 'novocadastro2.retornar' | translate }}
                    </button>

                    <ng-container *ngIf="isNullOrUndefined(solicitation?.adminObservation)">
                        <button class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 mr-md-0 fs-18"
                                (click)="showModal('observations')">
                            Adicionar Observação
                        </button>
                    </ng-container>
                </ng-container>
            </div>

            <div *ngIf="(user?.profile !== 'user' || user?.profile ==='director')
                 && (solicitation?.solicitationStep[solicitation.step].role === user.profile || user?.profile === 'admin')"
                 class="page-content__buttons mb-0 justify-content-center justify-content-md-end">
                <button (click)="actionModal('canceled')"
                        *ngIf="solicitation?.status === 'pending' || solicitation?.status === 'approved'"
                        class="btn btn-light btn-fixed btn-lg cancel mb-3 mr-4 fs-18">{{ 'cancelar2'|translate }}
                </button>
                <button (click)="actionModal('rejected')"
                        *ngIf="solicitation?.status === 'pending'"
                        class="btn btn-light btn-fixed btn-lg decline mb-3 mr-4 fs-18">{{ 'reprovar'|translate }}
                </button>
                <button (click)="actionModal('review')"
                        *ngIf="solicitation?.status === 'pending'"
                        class="btn btn-warning btn-fixed btn-lg mb-3 mr-4 fs-18">{{ 'solicitarrevisao'|translate }}
                </button>
                <button (click)="validateResponse('approved')"
                        *ngIf="solicitation?.status === 'pending'"
                        class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 mr-md-0 fs-18">{{ 'aprovar'|translate }}
                </button>
            </div>
        </div>
    </div>
</section>

<div id="history-version" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body modal-body-custom flex-row flex-wrap">
                <div class="mt-0 ml-0 mr-0 col-12">
                    <table class="table table-custom table-striped">
                        <thead>
                        <tr>
                            <th scope="col">{{ 'alteradopor'|translate }}</th>
                            <th scope="col">{{ 'dataversao'|translate }}</th>
                            <th scope="col"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngIf="isNullOrUndefined(logsBySolicitationId.length < 1)">
                            <p class="mt-3">Nenhuma solicitação encontrada!</p>
                        </ng-container>
                        <ng-container *ngFor="let version of logsBySolicitationId">
                            <tr>
                                <td>{{ version.user.name }}</td>
                                <td>{{ version.createdAt | date: 'dd/MM/yyyy \'às\' hh:mm': 'UTC+0' }}</td>
                                <td><button type="button" class="btn-link" [routerLink]="'/log-reembolso/' + version.id">{{'exibirversao'|translate}}</button></td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="canceled" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'reembolsobolsadeestudos' | translate }}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body flex-column">
                <p>{{ 'cancelareembolso'|translate }}</p>
                <div class="input-material w-full mt-3">
                    <textarea [(ngModel)]="response"
                              class="input-material form-control w-full small" rows="7"
                              required>{{ 'escreva' | translate }}</textarea>
                </div>
                <hr>
                <button (click)="validateResponse('canceled')" class="btn btn-block btn-warning mt-4">
                    {{ 'Salvar' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>


<div id="rejected" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'reembolsobolsadeestudos'|translate }}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body flex-column">
                <p>{{ 'motivoreembolso'|translate }}</p>
                <div class="input-material w-full mt-3">
                    <textarea [(ngModel)]="response"
                              class="input-material form-control w-full small" rows="7"
                              required>{{ 'escreva' | translate }}</textarea>
                </div>
                <hr>
                <button (click)="validateResponse('rejected')" class="btn btn-block btn-warning mt-4">
                    {{ 'fechar' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div id="review" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'reembolsobolsadeestudos'|translate }}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body flex-column">
                <p>{{ 'motivosolicitacao'|translate }}</p>
                <div class="input-material w-full mt-3">
                    <textarea [(ngModel)]="response" class="input-material form-control w-full small" rows="7"
                              required>{{ 'escrevaaqui'|translate }}</textarea>
                </div>
                <hr>
                <button (click)="validateResponse('review')" class="btn btn-block btn-warning mt-4"
                        data-dismiss="modal">{{ 'fechar'|translate }}
                </button>
            </div>
        </div>
    </div>
</div>

<div id="approved" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <span class="modal-body-success">
                    <svg-icon src="assets/svg/success.svg"></svg-icon>
                    <p>{{ 'oksuaaprovacao'|translate }}
                        <strong>{{ 'reembolsobolsadeestudos'|translate }}</strong>{{ 'foirealizadacom'|translate }}
                        <strong>{{ 'sucesso'|translate }}</strong>.</p>
                    <button class="btn btn-block btn-warning mt-4"
                            data-dismiss="modal">{{ 'fechar'|translate }}</button>
                </span>
            </div>
        </div>
    </div>
</div>

<div id="docs" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-overlay" role="document">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal">
                <svg-icon src="assets/svg/close.svg"></svg-icon>
            </button>
            <div class="modal-body flex-column">
                <img src="assets/images/document.jpg"/>
            </div>
        </div>
    </div>
</div>

<div id="observations" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Adicionar Observação</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body modal-body-custom flex-row flex-wrap">
                <div class="page-content__form full mt-0 ml-0 mr-0 col-12">
                    <textarea [(ngModel)]="observationAdmin"
                              class="input-material form-control w-full small" rows="12"
                              required>{{ 'escreva' | translate }}</textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button (click)="onSendObservation(solicitation.id)" [disabled]="isNullOrUndefined(observationAdmin)"
                        class="btn btn-block btn-warning mt-4"
                        data-dismiss="modal">
                    {{ 'Salvar' | translate }}
                </button>
            </div>
        </div>
    </div>
</div>
