import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {QueryRef} from 'apollo-angular';
import {
    GetHistoryBySolicitationIdGQL,
    GetSolicitationsListGQL,
    GetSolicitationsListQuery, GetUserGQL, GetUsersGQL,
    ReopenSolicitationGQL,
    SearchSolicitacionInput, SearchUserAll, SearchUsersGQL, SearchUsersQuery,
    Solicitation,
    SolicitationList,
    SolicitationListPage,
    SolicitationPage,
    User, UserPage
} from '../../../generated/graphql';
import * as moment from 'moment';
import {environment} from '../../../environments/environment';

import {UserService} from '../../service/user.service';
import {StorageService} from "../../service/user-simulation.service";
import {Subscription} from "rxjs";


declare var $: any;

@Component({
    selector: 'app-lista-de-solicitacoes',
    templateUrl: './lista-de-solicitacoes.component.html',
    styleUrls: ['./lista-de-solicitacoes.component.scss']
})
export class ListaDeSolicitacoesComponent extends BaseComponent implements OnInit, OnDestroy {

    currentUser: User = new User();

    filter: SearchSolicitacionInput = {
        start: moment().startOf('day').subtract(90, 'days').utcOffset(0, true).format(),
        end: moment().endOf('day').utcOffset(0, true).format(),
        page: 1
    };
    filterUser: SearchUserAll = new SearchUserAll();

    category = [
        {value: 'scholarship', checked: true},
        {value: 'training', checked: true},
        {value: 'language', checked: true},
        {value: 'refund', checked: true}
    ];
    isLoaderSpinner = true;
    // list: SolicitationPage = new SolicitationPage();
    list: SolicitationListPage = new SolicitationListPage();
    // listQuery: QueryRef<GetSolicitationsQuery>;
    listQuery: QueryRef<GetSolicitationsListQuery>;
    solicitationHistory: Solicitation = new Solicitation();
    userPage: UserPage = new UserPage();
    usersQuery: QueryRef<SearchUsersQuery>;
    users: User[] = [];
    simulationUserId!: string;
    simulationUserName!: string;
    simulatedUser: {id: string, name: string, profile: string} = null;
    simulatedUserSubject: Subscription;
    SIMULATED_USER_KEY = 'SIMULATED_USER';


    constructor(public router: Router,
                public userService: UserService,
                public translate: TranslateService,
                private getSolicitationListGQL: GetSolicitationsListGQL,
                private getHistoryBySolicitationIdGQL: GetHistoryBySolicitationIdGQL,
                private getUserGQL: GetUserGQL,
                private storageService: StorageService,
                private searchUsersGQL: SearchUsersGQL,
                private reopenSolicitationGQL: ReopenSolicitationGQL) {
        super(router, translate);
    }

    ngOnDestroy(): void {
        this.destroyModal('history');
    }

    ngOnInit(): void {

        this.userService.user.subscribe({
            next: data => {
                this.currentUser = data;
            }, error: err => super.onError(err)
        });
        this.getFilter();
        this.setDatePicker();
        this.listQuery = this.getSolicitationListGQL.watch({filter: this.filter});
        this.listQuery.valueChanges.subscribe(({data}) => {
            this.list = data.getSolicitationsList as SolicitationListPage;
            console.log('SOLICITATIONS LIST', this.list);
            this.isLoaderSpinner = false;
        }, error => super.onError(error));

        this.simulatedUserSubject = this.storageService.simulatedUser$.subscribe(user => {
            this.simulatedUser = user;

            // console.log('SIMULATED USER NULL', user);

            if (this.isNullOrUndefined(user)) {
                this.getFilter();
                console.log('FILTER ANTES SIMULATED USER NULL', this.filter);
                this.filter.simulationUserId = null;
                console.log('FILTER APOS SIMULATED USER NULL', this.filter);
                this.listQuery.refetch({ filter: this.filter });
            }
        });
    }

    getFilter() {
        this.filter.page = 1;
        const result = localStorage.getItem('FILTER_SOLICITATION');
        if (result != null) {
            this.filter = JSON.parse(result);
            this.category.map((element) => {
                const index = this.filter.category.findIndex((e) => e === element.value);
                if (index > -1) {
                    element.checked = false;
                }
            });
        }
    }

    getSearch(cleanFilter = false) {
        this.filter.category = [];
        this.category.map((element) => {
            if (!element.checked) {
                this.filter.category.push(element.value);
            }
        });
        this.listQuery.refetch({filter: this.filter}).then(() => {
            if (cleanFilter) {
                localStorage.removeItem('FILTER_SOLICITATION');
            } else {
                localStorage.setItem('FILTER_SOLICITATION', JSON.stringify(this.filter));
            }
        });
    }

    setDatePicker() {
        $('.js-daterangepicker').daterangepicker({
            startDate: moment(this.filter.start).utc(),
            endDate: moment(this.filter.end).utc(),
            cancelButtonClasses: 'btn-danger',
            ranges: {
                Hoje: [moment(), moment()],
                Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 Dias': [moment().subtract(6, 'days'), moment()],
                '30 Dias': [moment().subtract(29, 'days'), moment()]
            },
            locale: {
                customRangeLabel: 'Personalizar',
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                daysOfWeek: [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ],
                monthNames: [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ],
                firstDay: 1
            }
        }, (start, end, label) => {
            this.filter.start = start.utcOffset(0, true).format();
            this.filter.end = end.utcOffset(0, true).format();
        });
    }

    svgType(type) {
        switch (type) {
            case 'training':
                return 'assets/svg/icon-2.svg';
            case 'language':
                return 'assets/svg/icon-3.svg';
            case 'refund':
                return 'assets/svg/icon-4.svg';
            default:
                return 'assets/svg/icon-1.svg';
        }

    }

    getRouter(type, status, userId, id, re) {
        let isSelf = false;
        if (status === 'review' && this.currentUser.id === userId) {
            isSelf = true;
        }
        switch (type) {
            case 'training':
                if (isSelf) {
                    return '/treinamentos/' + id;
                }
                return `/detalhe-treinamento/` + id;
            case 'language':
                if (isSelf) {
                    return '/bolsa-de-idiomas/' + id;
                }
                return `/detalhe-bolsa-de-idiomas/` + id;
            case 'refund':
                if (isSelf) {
                    return '/reembolsos/' + id + '/' + re;
                }
                return `/detalhe-reembolso/` + id;
            default:
                if (isSelf) {
                    return '/bolsa-de-estudos/' + id;
                }
                return `/detalhe-bolsa-de-estudos/` + id;
        }

    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        // const start = moment(this.filter.start).format('YYYY-MM-DD');
        const start = moment(this.filter.start).format();
        // const end = moment(this.filter.end).format('YYYY-MM-DD');
        const end = moment(this.filter.end).format();
        let url = `${environment.base_url}/solicitations/search-solicitation/excel?token=${token}`;
        if (!super.isNullOrUndefined(this.filter.keyword)) {
            url += ('&keyword=' + this.filter.keyword);
        }
        if (!super.isNullOrUndefined(this.filter.status)) {
            url += ('&status=' + this.filter.status);
        }
        if (!super.isNullOrUndefined(this.filter.category)) {
            url += ('&category=' + this.filter.category);
        }
        if (!super.isNullOrUndefined(start)) {
            url += ('&start=' + start);
        }
        if (!super.isNullOrUndefined(end)) {
            url += ('&end=' + end);
        }
        window.open(encodeURI(url));
    }

    pageChanged(event: number, context: string) {
        if (context === 'solicitation') {
            this.filter.page = event;
            // console.log('ACIONADO PAGINATION MAIN TABLE');
            this.getSearch();
        } else if (context === 'simulation') {
            this.filterUser.page = event;
            // console.log('ACIONADO PAGINATION MAIN TABLE');
            this.onSearchUser();
        }
    }

    cleanFilter() {
        // console.log('USER ID ANTES DO CLEAR', this.simulationUserId);
        this.category = [
            {value: 'scholarship', checked: true},
            {value: 'training', checked: true},
            {value: 'language', checked: true},
            {value: 'refund', checked: true}
        ];
        this.simulationUserId = null;
        // console.log('USER ID DEPOIS DO CLEAR', this.simulationUserId);
        this.filter = {
            start: moment().startOf('day').subtract(90, 'days').utcOffset(0, true).format(),
            end: moment().endOf('day').utcOffset(0, true).format(),
            page: 1,
            keyword: null,
            status: undefined,
            role: undefined,
            simulationUserId: null
        };
        this.setDatePicker();

        this.simulationUserName = null;

        if (localStorage.getItem('SIMULATED_USER')) {
            this.storageService.clearSimulatedUser();
        }

        this.getSearch(true);
    }

    reopenSolicitation(id: string) {
        super.confirmMessage('Atenção', 'Deseja realmente reabrir esse solicitação?', () => {
            this.reopenSolicitationGQL.mutate({id}).subscribe(({data}) => {
                this.showMessage('Sucesso', 'Solicitação reaberta com sucesso!', 'success');
                this.pageChanged(1, 'main-table');
            }, error => super.onError(error));
        });
    }

    historySolicitation(id: string) {
        this.showModal('history');
        this.getHistoryBySolicitationIdGQL.watch({solicitationID: id}).valueChanges.subscribe(({data}) => {
            this.solicitationHistory = data.getHistoryBySolicitationID as Solicitation;
        }, error => super.onError(error));
    }

    userSimulation() {
        this.showModal('simulation');
        console.log('USER PAGE NO OPEN MODAL', this.userPage.results);
    }

    onSearchUser() {
        // console.log('FILTER USER KEYWORD', this.filterUser.keyword);

        this.searchUsersGQL.watch({
            input: this.filterUser
        }).valueChanges.subscribe(({data}) => {
            this.userPage = data.searchUsers as UserPage;
            // console.log('USER SEARCH', this.userPage);
        }, error => super.onError(error));
    }

    setSimulationUser(userId: string, simulationUserName: string) {
        // console.log('SIMULATION USER ID', userId);
        this.filter.simulationUserId = userId;
        this.simulationUserId = userId;
        // console.log('SIMULATION USER ID NO THIS', this.simulationUserId);
        this.simulationUserName = simulationUserName;
        this.closeModal('simulation');
        this.getSearch();
        this.getUser(userId);
        this.listQuery.refetch({filter: this.filter}).then((data) => {
            // console.log('REFETCH RESULT', data);
        });
    }

    getUser(userId: string) {
        this.getUserGQL
            .watch({id: userId})
            .valueChanges
            .subscribe(({ data }) => {
                console.log('DATA NO GET USER', data);
                this.storeSimulatedUser(data.getUser as User);
            });
    }

    storeSimulatedUser(user: User) {

        const simulatedUser = {
            id: user.id,
            name: user.name,
            profile: user.profile,
        };
        this.storageService.setSimulatedUser(simulatedUser);
    }
}
