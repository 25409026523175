<div class="page-header">
    <div class="container justify-content-between">
        <a [routerLink]="['/solicitacoes']" class="page-header__back">
            <svg-icon src="assets/svg/back.svg"></svg-icon>
        </a>
        <h2 class="page-header__title">{{'solicitacaotreinamento.title' | translate}}
            <strong>{{'solicitacaotreinamento.titlebold' | translate}}</strong></h2>
        <div class="page-header__date custom-date-right">{{'solicitacaotreinamento.criado' | translate}}
            <strong>{{modelDate}}</strong>
        </div>
    </div>
</div>

<div class="custom-nav">
    <ul class="nav nav-tabs custom-nav__menu custom-nav__menu-solicitation" id="myTab">
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': step == 1, 'done': step >= 2}" data-toggle="tab" href="#step-1"
               (click)="changeStep(1)">
                <span>1</span>
                <div class="bubble"></div>
                <span class="label">{{'solicitacaotreinamento.solicitante' | translate}}</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': step == 2, 'done': step >= 3}" data-toggle="tab" href="#step-2"
               (click)="changeStep(2)">
                <span>2</span>
                <div class="bubble"></div>
                <span class="label">{{'solicitacaotreinamento.colaborador' | translate}}</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link active" [ngClass]="{'active': step == 3, 'done': step >= 4}" data-toggle="tab"
               href="#step-3" (click)="changeStep(3)">
                <span>3</span>
                <div class="bubble"></div>
                <span class="label">{{'solicitacaotreinamento.curso' | translate}}</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link active" [ngClass]="{'active': step == 4, 'done': step >= 4}" data-toggle="tab"
               href="#step-4" (click)="changeStep(4)">
                <span>4</span>
                <div class="bubble"></div>
                <span class="label">{{'solicitacaotreinamento.instituicao' | translate}}</span>
            </a>
        </li>
        <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active': step == 5, 'done': done}" data-toggle="tab" href="#step-5"
               (click)="changeStep(5)">
                <span>5</span>
                <div class="bubble"></div>
                <span class="label">{{'solicitacaotreinamento.comprovantes' | translate}}</span>
            </a>
        </li>
    </ul>
</div>

<div class="reason-review" *ngIf="model.status == 'review'">
    <div class="accordion" id="accordionExample" style="width: 100%">
        <div style="background: #FFA500;">
            <button class="btn btn-block text-left review-button" type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div class="d-flex justify-content-between" style="align-items: self-end">
                    <h4 class="text-primary font-weight-bolder"><strong>{{'motivorevisao'|translate}}</strong></h4>
                    <svg-icon src="assets/svg/expand.svg"></svg-icon>
                </div>
            </button>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample"
                 style="background: #FFA500;">
                <div class="card-body pt-0 pb-2" style="background: #FFA500;">
                    <span class="text-primary" style="font-size: 18px"> {{model.response}}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="page-content">
    <div class="container">
        <div class="tab-content">
            <div id="step-1" class="tab-pane fade" [ngClass]="{'show active': step == 1 }">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="page-content__section">
                        <strong>1</strong>
                        <span>{{'solicitacaotreinamento.solicitante' | translate}}</span>
                    </h2>
                </div>
                <fieldset class="page-content__form">
                    <div class="row">
                        <div class="col-12 col-md-8">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       [(ngModel)]="colaborators.name"/>
                                <label>{{'nomeCompleto' | translate}}*</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       appOnlyNumber
                                       [(ngModel)]="colaborators.re"/>
                                <label>RE*</label>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="false" class="row">
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <ng-multiselect-dropdown
                                        [placeholder]="'&nbsp;'"
                                        [settings]="dropdownSettingsSkin"
                                        [data]="dropdownListSkin"
                                        [(ngModel)]="selectedItemsSkin"
                                        (onSelect)="onItemSelectSkin($event)"
                                        (onDeSelect)="onItemDeselectSkin($event)">
                                </ng-multiselect-dropdown>
                                <label class="disabled">{{'corpele'|translate}}*</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <ng-multiselect-dropdown
                                        [placeholder]="'&nbsp;'"
                                        [settings]="dropdownSettingsGender"
                                        [data]="dropdownListGender"
                                        [(ngModel)]="selectedItemsGender"
                                        (onSelect)="onItemSelectGender($event)"
                                        (onDeSelect)="onItemDeselectGender($event)">
                                </ng-multiselect-dropdown>
                                <label class="disabled">{{'identidadegenero'|translate}}*</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6" [ngClass]="{'col-md-12' : jumpRequester === 0}">
                            <div class="input-material">
                                <input class="form-control" id="institutionEmail" type="email"
                                       maxlength="250" name="email" required
                                       [(ngModel)]="colaborators.email"/>
                                <label for="institutionEmail">{{'E-mail' | translate}}*</label>
                            </div>
                        </div>
                        <div *ngIf="false" class="col-12 col-md-6">
                            <div class="input-material">
                                <ng-multiselect-dropdown
                                        [placeholder]="'&nbsp;'"
                                        [settings]="dropdownSettingsSexualOrientation"
                                        [data]="dropdownListSexualOrientation"
                                        [(ngModel)]="selectedItemsSexualOrientation"
                                        (onSelect)="onItemSelectSexualOrientation($event)"
                                        (onDeSelect)="onItemDeselectSexualOrientation($event)">
                                </ng-multiselect-dropdown>
                                <label class="disabled">{{'orientsex'|translate}}*</label>
                            </div>
                        </div>
                        <ng-container *ngIf="jumpRequester === 1">
                            <div class="col-12 col-md-6">
                                <div class="input-material">
                                    <input class="form-control" type="text" required maxlength="250"
                                           [clearIfNotMatch]="true"
                                           (blur)="onClearDate($event, 'dateOfBirth')"
                                           [mask]="'d0/M0/0000'" [leadZeroDateTime]="true"
                                           [dropSpecialCharacters]="false"
                                           [ngModel]="colaborators.dateOfBirth | date: 'dd/MM/yyyy'"/>
                                    <label class="disabled">{{'datanascimento'|translate}}*</label>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="input-material">
                                <ng-multiselect-dropdown
                                        [placeholder]="'&nbsp;'"
                                        [settings]="dropdownSettingsUnities"
                                        [data]="dropdownListUnities"
                                        [(ngModel)]="selectedItemsUnities"
                                        (onSelect)="onItemSelectUnities($event)"
                                        (onDeSelect)="onItemDeselectUnities($event)"
                                >
                                </ng-multiselect-dropdown>
                                <label class="disabled">{{'bolsa.unidade' | translate}}*</label>
                            </div>
                        </div>
                        <div class="col-6 col-md-6">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       [(ngModel)]="colaborators.areaManager"/>
                                <label>{{'bolsa.departamento' | translate}}*</label>
                            </div>
                        </div>
                        <div class="col-6 col-md-2">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       appOnlyNumber
                                       [(ngModel)]="colaborators.ramal"/>
                                <label>{{'bolsa.ramal' | translate}}*</label>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="jumpRequester === 1">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="input-material">
                                    <input class="form-control" type="text" required maxlength="250"
                                           [(ngModel)]="colaborators.currentJob"/>
                                    <label>{{'bolsa.cargo' | translate}}*</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="input-material">
                                    <input class="form-control" type="text" required maxlength="250"
                                           [clearIfNotMatch]="true"
                                           (blur)="onClearRequesterDate($event, 'admissionDate')"
                                           [mask]="'d0/M0/0000'" [leadZeroDateTime]="true"
                                           [dropSpecialCharacters]="false"
                                           [ngModel]="user.admissionDate | date: 'dd/MM/yyyy'"
                                           [ngClass]="user.admissionDate ? 'focus-simulated' : ''"
                                           disabled
                                    />
                                    <label>
                                        <svg-icon src="assets/svg/calendar.svg"></svg-icon>
                                        <span>{{'bolsa.admissao' | translate}}*</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="input-material">
                                    <input class="form-control" appOnlyNumber type="text" required maxlength="250"
                                           [(ngModel)]="colaborators.costCenter"/>
                                    <label>{{'bolsa.centro' | translate}}*</label>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="input-material">
                                    <input class="form-control" type="text" required maxlength="250"
                                           [(ngModel)]="colaborators.directorArea"/>
                                    <label>{{'bolsa.gerente' | translate}}*</label>
                                </div>
                            </div>
                        </div>
                    </ng-container>


                </fieldset>
                <div class="page-content__buttons">
                    <a [routerLink]="['/solicitacoes']" class="btn btn-light">{{'voltar' | translate}}</a>
                    <!--<button class="btn btn-warning" (click)="changeStep(2)">{{'proximo' | translate}}</button>-->
                    <button class="btn btn-warning" (click)="changeStep(2,jumpRequester)">{{'proximo' | translate}}</button>
                </div>
            </div>
            <div id="step-2" class="tab-pane fade" [ngClass]="{'show active': step == 2 }">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="page-content__section">
                        <strong>2</strong>
                        <span>{{'colaborador' | translate}}</span>
                    </h2>
                    <!--                    <label for="list" class="page-content__add btn btn-light">-->
                    <!--                        <svg-icon src="assets/svg/plus.svg"></svg-icon>-->
                    <!--                        <span>Adicionar lista de colaboradores</span>-->
                    <!--                        <input type="file" name="list" id="list">-->
                    <!--                    </label>-->
                    <button class="page-content__add btn btn-light" data-toggle="modal" data-target="#contributors">
                        <svg-icon src="assets/svg/plus.svg"></svg-icon>
                        <span>{{'solicitacaotreinamento.adicionarcol' | translate}}</span>
                    </button>
                </div>
                <div class="page-content__form full ml-0 mr-0">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">{{'nome' | translate}}</th>
                                <th scope="col">RE</th>
                                <th scope="col">E-mail</th>
                                <th scope="col">{{translate.instant('bolsa.nascimento')}}</th>
                                <th scope="col">{{'unidade' | translate}}</th>
                                <th scope="col">{{'departamento/setor' | translate}}</th>
                                <th scope="col">{{'ramal' | translate}}</th>
                                <th scope="col">{{'dataadm' | translate}}</th>
                                <th scope="col">{{'cargo' | translate}}</th>
                                <th scope="col">{{'treinamento.costCenter' | translate}}</th>
                                <th scope="col">{{'gerarea' | translate}}</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngIf="dataSolicitation.colaborators.length === 1">
                                <td colspan="11">
                                    <p class="py-5 text-center">Nenhum colaborador cadastrado</p>
                                </td>
                            </tr>
                            <ng-container *ngFor="let x of dataSolicitation.colaborators">
                                <tr *ngIf="!x.isRequester">
                                    <td scope="row">{{x.name}}</td>
                                    <td>{{x.re}}</td>
                                    <td>{{x.email}}</td>
                                    <td>{{x.dateOfBirth | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{x.unity.name}}</td>
                                    <td>{{x.areaManager}}</td>
                                    <td>{{x.ramal}}</td>
                                    <td>{{x.admissionDate | date: 'dd/MM/yyyy'}}</td>
                                    <td>{{x.currentJob}}</td>
                                    <td>{{x.costCenter}}</td>
                                    <td>{{x.directorArea}}</td>
                                    <td>
                                        <div (click)="onColaboratorsRemove(x.re)">
                                            <svg-icon class="remove" src="assets/svg/close-rounded.svg"></svg-icon>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="page-content__buttons">

                    <button class="btn btn-light" (click)="backStep(1)">{{'voltar' | translate}}</button>
                    <button class="btn btn-warning" (click)="changeStep(3)">{{'proximo' | translate}}</button>
                </div>
            </div>
            <div id="step-3" class="tab-pane fade" [ngClass]="{'show active': step == 3 }">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="page-content__section">
                        <strong>3</strong>
                        <span>{{'solicitacaotreinamento.curso' | translate}}</span>
                    </h2>
                </div>
                <form action="#" class="page-content__form">
                    <div class="input-material">
                        <input class="form-control" type="text" required maxlength="250"
                               name="nameCourse"
                               [(ngModel)]="dataSolicitation.course.name"/>
                        <label>{{'solicitacaotreinamento.nomecurso' | translate}}*</label>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       name="startCourse"
                                       [clearIfNotMatch]="true"
                                       (blur)="onClearCourseDate($event, 'start')"
                                       [mask]="'d0/M0/0000'" [leadZeroDateTime]="true"
                                       [dropSpecialCharacters]="false"
                                       [ngModel]="dataSolicitation.course.start | date: 'dd/MM/yyyy'"/>
                                <label>
                                    <svg-icon src="assets/svg/calendar.svg"></svg-icon>
                                    <span>{{'dataini' | translate}}*</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-6 col-md-6">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       name="endCourse"
                                       [clearIfNotMatch]="true"
                                       (blur)="onClearCourseDate($event, 'end')"
                                       [mask]="'d0/M0/0000'" [leadZeroDateTime]="true"
                                       [dropSpecialCharacters]="false"
                                       [ngModel]="dataSolicitation.course.end | date: 'dd/MM/yyyy'"/>
                                <label>
                                    <svg-icon src="assets/svg/calendar.svg"></svg-icon>
                                    <span>{{'datater' | translate}}*</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       name="workloadCourse"
                                       [(ngModel)]="dataSolicitation.course.workload"/>
                                <label>{{'solicitacaotreinamento.cargahor' | translate}}*</label>
                            </div>
                        </div>
                        <!--<div class="col-12 col-md-3"></div>-->
                        <div class="col-12 col-md-7">
                            <div class="row align-items-center flex-md-row flex-column mt-3 mt-md-0 justify-content-center justify-content-md-start">
                                <div class="col-auto mb-3 mb-md-0">
                                    <div class="form-check form-check-inline">
                                        <input [(ngModel)]="this.dataSolicitation.course.typeOfCurrency"
                                               class="form-check-input" type="radio" name="inlineRadioOptions"
                                               id="inlineRadio1" value="real">
                                        <label class="form-check-label" for="inlineRadio1">Real</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input [(ngModel)]="this.dataSolicitation.course.typeOfCurrency"
                                               class="form-check-input" type="radio" name="inlineRadioOptions"
                                               id="inlineRadio2" value="euro">
                                        <label class="form-check-label" for="inlineRadio2">Euro</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input [(ngModel)]="this.dataSolicitation.course.typeOfCurrency"
                                               class="form-check-input" type="radio" name="inlineRadioOptions"
                                               id="inlineRadio3" value="dolar">
                                        <label class="form-check-label" for="inlineRadio3">Dólar</label>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="input-material mb-0">
                                        <input class="form-control" type="text" required maxlength="250"
                                               currencyMask
                                               name="valueCourse"
                                               [options]="{ prefix: getSymbolCurrency(this.dataSolicitation.course.typeOfCurrency) +' '}"
                                               [(ngModel)]="dataSolicitation.course.value"/>
                                        <label>{{'solicitacaotreinamento.valorcurso' | translate}}*</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="row align-items-center flex-md-row flex-column mt-4 mt-md-0">
                                <div class="col">
                                    <div class="mb-0 d-flex justify-content-center justify-content-md-start">
                                        <p>{{'selecioneotipodetreinamento'|translate}}* :</p>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="form-check form-check-inline">
                                        <input [(ngModel)]="this.dataSolicitation.course.trainingType"
                                               class="form-check-input" type="radio" name="lectureRadioOptions"
                                               id="lectureRadio" value="lecture">
                                        <label class="form-check-label"
                                               for="lectureRadio">{{'palestra'|translate}}</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input [(ngModel)]="this.dataSolicitation.course.trainingType"
                                               class="form-check-input" type="radio" name="courseRadioOptions"
                                               id="courseRadio" value="course">
                                        <label class="form-check-label"
                                               for="courseRadio">{{'curso'|translate}}</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input [(ngModel)]="this.dataSolicitation.course.trainingType"
                                               class="form-check-input" type="radio" name="form-check-labelOptions"
                                               id="congressRadio" value="congress">
                                        <label class="form-check-label"
                                               for="congressRadio">{{'congresso'|translate}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="page-content__buttons">
                    <ng-container *ngIf="dataSolicitation.colaborators.length > 1">
                        <button class="btn btn-light" (click)="backStep(2)">{{'voltar' | translate}}</button>
                    </ng-container>
                    <ng-container *ngIf="dataSolicitation.colaborators.length === 1">
                        <button class="btn btn-light" (click)="backStep(1)">{{'voltar' | translate}}</button>
                    </ng-container>
                    <button class="btn btn-warning" (click)="changeStep(4)">{{'proximo' | translate}}</button>
                </div>
            </div>
            <div id="step-4" class="tab-pane fade" [ngClass]="{'show active': step == 4 }">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="page-content__section">
                        <strong>4</strong>
                        <span>{{'solicitacaotreinamento.instituicao' | translate}}</span>
                    </h2>
                </div>
                <form action="#" class="page-content__form">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="input-material">
                                <input class="form-control" autocomplete="false" type="text" required
                                       maxlength="250"
                                       name="nameInstitution"
                                       [(ngModel)]="dataSolicitation.institution.name"/>
                                <label>{{'solicitacaotreinamento.nomeinstitui' | translate}}*</label>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="input-material">
                                <input class="form-control" type="email" required maxlength="250"
                                       name="emailInstitution"
                                       [(ngModel)]="dataSolicitation.institution.email"/>
                                <label>{{'solicitacaotreinamento.emailcontato' | translate}}*</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto">
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="document"
                                       id="CPF" value="CPF" [(ngModel)]="this.documentType"
                                       (click)="dataSolicitation.institution.cnpj_cpf = ''">
                                <label class="form-check-label" for="CPF"> CPF </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="document"
                                       id="CNPJ" value="CNPJ" [(ngModel)]="this.documentType"
                                       (click)="dataSolicitation.institution.cnpj_cpf = ''">
                                <label class="form-check-label" for="CNPJ"> CNPJ </label>
                            </div>
                        </div>
                        <div class="col">
                            <div class="input-material">
                                <input class="form-control" [ngModelOptions]="{standalone: true}" type="text"
                                       required
                                       maxlength="250"
                                       name="cnpj_cpfInstitution"
                                       [clearIfNotMatch]="true"
                                       [mask]="this.documentType == 'CPF'?'000.000.000-00':'00.000.000/0000-00'"
                                       (blur)="onClearModelInstitution($event, 'cnpj')"
                                       [dropSpecialCharacters]="false"
                                       [(ngModel)]="dataSolicitation.institution.cnpj_cpf"/>
                                <label>{{documentType}}*</label>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="custom-control custom-switch" style="padding-left: 2.75rem;">
                                <input type="checkbox" class="custom-control-input"
                                       id="check" name="check" [(ngModel)]="dataSolicitation.institution.isOnline">
                                <label class="custom-control-label"
                                       for="check">Online</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-material">
                                <input class="form-control" [ngModelOptions]="{standalone: true}" type="text"
                                       required
                                       maxlength="250"
                                       name="phoneInstitution"
                                       [clearIfNotMatch]="true"
                                       [mask]="'(00) 0000-0000||(00) 00000-0000'"
                                       (blur)="onClearModelInstitution($event, 'phone')"
                                       [dropSpecialCharacters]="false"
                                       [(ngModel)]="dataSolicitation.institution.phone"/>
                                <label>{{'solicitacaotreinamento.telcontato' | translate}}*</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-material">
                                <input class="form-control" [ngModelOptions]="{standalone: true}" type="text"
                                       required
                                       maxlength="250"
                                       name="phone2Institution"
                                       [clearIfNotMatch]="true"
                                       [mask]="'(00) 0000-0000||(00) 00000-0000'"
                                       (blur)="onClearModelInstitution($event, 'phone2')"
                                       [dropSpecialCharacters]="false"
                                       [(ngModel)]="dataSolicitation.institution.phone2"/>
                                <label>{{'solicitacaotreinamento.telcontato' | translate}} 2</label>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-material">
                                <input class="form-control" autocomplete="false" type="text" required
                                       maxlength="250"
                                       name="responsibleInstitution"
                                       [(ngModel)]="dataSolicitation.institution.responsible"/>
                                <label>{{'solicitacaotreinamento.nomeres' | translate}}*</label>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="page-content__buttons pt-3">

                    <button class="btn btn-light" (click)="backStep(3)">{{'voltar' | translate}}</button>
                    <button class="btn btn-warning" (click)="changeStep(5)">{{'proximo' | translate}}</button>
                </div>
            </div>
            <div id="step-5" class="tab-pane fade" [ngClass]="{'show active': step == 5 }">
                <div class="d-flex justify-content-between align-items-center">
                    <h2 class="page-content__section">
                        <strong>5</strong>
                        <span>{{'solicitacaotreinamento.comprovante' | translate}}</span>
                    </h2>
                </div>
                <form action="#" class="page-content__form full justify-content-center ml-0 mr-0">
                    <div class="row">
                        <div class="col-8">
                            <p>{{'solicitacaotreinamento.dequeforma' | translate}}</p>
                            <div class="input-material w-full mt-3 input-material-textarea">
                                <textarea class="input-material form-control w-full small" rows="11" required
                                          name="receiptJustification"
                                          [(ngModel)]="dataSolicitation.receiptJustification"></textarea>
                                <label>{{'solicitacaotreinamento.justificativa' | translate}}</label>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="d-flex flex-column">
                                <div class="page-content__upload single">
                                    <svg-icon class="icon svg-icon" src="assets/svg/check.svg"
                                              [ngClass]="{'svg-icon-success': !isNullOrUndefined(pathImageObj['primary'].path)}"></svg-icon>
                                    <p>{{'solicitacaotreinamento.prospecto' | translate}}</p>
                                    <p>{{'file-types-img' | translate}}</p>
                                    <label for="doc-1" class="btn btn-primary  page-content__upload__input">
                                        <svg-icon src="assets/svg/attachment.svg"></svg-icon>
                                        <span>{{'anexar' | translate}}</span>
                                        <input type="file" id="doc-1" name="doc-1" required
                                               onclick="this.value = null"
                                               accept=".image/, .pdf, .png, .jpg, .jpeg"
                                               (change)="handlerUploadSingle($event, 'course', 'primary')"/>
                                    </label>
                                    <div *ngIf="!isNullOrUndefined(pathImageObj['primary'].path)">
                                        {{pathImageObj['primary'].name}}
                                        <svg-icon (click)="removeFile('course', 'primary')" class="icon svg-icon"
                                                  src="assets/svg/trash.svg">
                                        </svg-icon>
                                    </div>
                                </div>
                                <div class="gap-20 d-flex mb-3" *ngIf="jumpRequester === 1">
                                    <input [(ngModel)]="this.dataSolicitation.isAcceptPolicy" type="checkbox"
                                           name="form-check-labelOptionsPolicts"
                                           id="politcsRadio" value="politcs">
                                    <label class="form-check-label"
                                           for="politcsRadio">Declaro estar ciente com a
                                        <ng-container *ngFor="let x of politic">
                                            <a *ngIf="x.typeSolicitation ==='training'"
                                               [attr.href]="x.linkPolicyDocument">
                                                Política de Treinamento
                                            </a>
                                        </ng-container>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <ng-container *ngIf="jumpRequester == 1 && !urlForAdminEdit">
                            <div class="col-12">
                                <div class="page-content__upload signature">
                                    <div class="row align-items-center">
                                        <div class="col-9">
                                            <img *ngIf="!this.isNullOrUndefined(paintScreen.preview)"
                                                 src="{{paintScreen!.preview}}" alt="screen">
                                        </div>
                                        <div class="col-3">
                                            <button class="btn btn-primary"
                                                    (click)="controlFlagPaint = !controlFlagPaint">
                                                {{'openSignature'|translate}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </form>

                <div class="page-content__buttons">
                    <button class="btn btn-light" (click)="backStep(4)">{{'voltar' | translate}}</button>
                    <button class="btn btn-warning" (click)="saveConfirm()">{{'proximo' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="questContributors" class="modal fade" data-backdrop="static" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'solicitacao.card2title' | translate}}</h5>
                <!--                <button type="button" class="close" data-dismiss="modal">-->
                <!--                    <svg-icon src="assets/svg/close.svg"></svg-icon>-->
                <!--                </button>-->
            </div>
            <div class="modal-body" style="min-height: initial">
                <span class="modal-loading" *ngIf="false"></span>
                <div>
                    <h2 class="modal-body-title">{{'solicitacao.innercardtitle' | translate}}</h2>
                    <ul class="modal-body-list">
                        <li>
                            <a (click)="jumpRequester = 0" data-dismiss="modal">{{'otreinamentoeparaseus'|translate}}
                                <strong>{{'colaboradores?'|translate}}</strong>
                            </a>
                        </li>
                        <li>
                            <a (click)="jumpRequester = 1" data-dismiss="modal">{{'otreinamentoeparavoce'|translate}}
                                <strong>{{'voce?'|translate}}</strong>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="contributors" class="modal fade" tabindex="-1">
    <div class="modal-dialog  modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{'colaborador' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body mb-0">
                <fieldset class="page-content__form mb-0">
                    <div class="row">
                        <div class="col-12 col-md-8">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       [(ngModel)]="modelColaborator.name"/>
                                <label>{{'nome' | translate}}*</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-4">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       [(ngModel)]="modelColaborator.re"
                                       (blur)="getUserByRe()"
                                />
                                <label>RE*</label>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="false" class="row">
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <ng-multiselect-dropdown
                                        [placeholder]="'&nbsp;'"
                                        [settings]="dropdownSettingsSkin"
                                        [data]="dropdownListSkinColaborators"
                                        [(ngModel)]="selectedItemsSkinColaborators"
                                        (onSelect)="onItemSelectSkinColaborators($event)"
                                        (onDeSelect)="onItemDeselectSkinColaborators($event)">
                                </ng-multiselect-dropdown>
                                <label class="disabled">{{'corpele'|translate}}*</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <ng-multiselect-dropdown
                                        [placeholder]="'&nbsp;'"
                                        [settings]="dropdownSettingsGender"
                                        [data]="dropdownListGenderColaborators"
                                        [(ngModel)]="selectedItemsGenderColaborators"
                                        (onSelect)="onItemSelectGenderColaborators($event)"
                                        (onDeSelect)="onItemDeselectGenderColaborators($event)">
                                </ng-multiselect-dropdown>
                                <label class="disabled">{{'identidadegenero'|translate}}*</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div *ngIf="false" class="col-12 col-md-6">
                            <div class="input-material">
                                <ng-multiselect-dropdown
                                        [placeholder]="'&nbsp;'"
                                        [settings]="dropdownSettingsSexualOrientation"
                                        [data]="dropdownListSexualOrientationColaborators"
                                        [(ngModel)]="selectedItemsSexualOrientationColaborators"
                                        (onSelect)="onItemSelectSexualOrientationColaborators($event)"
                                        (onDeSelect)="onItemDeselectSexualOrientationColaborators($event)">
                                </ng-multiselect-dropdown>
                                <label class="disabled">{{'orientsex'|translate}}*</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <input class="form-control" id="iEmail" type="email"
                                       maxlength="250" name="email" required
                                       [(ngModel)]="modelColaborator.email"/>
                                <label for="iEmail">{{'E-mail' | translate}}*</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       [clearIfNotMatch]="true"
                                       (blur)="onClearColaboratorsDate($event, 'dateOfBirth')"
                                       [mask]="'d0/M0/0000'" [leadZeroDateTime]="true"
                                       [dropSpecialCharacters]="false"
                                       [ngModel]="modelColaborator.dateOfBirth | date: 'dd/MM/yyyy'"/>
                                <label class="disabled">Data de nascimento*</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <div class="input-material">
                                <ng-multiselect-dropdown
                                        [placeholder]="'&nbsp;'"
                                        [settings]="dropdownSettingsUnities"
                                        [data]="dropdownListUnitiesColaborators"
                                        [(ngModel)]="selectedItemsUnitiesColaborators"
                                        (onSelect)="onItemSelectUnitiesColaborators($event)"
                                        (onDeSelect)="onItemDeselectUnitiesColaborators($event)">
                                </ng-multiselect-dropdown>
                                <label class="disabled">{{'bolsa.unidade' | translate}}*</label>
                            </div>
                        </div>
                        <div class="col-6 col-md-6">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       [(ngModel)]="modelColaborator.areaManager"/>
                                <label>{{'bolsa.departamento' | translate}}*</label>
                            </div>
                        </div>
                        <div class="col-6 col-md-2">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       appOnlyNumber
                                       [(ngModel)]="modelColaborator.ramal"/>
                                <label>{{'bolsa.ramal' | translate}}*</label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       [(ngModel)]="modelColaborator.currentJob"/>
                                <label>{{'bolsa.cargo' | translate}}*</label>
                            </div>
                        </div>
                        <!--                        <div class="col-6 col-md-4">-->
                        <!--                            <div class="input-material">-->
                        <!--                                <select class="form-control" required-->
                        <!--                                        [(ngModel)]="modelColaborator.salaryRange">-->
                        <!--                                    <option disabled value hidden selected></option>-->
                        <!--                                    <option selected-->
                        <!--                                            [ngValue]="null">{{'minhalista.input3.subtitle' | translate}}</option>-->
                        <!--                                    <option *ngFor="let v of salaries " [ngValue]="v.value">{{v.value}}</option>-->
                        <!--                                </select>-->
                        <!--                                <label>{{'bolsa.faixa' | translate}}*</label>-->
                        <!--                            </div>-->
                        <!--                        </div>-->
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       [clearIfNotMatch]="true"
                                       (blur)="onClearColaboratorsDate($event, 'admissionDate')"
                                       [mask]="'d0/M0/0000'" [leadZeroDateTime]="true"
                                       [dropSpecialCharacters]="false"
                                       [ngModel]="collaborator.admissionDate | date: 'dd/MM/yyyy'"
                                       [ngClass]="collaborator.admissionDate ? 'focus-simulated' : ''"
                                       disabled
                                />
                                <label>
                                    <svg-icon src="assets/svg/calendar.svg"></svg-icon>
                                    <span>{{'bolsa.admissao' | translate}}*</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <input class="form-control" appOnlyNumber type="text" required maxlength="250"
                                       [(ngModel)]="modelColaborator.costCenter"/>
                                <label>{{'bolsa.centro' | translate}}*</label>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="input-material">
                                <input class="form-control" type="text" required maxlength="250"
                                       [(ngModel)]="modelColaborator.directorArea"/>
                                <label>{{'bolsa.gerente' | translate}}*</label>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="modal-footer">
                <div class="page-content__buttons">
                    <button class="btn btn-warning"
                            (click)="onColaboratorsAdd();">{{'proximo' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="success" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <span class="modal-body-success">
                    <svg-icon src="assets/svg/success.svg"></svg-icon>
                    <p>{{'solicitacaotreinamento.final1' | translate}}
                        <strong>{{'solicitacaotreinamento.final2' | translate}}</strong>{{'solicitacaotreinamento.final3' | translate}}
                        <strong>{{'solicitacaotreinamento.final4' | translate}}</strong>.</p>
                    <button class="btn btn-block btn-warning mt-4" data-dismiss="modal">{{'fechar'|translate}}</button>
                </span>
            </div>
        </div>
    </div>
</div>

<div id="resume-treinamentos" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-custom modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </button>
            </div>
            <div class="modal-body modal-body-custom flex-column">
                <div class="d-flex w-full flex-column">
                    <div class="pb-3 d-flex justify-content-between align-items-center">
                        <h2 class="page-content__section first">
                            <strong>1</strong>
                            <span>{{'solicitacaotreinamento.solicitante' | translate}}</span>
                        </h2>
                    </div>
                    <div class="row w-full d-flex mb-md-4 justify-content-between">
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'nomeCompleto' | translate}}</strong><br/>
                                {{colaborators?.name}}
                            </div>
                        </div>
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>RE</strong><br/>
                                {{colaborators?.re}}
                            </div>
                        </div>
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>E-mail</strong><br/>
                                {{colaborators?.email}}
                            </div>
                        </div>
                    </div>

                    <div *ngIf="false" class="row w-full d-flex mb-md-4 justify-content-between">
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'corpele'|translate}}</strong><br/>
                                {{getSkinById(colaborators?.skinColor?.id)}}
                            </div>
                        </div>
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'identidadegenero'|translate}}</strong><br/>
                                {{getIdentityGenderById(colaborators?.genderIdentity?.id)}}
                            </div>
                        </div>
                        <div class="col-12 col-md-4 d-flex mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'orientsex'|translate}}</strong><br/>
                                {{getSexualOrientationById(colaborators?.sexualOrientation?.id)}}
                            </div>
                        </div>
                    </div>

                    <div class="row w-full d-flex mb-md-4 justify-content-between">
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'bolsa.unidade' | translate}}</strong><br/>
                                {{colaborators?.unity?.name}}
                            </div>
                        </div>
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'bolsa.departamento' | translate}}</strong><br/>
                                {{colaborators?.areaManager}}
                            </div>
                        </div>
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'bolsa.ramal' | translate}}</strong><br/>
                                {{colaborators?.ramal}}
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf="jumpRequester === 1">
                        <div class="row w-full d-flex mb-md-4 justify-content-between">
                            <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                                <div class="label-text-custom">
                                    <strong>{{'bolsa.cargo' | translate}}</strong><br/>
                                    {{colaborators?.currentJob}}
                                </div>
                            </div>
                            <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                                <div class="label-text-custom">
                                    <strong>{{'bolsa.admissao' | translate}}</strong><br/>
                                    {{colaborators?.admissionDate | date: 'dd/MM/yyyy'}}
                                </div>
                            </div>
                            <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                                <div class="label-text-custom">
                                    <strong>{{'bolsa.centro' | translate}}</strong><br/>
                                    {{colaborators?.costCenter}}
                                </div>
                            </div>

                        </div>
                        <div class="row w-full d-flex mb-md-4 justify-content-between">
                            <div class="col-12 col-md-4 d-flex mb-4 mb-md-0">
                                <div class="label-text-custom">
                                    <strong>{{'bolsa.gerente' | translate}}</strong><br/>
                                    {{colaborators?.directorArea}}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="d-flex w-full flex-column">
                    <div class="pb-3 d-flex justify-content-between align-items-center">
                        <h2 class="page-content__section first">
                            <strong>2</strong>
                            <span>{{'colaborador' | translate}}</span>
                        </h2>
                    </div>
                    <div class="row w-full d-flex mb-md-4 justify-content-between">
                        <div class="col-12">
                            <p *ngIf="dataSolicitation.colaborators?.length === 1">{{'treinamentoParaVoce' | translate}}</p>
                            <p *ngIf="dataSolicitation.colaborators?.length > 1">{{'treinamentoParaEquipe' | translate}}</p>
                            <div class="table-responsive" *ngIf="dataSolicitation.colaborators?.length > 1">
                                <table class="table table-custom table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">{{'nome' | translate}}</th>
                                        <th scope="col">RE</th>
                                        <th scope="col">E-mail</th>
                                        <th scope="col">{{'unidade' | translate}}</th>
                                        <th scope="col">{{'departamento/setor' | translate}}</th>
                                        <th scope="col">{{'ramal' | translate}}</th>
                                        <th scope="col">{{'dataadm' | translate}}</th>
                                        <th scope="col">{{'cargo' | translate}}</th>
                                        <th scope="col">{{'treinamento.costCenter' | translate}}</th>
                                        <th scope="col">{{'gerarea' | translate}}</th>
                                        <th scope="col"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <ng-container *ngFor="let x of dataSolicitation.colaborators">
                                        <tr *ngIf="!x.isRequester">
                                            <td scope="row">{{x.name}}</td>
                                            <td>{{x.re}}</td>
                                            <td>{{x.email}}</td>
                                            <td>{{x.unity.name}}</td>
                                            <td>{{x.areaManager}}</td>
                                            <td>{{x.ramal}}</td>
                                            <td>{{x.admissionDate | date: 'dd/MM/yyyy'}}</td>
                                            <td>{{x.currentJob}}</td>
                                            <td>{{x.costCenter}}</td>
                                            <td>{{x.directorArea}}</td>
                                        </tr>
                                    </ng-container>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex w-full flex-column">
                    <div class="pb-3 d-flex justify-content-between align-items-center">
                        <h2 class="page-content__section first">
                            <strong>3</strong>
                            <span>{{'solicitacaotreinamento.curso' | translate}}</span>
                        </h2>
                    </div>
                    <div class="row w-full d-flex mb-md-4 justify-content-between">
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'solicitacaotreinamento.nomecurso' | translate}}</strong><br/>
                                {{dataSolicitation.course?.name}}
                            </div>
                        </div>
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'dataini' | translate}}</strong><br/>
                                {{dataSolicitation.course?.start | date: 'dd/MM/yyyy'}}
                            </div>
                        </div>
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'datater' | translate}}</strong><br/>
                                {{dataSolicitation.course?.end | date: 'dd/MM/yyyy'}}
                            </div>
                        </div>
                    </div>
                    <div class="row w-full d-flex mb-md-4">
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'solicitacaotreinamento.cargahor' | translate}}</strong><br/>
                                <span>{{dataSolicitation.course?.workload}}</span>
                            </div>
                        </div>
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'solicitacaotreinamento.valorcurso' | translate}}</strong><br/>
                                {{dataSolicitation.course?.value | currency:getSymbolCurrency(dataSolicitation.course?.typeOfCurrency)}}
                            </div>
                        </div>
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'solicitacaotreinamento.tipotreinamento' | translate}}</strong><br/>
                                {{dataSolicitation.course?.trainingType === 'lecture' ? 'Palestra' : (dataSolicitation.course?.trainingType === 'course' ? 'Curso' : 'Congresso')}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex w-full flex-column">
                    <div class="pb-3 d-flex justify-content-between align-items-center">
                        <h2 class="page-content__section first">
                            <strong>4</strong>
                            <span>{{'solicitacaotreinamento.instituicao' | translate}}</span>
                        </h2>
                    </div>
                    <div class="row w-full d-flex mb-md-4 justify-content-between">
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'solicitacaotreinamento.nomeinstitui' | translate}}</strong><br/>
                                {{dataSolicitation.institution.name}}
                            </div>
                        </div>
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'solicitacaotreinamento.emailcontato' | translate}}</strong><br/>
                                {{dataSolicitation.institution.email}}
                            </div>
                        </div><!---->
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'solicitacaotreinamento.telcontato' | translate}}</strong><br/>
                                {{dataSolicitation.institution.phone}}
                            </div>
                        </div>
                    </div>
                    <div class="row w-full d-flex mb-md-4">
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'solicitacaotreinamento.telcontato2' | translate}}</strong><br/>
                                {{dataSolicitation.institution.phone2}}
                            </div>
                        </div>
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'solicitacaotreinamento.nomeres' | translate}}</strong><br/>
                                {{dataSolicitation.institution.responsible}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex w-full flex-column">
                    <div class="pb-3 d-flex justify-content-between align-items-center">
                        <h2 class="page-content__section first">
                            <strong>5</strong>
                            <span>{{'solicitacaotreinamento.comprovante' | translate}}</span>
                        </h2>
                    </div>
                    <div class="row w-full d-flex mb-md-4 justify-content-between">
                        <div class="col-12 d-flex col-md-6 mb-4 mb-md-0">
                            <div class="label-text-custom">
                                <strong>{{'solicitacaotreinamento.dequeforma' | translate}}</strong><br/>
                                {{dataSolicitation.receiptJustification}}
                            </div>
                        </div>
                        <div class="col-12 d-flex col-md-4 mb-4 mb-md-0 flex-column">
                            <div class="label-text-custom justify-content-center align-content-center d-flex flex-column">
                                <div class="label-text-custom-download mb-2">
                                    <svg-icon src="assets/svg/attachment.svg"></svg-icon>
                                    <br/>
                                </div>
                                <ng-container *ngIf="isNullOrUndefined(pathImageObj['primary']?.path)">
                                    <p>{{'nenhumArquivo' | translate}}</p>
                                </ng-container>
                                <ng-container *ngIf="!isNullOrUndefined(pathImageObj['primary']?.path)">
                                    <a href="{{pathImageObj['primary'].path}}" download
                                       class="btn btn-warning">Download</a>
                                    <span class="mt-2 text-break-all">{{pathImageObj['primary'].name}}</span>
                                </ng-container>
                            </div>
                            <div *ngIf="dataSolicitation.isAcceptPolicy" class="text-center mt-2">
                                <strong>Declaro estar ciente com a Política de Treinamento</strong>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer w-full justify-content-between" type="button" data-dismiss="modal">
                <button class="btn btn-light">{{'cancelar2' | translate}}</button>
                <button class="btn btn-warning" (click)="changeStep(6);">{{'finalizar' | translate}}</button>
            </div>
        </div>
    </div>
</div>

<div id="approved" class="modal" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <span class="modal-body-success">
                    <svg-icon src="assets/svg/success.svg"></svg-icon>
                    <p class="mb-3" [innerHTML]="'treinamento.modalTextoSucesso' | translate"></p>
                    <span><strong>{{'numerodasolicitacao'|translate}}: </strong>{{numberSolicitation}}</span>
                    <button class="btn btn-block btn-warning mt-4"
                            data-dismiss="modal">{{'fechar' | translate}}</button>
                </span>
            </div>
        </div>
    </div>
</div>

<app-paint-screen [(flagSignature)]="controlFlagPaint" (paintScreen)="paintScreen = $event"></app-paint-screen>
