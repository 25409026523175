import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {LogoutComponent} from './views/logout/logout.component';
import {RegisterComponent} from './views/register/register.component';
import {HomeComponent} from './views/home/home.component';
import {MyListComponent} from './views/my-list/my-list.component';
import {RegisterDetailComponent} from './views/register-detail/register-detail.component';
import {RequestsComponent} from './views/requests/requests.component';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {BolsaDeEstudosComponent} from './views/bolsa-de-estudos/bolsa-de-estudos.component';
import {TreinamentosComponent} from './views/treinamentos/treinamentos.component';
import {BolsaDeIdiomasComponent} from './views/bolsa-de-idiomas/bolsa-de-idiomas.component';
import {ReembolsosComponent} from './views/reembolsos/reembolsos.component';
import {ListaDeSolicitacoesComponent} from './views/lista-de-solicitacoes/lista-de-solicitacoes.component';
import {DetalheDaBolsaComponent} from './views/detalhe-da-bolsa/detalhe-da-bolsa.component';
import {DetalheBolsaDeEstudosComponent} from './views/detalhe-bolsa-de-estudos/detalhe-bolsa-de-estudos.component';
import {DetalheReembolsoComponent} from './views/detalhe-reembolso/detalhe-reembolso.component';
import {DetalheBolsaDeIdiomaComponent} from './views/detalhe-bolsa-de-idioma/detalhe-bolsa-de-idioma.component';
import {DetalheTreinamentoComponent} from './views/detalhe-treinamento/detalhe-treinamento.component';

import {AuthGuard} from './service/auth-guard.service';
import {ReembolsosIdiomasComponent} from "./views/reembolsos-idiomas/reembolsos-idiomas.component";
import {ConfigPoliticComponent} from "./views/config-politic/config-politic.component";
import {ConfigSalaryComponent} from "./views/config-salary/config-salary.component";
import {LoginComponent} from "./views/login/login.component";
import {LogBolsaEstudoComponent} from "./views/log-bolsa-estudo/log-bolsa-estudo.component";
import {LogIdiomaComponent} from "./views/log-idioma/log-idioma.component";
import {LogTreinamentoComponent} from "./views/log-treinamento/log-treinamento.component";
import {LogReembolsoComponent} from "./views/log-reembolso/log-reembolso.component";

const routes: Routes = [
    {
        path: 'authenticate', component: AuthenticateComponent
    },
    {
        path: 'logout', component: LogoutComponent
    },
    {path: 'login', component: LoginComponent},
    {
        path: '', component: MainComponent,
        children: [
            {path: '', component: ListaDeSolicitacoesComponent},
            {path: 'register', component: RegisterComponent},
            {path: 'register/:id', component: RegisterComponent},
            {path: 'register-detail', component: RegisterDetailComponent},
            {path: 'home', component: ListaDeSolicitacoesComponent},
            {path: 'my-list', component: MyListComponent},
            {path: 'solicitacoes', component: RequestsComponent},
            {path: 'lista-de-solicitacoes', component: ListaDeSolicitacoesComponent},
            {path: 'bolsa-de-estudos/:id', component: BolsaDeEstudosComponent},
            {path: 'bolsa-de-estudos-admin/:id', component: BolsaDeEstudosComponent},
            {path: 'bolsa-de-estudos', component: BolsaDeEstudosComponent},
            {path: 'log-bolsa-estudo/:id', component: LogBolsaEstudoComponent},
            {path: 'treinamentos/:id', component: TreinamentosComponent},
            {path: 'treinamentos', component: TreinamentosComponent},
            {path: 'treinamentos-admin/:id', component: TreinamentosComponent},
            {path: 'bolsa-de-idiomas/:id', component: BolsaDeIdiomasComponent},
            {path: 'bolsa-de-idiomas', component: BolsaDeIdiomasComponent},
            {path: 'log-idioma/:id', component: LogIdiomaComponent},
            {path: 'bolsa-de-idiomas-admin/:id', component: BolsaDeIdiomasComponent},
            // {path: 'detalhe-da-bolsa', component: DetalheDaBolsaComponent},
            {path: 'detalhe-bolsa-de-estudos/:id', component: DetalheBolsaDeEstudosComponent},
            {path: 'detalhe-bolsa-de-idiomas/:id', component: DetalheBolsaDeIdiomaComponent},
            {path: 'detalhe-reembolso/:id', component: DetalheReembolsoComponent},
            {path: 'log-reembolso/:id', component: LogReembolsoComponent},
            {path: 'detalhe-treinamento/:id', component: DetalheTreinamentoComponent},
            {path: 'log-treinamento/:id', component: LogTreinamentoComponent},
            {path: 'reembolsos/:id1', component: ReembolsosComponent},
            {path: 'reembolsos/:id1/:id2', component: ReembolsosComponent},
            {path: 'reembolsos', component: ReembolsosComponent},
            {path: 'reembolsos-admin/:id1', component: ReembolsosComponent},
            {path: 'reembolsos-idiomas/:id1', component: ReembolsosIdiomasComponent},
            {path: 'reembolsos-idiomas/:id1/:id2', component: ReembolsosIdiomasComponent},
            {path: 'reembolsos-idiomas', component: ReembolsosIdiomasComponent},
            {path: 'reembolsos-idiomas-admin/:id', component: ReembolsosIdiomasComponent},
            {path: 'user', component: UserComponent},
            {path: 'config-politic', component: ConfigPoliticComponent},
            {path: 'config-salary', component: ConfigSalaryComponent},
        ],
        // canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        relativeLinkResolution: 'legacy',
        scrollOffset: [0, 0],
        scrollPositionRestoration: 'top'
    })],
    exports: [RouterModule]
})
// @ts-ignore
export class AppRoutingModule {
}
