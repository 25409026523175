import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router, ActivatedRoute} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from "../../service/user.service";
import {
    FindRefundCeilingGQL,
    RefundCeiling,
    UpdateRefundCeilingGQL, UpdateRefundCeilingInput,
    UpdateRefundInput,
    User
} from "../../../generated/graphql";
import {Subscription} from "rxjs";
import {StorageService} from "../../service/user-simulation.service";

declare var $: any;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    user: User;
    navBarShort = false;

    refundCeiling: RefundCeiling = new RefundCeiling();

    refundCeilingValue: number;

    simulatedUser: {id: string, name: string, profile: string} = null;
    simulatedUserSubscription!: Subscription;
    SIMULATED_USER_KEY = 'SIMULATED_USER';


    constructor(public router: Router,
                public router2: ActivatedRoute,
                public translate: TranslateService,
                private storageService: StorageService,
                private findRefundCeilingGQL: FindRefundCeilingGQL,
                private updateRefundCeilingGQL: UpdateRefundCeilingGQL,
                public userService: UserService) {

        super(router, translate);
    }

    ngOnInit(): void {
        this.handlerCollapse();
        this.userService.user.subscribe({
            next: data => {
                this.user = data;
            }, error: err => super.onError(err)
        });

        this.simulatedUserSubscription = this.storageService.simulatedUser$.subscribe(user => {
            this.simulatedUser = user;
            // this.getSimulatedUser();
        });
    }

    ngOnDestroy() {
        this.simulatedUser = null;
        if (this.simulatedUserSubscription) {
            this.simulatedUserSubscription.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        this.navActive();
    }

    handlerCollapse() {
        $('.navbar-nav .nav-item').click(function () {
            $('.navbar-nav .nav-item').removeClass('active');
            $(this).addClass('active');
        });
        $('.navbar-nav .nav-link').click(function () {
            $('.navbar-nav .nav-link').removeClass('text-white');
            $(this).addClass('text-white');
        });
    }

    actionCloseCollapse() {
        this.actionSideBar();
        $('body').removeClass('nav-open');
        $('.hamburger').removeClass('open');
    }

    navActive() {
        if (this.router.url === '/calibration' || this.router.url === '/engines') {
            return this.handlerNavActive('.report-ts');
        }
        if (this.router.url === '/training' || this.router.url === '/faq') {
            return this.handlerNavActive('.help-ts');
        }
    }

    handlerNavActive(el) {
        $('.navbar-nav .nav-item').removeClass('active');
        $('.navbar-nav .nav-link').removeClass('text-white');
        $(el).addClass('active');
        $(el + ' .nav-link').addClass('text-white');

    }

    actionSideBar() {
        this.navBarShort = !this.navBarShort;
    }

    handleOpenModalRefund() {
        $('#refund').modal();

        this.findRefundCeilingGQL.watch()
            .valueChanges
            .subscribe(({data}) => {
                this.refundCeiling = data.findRefundCeiling;

                this.refundCeilingValue = this.refundCeiling.value;
            });
    }

    onChangeRefundCeiling() {
        const updateRefundCeiling: UpdateRefundCeilingInput = {
            id: this.refundCeiling.id,
            value: this.refundCeilingValue
        };

        this.updateRefundCeilingGQL
            .mutate({input: updateRefundCeiling})
            .subscribe(() => {
                this.showMessage(this.translate.instant('sucesso'),
                    this.translate.instant('tetoreembolsoalterado'), 'success');
                this.closeModal('refund');
            });
    }

    // getSimulatedUser() {
    //     this.simulatedUser = this.storageService.getSimulatedUser();
    //     console.log('SIMULATED USER NO GET DO SIDEBAR', this.simulatedUser);
    // }
}
