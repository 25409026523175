<div class="page-header">
    <div class="container justify-content-center">
        <a [routerLink]="['/lista-de-solicitacoes']" class="page-header__back">
            <svg-icon src="assets/svg/back.svg"></svg-icon>
        </a>
        <h2 class="page-header__title"><strong>{{ 'solicitacao.logsolicitacaotitle' | translate }}</strong></h2>
        <ng-container *ngIf="user?.profile === 'admin' && solicitationLog?.status === 'approved'">
            <div class="page-header__btn">
                <button class="btn btn-primary btn-fixed btn-lg mb-md-3 mr-md-4 fs-16"
                        [routerLink]="'/bolsa-de-estudos-admin/' + solicitationLog.id">Editar formulário
                </button>
            </div>
        </ng-container>
    </div>
</div>

<div class="reason-rejected" *ngIf="solicitationLog?.status === 'rejected'">
    <div class="accordion" id="accordionExample" style="width: 100%">
        <div style="background: #D81212;">
            <button class="btn btn-block text-left review-button" type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div class="d-flex justify-content-between" style="align-items: self-end">
                    <h4 class="text-primary font-weight-bolder">
                        <strong>{{ 'reprovadopor'|translate }} {{ getNameRejected() }}</strong>
                    </h4>
                    <h4 class="text-primary font-weight-bolder"><strong>{{ 'motivoreprova'|translate }}</strong></h4>
                    <svg-icon src="assets/svg/expand.svg"></svg-icon>
                </div>
            </button>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                 data-parent="#accordionExample"
                 style="background: #FFA500;">
                <div class="card-body pt-0 pb-2" style="background: #D81212;">
                    <span class="text-primary" style="font-size: 18px"> {{ solicitationLog.response }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="page-content">
    <div class="container">
        <div class="page-content__status">
            <!-- VARIAÇÃO DE STATUS: approving, rejected e approved -->
            <div class="infos no-border" [ngClass]="solicitationLog?.status">
                <span class="number">
                    <span class="page-header__number mt-1">Nº <strong>{{ solicitationLog?.code }}</strong></span>
                </span>
                <span class="ml-3 text flex-column align-items-start tag tag-secondary tag-left color">
                    <span class="head small">Status</span>
                    <strong>{{ getStatusName(solicitationLog?.status) }}</strong>
                </span>
                <span class="text flex-column align-items-start mr-lg-auto">
                    <span class="head small">{{ 'atualizado' | translate }}</span>
                    <span class="color">{{ solicitationLog?.updatedAt | date:'dd/MM/yyyy':'UTC+0' }}</span>
                </span>
                <span class="text flex-column align-items-start mr-0">
                    <span class="head small">{{ 'novocadastro.criado' | translate }}</span>
                    <span class="text-primary">{{ solicitationLog?.createdAt | date:'dd/MM/yyyy':'UTC+0' }}</span>
                </span>
            </div>
            <div class="custom-nav full mt-3">
                <!-- VARIAÇÃO DE STATUS: active, error e done -->
                <ul class="nav nav-tabs custom-nav__menu">
                    <li class="nav-item" *ngFor="let s of solicitationLog?.solicitationStep;let i = index">
                        <span class="nav-link" [ngClass]="{'active': i === solicitationLog?.step, 'done': s.status === 'approved', 'error': s.status === 'rejected'}">
                            <div class="bubble"></div>
                            <span class="tooltip-label label is-cursor"
                                  (mouseenter)="onShowTooltip(i, true)"
                                  (mouseleave)="onShowTooltip(i, false)">
                                {{getProfileNameByRole(s.role)}}</span>
                            <span class="responsible-name"*ngIf="showTooltip[i]">{{s.responsibleName}}</span>
                        </span>

                    </li>
                </ul>
            </div>
        </div>

        <ng-container *ngFor="let c of solicitationLog?.colaborators">
            <article class="page-content__details box mb-md-4"
                     *ngIf="solicitationLog?.colaborators.length === 1 && c.isRequester">
                <div class="page-content__details__tab">{{ 'treinamento.requester'| translate }}</div>
                <div class="row">
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{ 'nome' | translate }}</h4>
                        <p class="text">{{ c.name }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3" *ngIf="c.dateOfBirth">
                        <h4 class="title">{{ 'bolsa.nascimento' | translate }}</h4>
                        <p class="text">{{ c.dateOfBirth | date:'dd/MM/yyyy':'UTC+0' }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-2">
                        <h4 class="title">RE</h4>
                        <p class="text">{{ c.re }}</p>
                    </div>
                </div>
                <ng-container *ngIf="false">
                    <hr>
                    <div class="row">
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{ 'corpele'|translate }}</h4>
                            <p class="text">{{ c.skinColor?.name }}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{ 'generosex'|translate }}</h4>
                            <p class="text">{{ c.genderIdentity?.name }}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{ 'orientsex'|translate }}</h4>
                            <p class="text">{{ c.sexualOrientation?.name }}</p>
                        </div>
                    </div>
                </ng-container>
                <hr>
                <div class="row">
                    <div class="col-6 col-sm-4 col-lg-2">
                        <h4 class="title">{{ 'unidade' | translate }}</h4>
                        <p class="text">{{ c.unity.name }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3 mt-sm-3 mt-lg-0">
                        <h4 class="title">{{ 'departamento/setor' | translate }}</h4>
                        <p class="text">{{ c.areaManager }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-2 mt-sm-3 mt-lg-0">
                        <h4 class="title">{{ 'ramal' | translate }}</h4>
                        <p class="text">{{ c.ramal }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{ 'cargo' | translate }}</h4>
                        <p class="text">{{ c.currentJob }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{ 'bolsa.faixa' | translate }}</h4>
                        <p class="text">{{ c.salaryRange }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <ng-container *ngIf="user.profile === 'admin' && c.lessThanOneYear">
                            <div class="d-flex">
                                <h4 class="title mr-2">{{ 'bolsa.admissao' | translate }}</h4>
                                <span class="text-primary text-size-small tooltip-help">
                                <svg class="help-tooltip" xmlns="http://www.w3.org/2000/svg" width="15" height="15"
                                     viewBox="0 0 24 24">
                                    <path
                                            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"
                                            title="Popover title" data-content="O titulo, ou nome, do seu produto."/>
                                 </svg>
                                <span class="tooltip-help-box">
                                  {{ 'admissaoMenorQue' | translate }}
                                </span>
                            </span>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="!c.lessThanOneYear">
                            <h4 class="title">{{ 'bolsa.admissao' | translate }}</h4>
                        </ng-container>
                        <p class="text">{{ c.admissionDate | date:'dd/MM/yyyy':'UTC+0' }}</p>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-3">
                        <h4 class="title">{{ 'bolsa.centro' | translate }}</h4>
                        <p class="text">{{ c.costCenter }}</p>
                    </div>
                    <div class="col-6 col-sm-8 col-lg-3">
                        <h4 class="title">{{ 'bolsa.gerente' | translate }}</h4>
                        <p class="text">{{ c.directorArea }}</p>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-12">
                        <h4 class="title">{{ 'bolsa.resumo' | translate }}</h4>
                        <p class="text text-break-word">{{ c.activities }}</p>
                    </div>
                </div>
            </article>
        </ng-container>

        <article class="page-content__details box mb-md-4" *ngIf="solicitationLog?.colaborators?.length > 1">
            <div class="page-content__details__tab">{{ 'treinamento.collaborator'| translate }}</div>
            <div class="row">
                <div class="col-12">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th scope="col">{{ 'treinamento.name' | translate }}</th>
                            <th scope="col">{{ 'treinamento.re' | translate }}</th>
                            <th scope="col">{{ 'bolsa.nascimento' | translate }}</th>
                            <th scope="col">{{ 'treinamento.unity' | translate }}</th>
                            <th scope="col">{{ 'treinamento.departmentSector' | translate }}</th>
                            <th scope="col">{{ 'bolsa.admissao' | translate }}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let c of solicitationLog?.colaborators">
                            <ng-container *ngIf="!c.isRequester">
                                <tr *ngIf="solicitationLog?.step === 0 || (solicitationLog?.step > 0 && c.status !== 'rejected')">
                                    <th scope="row">{{ c.name }}</th>
                                    <td>{{ c.re }}</td>
                                    <td>{{ c.dateOfBirth | date: 'dd/MM/yyyy':'UTC+0' }}</td>
                                    <td>{{ c.unity.name }}</td>
                                    <td>{{ c.directorArea }}</td>
                                    <ng-container *ngIf="user.profile === 'admin' && c.lessThanOneYear">
                                        <td class="d-flex align-items-center" style="gap: 10px">
                                            {{ c.admissionDate | date: 'dd/MM/yyyy':'UTC+0' }}
                                            <span class="text-primary text-size-small tooltip-help">
                                                <svg class="help-tooltip" xmlns="http://www.w3.org/2000/svg" width="15"
                                                     height="15" viewBox="0 0 24 24">
                                                    <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1.25 17c0 .69-.559 1.25-1.25 1.25-.689 0-1.25-.56-1.25-1.25s.561-1.25 1.25-1.25c.691 0 1.25.56 1.25 1.25zm1.393-9.998c-.608-.616-1.515-.955-2.551-.955-2.18 0-3.59 1.55-3.59 3.95h2.011c0-1.486.829-2.013 1.538-2.013.634 0 1.307.421 1.364 1.226.062.847-.39 1.277-.962 1.821-1.412 1.343-1.438 1.993-1.432 3.468h2.005c-.013-.664.03-1.203.935-2.178.677-.73 1.519-1.638 1.536-3.022.011-.924-.284-1.719-.854-2.297z"
                                                          title="Popover title"
                                                          data-content="O titulo, ou nome, do seu produto."/>
                                                 </svg>
                                                <span class="tooltip-help-box">
                                                  {{ 'admissaoMenorQue' | translate }}
                                                </span>
                                            </span>
                                        </td>
                                    </ng-container>
                                    <ng-container *ngIf="user.profile !== 'admin' && !c.lessThanOneYear">
                                        <td>{{ c.admissionDate | date: 'dd/MM/yyyy':'UTC+0' }}</td>
                                    </ng-container>
                                </tr>
                            </ng-container>
                        </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
        </article>


        <article class="page-content__details box mb-md-4">
            <div class="page-content__details__tab">{{ 'bolsa.curso' | translate }}</div>
            <div class="row">
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'typeOfCourse' | translate }}</h4>
                    <p class="text">{{ getTranslate(solicitationLog?.course?.type) }}</p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'solicitacao.coursename' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.course?.name }}</p>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'bolsa.anocurso' | translate }}</h4>
                    <p class="text">{{ yearNow - solicitationLog?.course?.attendingYear }}</p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'bolsa.duracurso' | translate }}</h4>
                    <p class="text">
                        <span class="mr-1"
                              *ngIf="solicitationLog?.course?.durationYear > 0">{{ solicitationLog?.course?.durationYear }} {{ 'bolsa.anos' | translate }}</span>
                        <span *ngIf="solicitationLog?.course?.durationMonths > 0">{{ solicitationLog?.course?.durationMonths }} {{ 'bolsa.meses' | translate }}</span>
                    </p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'bolsa.datamatricula' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.course?.registrationDate | date:'dd/MM/yyyy':'UTC+0' }}</p>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{ 'bolsa.datainicio' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.course?.start | date:'dd/MM/yyyy':'UTC+0' }}</p>
                </div>
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{ 'bolsa.datatermino' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.course?.end | date:'dd/MM/yyyy':'UTC+0' }}</p>
                </div>
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{ 'bolsa.numberOfInstallments' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.course?.numberOfInstallments }}</p>
                </div>
                <div class="col-6 col-sm-3">
                    <h4 class="title">{{ 'bolsa.valor' | translate }}</h4>
                    <strong class="text">{{ solicitationLog?.course?.value | currency:getSymbolCurrency(solicitationLog?.course?.typeOfCurrency) }}</strong>
                </div>
            </div>
        </article>

        <article class="page-content__details box mb-md-4">
            <div class="page-content__details__tab">{{ 'bolsa.instituicao' | translate }}</div>
            <div class="row pt-md-4">
                <div class="col-6 col-sm-6">
                    <h4 class="title">{{ 'bolsa.instituicaonome' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.institution?.name }}</p>
                </div>
                <div class="col-6 col-sm-6">
                    <ng-container *ngIf="this.documentType === 'CNPJ'">
                        <h4 class="title">CNPJ</h4>
                    </ng-container>
                    <ng-container *ngIf="this.documentType === 'CPF'">
                        <h4 class="title">CPF</h4>
                    </ng-container>
                    <p class="text">{{ solicitationLog?.institution?.cnpj_cpf }}</p>
                </div>
            </div>
        </article>

        <article class="page-content__details box mb-md-4" *ngIf="!isNullOrUndefined(solicitationLog?.receipts)">
            <div class="page-content__details__tab">{{ 'bolsa.comprovantes' | translate }}</div>
            <div class="row justify-content-center pt-3">
                <div class="col-12 col-md-4 text-center mr-3" *ngFor="let r of solicitationLog?.receipts">
                    <h4 class="title large">{{ getReceiptName(r.type) }}</h4>
                    <a [href]="r.url" target="_blank" class="attachment d-flex flex-column align-items-center">
                        <img class="attach" *ngIf="r.extension !== 'pdf'" src="{{r.url}}" alt="receipts"/>
                        <!--                        <svg-icon [svgStyle]="{'width':'30px','height':'30px'}" src="assets/svg/icon-metro-file-pdf.svg"></svg-icon>-->
                        <svg-icon [svgStyle]="{'width':'30px','height':'30px'}" [src]="svgType(r.extension)"></svg-icon>
                        <p>{{ r.name }}.{{ r.extension }}</p>
                    </a>
                </div>
                <div class="flex-column d-flex justify-content-center gap-20">
                    <ng-container *ngIf="solicitationLog.isAcceptPolicy">
                        <p class="text">Declaro estar ciente com a Política Bolsa de Estudos</p>
                    </ng-container>
                    <ng-container *ngIf="solicitationLog.isAcceptTerms">
                        <p class="text">Declaro estar ciente com o Termo de Ciência</p>
                    </ng-container>
                </div>
            </div>
        </article>

        <article class="page-content__details box mb-md-4 d-flex"
                 *ngIf="!this.isNullOrUndefined(solicitationLog?.signature)">
            <div class="page-content__details__tab">{{ 'signature'|translate }}</div>
            <img class="signature" src="{{solicitationLog?.signature}}" alt="signature"/>
        </article>

        <article class="page-content__details box mb-md-4"
                 *ngIf="!isNullOrUndefined(solicitationLog?.receiptJustification)">
            <div class="page-content__details__tab">{{ 'justificativa' | translate }}</div>
            <div class="row pt-md-4">
                <div class="col-12 mb-4">
                    <p class="text">{{ solicitationLog?.receiptJustification }}</p>
                </div>
            </div>
        </article>

        <article class="page-content__details box mb-md-4"
                 *ngIf="!isNullOrUndefined(solicitationLog?.courseName || solicitationLog?.courseStatus || solicitationLog?.startDate)">
            <div class="page-content__details__tab">{{ 'bolsaestudos'|translate }}</div>
            <div class="row pt-md-4">
                <div class="col-6 col-sm-4 col-lg-3">
                    <h4 class="title">{{ 'nomecurso'|translate }}</h4>
                    <p class="text">{{ solicitationLog.courseName }}</p>
                </div>

                <div class="col-6 col-sm-4 col-lg-3" *ngIf="!isNullOrUndefined(solicitationLog?.startDate)">
                    <h4 class="title">{{ 'datainicio'|translate }}</h4>
                    <p class="text">{{ solicitationLog.startDate | date : 'dd/MM/yyyy' }}</p>
                </div>

                <div class="col-6 col-sm-4 col-lg-3" *ngIf="!isNullOrUndefined(solicitationLog?.courseStatus)">
                    <h4 class="title">Status</h4>
                    <p class="text">{{ solicitationLog.courseStatus === 'finished' ? 'Curso Completo' : 'Curso Incompleto' }}</p>
                </div>
            </div>
        </article>

        <ng-container *ngIf="user.profile === 'admin' && !isNullOrUndefined(solicitationLog?.adminObservation)">
            <article class="page-content__details box mb-md-4">
                <div class="page-content__details__tab">{{ 'observacao' | translate }}</div>
                <div class="row pt-md-4">
                    <div class="col-6 col-sm-6">
                        <h4 class="title">{{ 'observacao' | translate }}</h4>
                        <p class="text">{{ solicitationLog?.adminObservation }}</p>
                    </div>
                </div>
            </article>
        </ng-container>

        <div class="d-flex align-items-center mb-4 w-100 flex-wrap"
             [ngClass]="{'justify-content-end' : user?.profile !== 'admin', 'justify-content-between' : user?.profile === 'admin'}">

            <div class="page-content__buttons mb-0 justify-content-center">
                <ng-container *ngIf="user?.profile === 'admin'">
<!--                    <button class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 fs-18"-->
<!--                            (click)="returnAction(solicitationLog.id)">{{ 'novocadastro2.retornar' | translate }}-->
<!--                    </button>-->
                    <ng-container *ngIf="isNullOrUndefined(solicitationLog?.adminObservation)">
                        <button class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 mr-md-0 fs-18"
                                (click)="showModal('observations')">
                            Adicionar Observação
                        </button>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</section>