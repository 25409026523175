import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {
    Colaborator,
    CreateRefundGQL,
    CreateRefundInput,
    GetLastLanguageGQL,
    GetLastLanguageQuery,
    GetSolicitationGQL,
    GetSolicitationQuery,
    Solicitation, UpdateRefundAdminGQL, UpdateRefundAdminInput,
    UpdateRefundGQL,
    UpdateRefundInput, User
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import * as moment from 'moment/moment';
import {PaintScreen} from '../paint-screen/model/pain-screen';

declare var $: any;

@Component({
    selector: 'app-reembolsos-idiomas',
    templateUrl: './reembolsos-idiomas.component.html',
    styleUrls: ['./reembolsos-idiomas.component.scss']
})
export class ReembolsosIdiomasComponent extends BaseComponent implements OnInit {

    controlFlagPaint = false;
    paintScreen: PaintScreen = new PaintScreen();
    modelSolicitation: Solicitation = new Solicitation(); // CreateSo
    getLastLanguageQuery: QueryRef<GetLastLanguageQuery>;
    solicitationQuery: QueryRef<GetSolicitationQuery>;


    createRefundInput: CreateRefundInput = {
        dateRefund: moment().format(),
        solicitacionId: '',
        colaboratorId: '',
        value: 0
    };
    updateRefundInput: UpdateRefundInput = {dateRefund: moment().format(), id: '', value: 0};

    updateRefundAdminInput: UpdateRefundAdminInput = {dateRefund: moment().format(), id: '', value: 0};

    pathImageObj = {
        primary: {name: '', path: ''},
        secondary: {name: '', path: ''},
        tertiary: {name: '', path: ''}
    };
    file: File = null;
    listFiles = [];
    isUpload = false;
    colaborators: Colaborator = new Colaborator();
    limitDay: number;

    urlForAdminEdit: boolean;

    user: User = new User();

    constructor(public router: Router,
                public router2: ActivatedRoute,
                public translate: TranslateService,
                public userService: UserService,
                private getLastLanguageGQL: GetLastLanguageGQL,
                private createRefundGQL: CreateRefundGQL,
                private updateRefundGQL: UpdateRefundGQL,
                private updateRefundAdminGQL: UpdateRefundAdminGQL,
                private getSolicitationGQL: GetSolicitationGQL) {
        super(router, translate);
    }


    ngOnInit(): void {
        this.limitDay = Number(moment().startOf('day').utcOffset(0, true).format('DD'));

        this.router2.params.subscribe(url => {

            console.log('URL', url);
            console.log('URL ID', url.id1);

                if (!this.isNullOrUndefined(url) && !this.isNullOrUndefined(url.id1)) {
                    console.log('ID VINDO', url.id1);
                    this.solicitationQuery = this.getSolicitationGQL.watch({id: url.id1});
                    this.solicitationQuery.valueChanges.subscribe(({data}) => {
                            this.modelSolicitation = data.getSolicitation as Solicitation;

                            console.log('SOLICITATION', this.modelSolicitation);

                            this.urlForAdminEdit = this.router.url.includes('reembolsos-admin');

                            this.userService.user.subscribe({
                                next: data => {
                                    this.user = data;
                                }, error: err => super.onError(err)
                            });

                            if (this.modelSolicitation.type === 'refund') {

                                console.log('IS REFUND');
                                this.modelSolicitation.institution = this.modelSolicitation.father.institution;
                                this.modelSolicitation.colaborators = this.modelSolicitation.father.colaborators;
                                this.modelSolicitation.course = this.modelSolicitation.father.course;
                                this.modelSolicitation.course.value = this.modelSolicitation.value;

                                this.pathImageObj.primary = {
                                    name: this.modelSolicitation.receipts[0].name,
                                    path: this.modelSolicitation.receipts[0].url
                                };

                                this.pathImageObj.secondary = {
                                    name: this.modelSolicitation.receipts[1].name,
                                    path: this.modelSolicitation.receipts[1].url
                                };
                            }

                            if (!this.isNullOrUndefined(url.id2)) {
                                if (this.modelSolicitation.colaborators.length > 1) {
                                    this.modelSolicitation.colaborators = this.modelSolicitation.colaborators.filter((e) =>
                                        e.re === url.id2
                                    );
                                }
                            }

                            this.colaborators = this.modelSolicitation.colaborators[0] ?? new Colaborator();
                        }, (error) => {
                            this.router.navigate(['/solicitacoes']);
                        }
                    );
                } else {
                    this.getLastLanguageQuery = this.getLastLanguageGQL.watch();
                    this.getLastLanguageQuery.valueChanges.subscribe(
                        ({data}) => {
                            this.modelSolicitation = data?.getLastLanguage as Solicitation;
                        }, (errors) => {
                            this.router.navigate(['/solicitacoes']);
                        }
                    );
                }

            }
        );

    }

    handlerUploadSingle(event: Event, type, element) {
        const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
        const allowedExtensions = ['png', 'jpg', 'jpeg', 'pdf'];

        const input = event.target as HTMLInputElement;

        if (!input.files || !input.files[0]) {
            return;
        }

        const file = input.files[0];

        if (file.size > 50000000) {
            super.showMessage('Atenção', 'O arquivo não deve ultrapassar o tamanho de 50MB', 'info');
            return;
        }

        console.log('FILE NAME', file.name);
        const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';

        if (!allowedTypes.includes(file.type) || !allowedExtensions.includes(fileExtension)) {
            super.showMessage('Atenção', 'Formato de arquivo inválido. Permitidos: PNG, JPG, JPEG, PDF', 'error');
            return;
        }

        const reader: FileReader = new FileReader();
        reader.onload = (files) => {
            // this.pathImage = file.target.result;
            console.log('FILES NO ONLOAD - ', files);
            console.log('LOG NO ONLOAD - ', this.pathImageObj);
            this.pathImageObj[element].path = files.target.result;
            this.pathImageObj[element].name = file.name;


            this.listFiles = this.listFiles.filter((item) => item.type !== type);
            this.listFiles.push({file, path: files.target.result, type});
        };
        reader.readAsDataURL(file);
    }

    actionUploadImage(id: string, router = '/solicitacoes') {
        this.userService.upload(id, 'signature', this.paintScreen.file, 'signature').subscribe((data1) => {
            if (this.isNullOrUndefined(this.listFiles)) {
                this.handlerFinish(router);
            } else {
                this.handlerUploadList(id).then(() => this.handlerFinish(router));
            }
        }, error => super.onError(error));
    }

    async handlerUploadList(id): Promise<void> {

        console.log('LIST FILES', this.listFiles);
        for (const file of this.listFiles) {
            try {
                await this.userService.upload(id, file.type, file.file).toPromise();
                console.log(`Upload do arquivo ${file.name} concluído.`);
            } catch (error) {
                console.error(`Erro ao fazer upload do arquivo ${file.name}:`, error);
            }
        }
    }

    handlerFinish(router: string) {
        this.isUpload = true;
        if (this.urlForAdminEdit) {
            $('#successEditAdmin').modal('show');
        } else {
            $('#success').modal('show');
        }
        // this.dataSolicitation = new CreateSolicitation();
        this.toggleLoaderCustom(false);
        this.router.navigate([router]);
    }

    onSaveRefund() {

        if (this.limitDay > 15 && this.user.profile !== 'admin' && this.getEnv().handlerRequest) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), 'Seu reembolso não foi aceito! O prazo de reembolso é até dia 15 de cada mês, podendo sofrer alteração em casos de feriados ou finais de semana. Caso de dúvidas consulte a política vigente.', 'warning');
            return;
        }
        if (!this.urlForAdminEdit) {
            if (this.isNullOrUndefined(this.paintScreen.file)) {
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), 'Para continuar, é necessária uma assinatura.', 'warning');
                return;
            }
        }

        if (this.isNullOrUndefined(this.createRefundInput.dateRefund) || this.isNullOrUndefined(this.modelSolicitation.course.value)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningRequiredFields'), 'warning');
            return;
        }
        let numberEmpty = 0;
        const arrValidateFields = [
            {value: this.pathImageObj.primary.name},
            {value: this.pathImageObj.secondary.name}
        ];

        console.log('PATH TO IMAGE', this.pathImageObj);

        console.log('ARR VALIDATE FIELDS', arrValidateFields);

        arrValidateFields.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                numberEmpty++;
            }
        });
        if (numberEmpty > 0) {
            console.log('NUMBER EMPTY', numberEmpty);
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgUploadFile'), 'warning');
            return;
        } else {
            if (this.urlForAdminEdit) {
                this.onUpdateRefundEditAdmin();
            } else {
                this.toggleLoaderCustom(true);
                if (this.modelSolicitation.type === 'refund') {
                    this.onUpdateRefund();
                } else {
                    this.onCreateRefund();
                }
            }
        }
    }

    onUpdateRefund() {
        this.updateRefundInput.id = this.modelSolicitation.id;
        this.updateRefundInput.dateRefund = this.createRefundInput.dateRefund;
        this.updateRefundInput.value = this.modelSolicitation.course.value;
        this.updateRefundGQL.mutate({data: this.updateRefundInput}).subscribe(
            ({data}) => {
                const a: any = data.updateRefund as Solicitation;
                this.actionUploadImage(a.id, '/lista-de-solicitacoes');
            }, (err) => super.onError(err)
        );
    }

    onCreateRefund() {
        this.createRefundInput.solicitacionId = this.modelSolicitation.id;
        this.createRefundInput.colaboratorId = this.colaborators.id;
        this.createRefundInput.value = this.modelSolicitation.course.value;
        this.createRefundGQL.mutate({data: this.createRefundInput}).subscribe(
            ({data}) => {
                const a: any = data.createRefund as Solicitation;
                this.actionUploadImage(a.id);
            }, (err) => super.onError(err)
        );
    }

    onUpdateRefundEditAdmin() {

        console.log('ON UPDATE REFUND');
        this.updateRefundAdminInput.id = this.modelSolicitation.id;
        this.updateRefundAdminInput.dateRefund = this.createRefundInput.dateRefund;
        this.updateRefundAdminInput.value = this.modelSolicitation.course.value;

        console.log('UPDATE REFUND INPUT', this.updateRefundInput);

        this.updateRefundAdminGQL.mutate({data: this.updateRefundAdminInput}).subscribe(
            ({data}) => {
                const a: any = data.updateRefundAdmin as Solicitation;
                this.handlerUploadList(a.id).then(() => this.handlerFinish('/lista-de-solicitacoes'));
            }, (err) => super.onError(err)
        );
    }

    removeFile(type: string, element: string) {
        this.pathImageObj[element].name = '';
        this.pathImageObj[element].path = '';
        for (let i = 0; i < this.listFiles.length; i++) {
            if (this.listFiles[i].type === type) {
                this.listFiles.splice(i, 1);
                i--;
            }
        }
    }

    onClearColaboratorsDate(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.createRefundInput.dateRefund = '';
            } else {
                this.createRefundInput.dateRefund = moment(a, 'DD/MM/yyyy').format();
            }
        });
    }

    selectColaborators(c: Colaborator) {
        this.pathImageObj = {
            primary: {name: '', path: ''},
            secondary: {name: '', path: ''},
            tertiary: {name: '', path: ''}
        };
        this.colaborators = c;

        console.log('SOLICITATION', this.modelSolicitation);
    }
}
