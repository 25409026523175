<div class="page-header">
    <div class="container justify-content-between">
        <a [routerLink]="['/solicitacoes']" class="page-header__back">
            <svg-icon src="assets/svg/back.svg"></svg-icon>
        </a>
        <div class="page-header__number">Nº <strong>{{modelSolicitation?.code}}</strong></div>
        <h2 class="page-header__title mx-auto">{{'reembolso.titulo1'|translate}}
            <strong>{{'reembolso.titulo2'|translate}}</strong></h2>
    </div>
</div>

<div class="page-content">
    <div class="container">
        <div class="d-flex justify-content-center" style="gap: 20px">
            <div (click)="selectColaborators(c)" class="d-flex flex-column align-items-center is-cursor"
                 *ngFor="let c of modelSolicitation.colaborators">
                <img src="assets/images/avatar.svg" alt="">
                <p class="mb-0">{{c.name}}</p>
            </div>
        </div>

        <div class="reason-review mx-0 w-100 mt-5" *ngIf="modelSolicitation.status == 'review'">
            <div class="accordion" id="accordionExample" style="width: 100%">
                <div style="background: #FFA500;">
                    <button class="btn btn-block text-left review-button" type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                        <div class="d-flex justify-content-between" style="align-items: self-end">
                            <h4 class="text-primary font-weight-bolder"><strong>{{'motivorevisao'|translate}}</strong>
                            </h4>
                            <svg-icon src="assets/svg/expand.svg"></svg-icon>
                        </div>
                    </button>
                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                         data-parent="#accordionExample"
                         style="background: #FFA500;">
                        <div class="card-body pt-0 pb-2" style="background: #FFA500;">
                            <span class="text-primary" style="font-size: 18px"> {{modelSolicitation.response}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!isNullOrUndefined(colaborators.id)">

            <div class="page-content__details mb-md-4">
                <div class="row mb-2 mb-md-4">
                    <div class="col-12 col-sm-12 col-md-6 d-flex">
                        <img class="mr-3" src="assets/images/avatar.svg" alt="">
                        <div>
                            <h4 class="title">{{'NomeColaborador'|translate}}</h4>
                            <p class="text">{{colaborators.name}}</p>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <h4 class="title">{{'unidade'|translate}}</h4>
                        <p class="text">{{colaborators.unity.name}}</p>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <h4 class="title">{{'dateMtr'|translate}}</h4>
                        <p class="text">{{modelSolicitation.createdAt | date: 'fullDate'}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6">
                        <h4 class="title">{{'departamento/setor'|translate}}</h4>
                        <p class="text">{{colaborators.areaManager}}</p>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <h4 class="title">{{'area'|translate}}</h4>
                        <p class="text">{{colaborators.areaManager}}</p>
                    </div>
                    <div class="col-12 col-sm-6 col-md-3">
                        <h4 class="title">{{'responsible'|translate}}</h4>
                        <strong class="text">{{colaborators.directorArea}}</strong>
                    </div>
                </div>
            </div>

            <div class="page-content__details">
                <div class="row">
                    <div class="col-12 col-sm-6 col-md-6">
                        <h4 class="title">{{'curso'|translate}}</h4>
                        <p class="text">{{modelSolicitation.course.name}}</p>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <h4 class="title">{{'bolsa.instituicaonome'|translate}}</h4>
                        <p class="text">{{modelSolicitation.institution.name}}</p>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-12 col-sm-6 col-md-6">
                        <div class="input-material" style="width:fit-content;margin-left: initial;">
                            <input class="form-control" type="text" required maxlength="250" name="dateRefund"
                                   [clearIfNotMatch]="true"
                                   (blur)="onClearColaboratorsDate($event, 'dateRefund')"
                                   [mask]="'d0/M0/0000'" [leadZeroDateTime]="true"
                                   [dropSpecialCharacters]="false"
                                   [ngModel]="createRefundInput.dateRefund | date: 'dd/MM/yyyy'"/>
                            <label>Data*</label>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-md-6">
                        <div class="input-material" style="width:fit-content;margin-left: initial;">
                            <input class="form-control" type="text" required maxlength="250" currencyMask
                                   name="valueCourse"
                                   [(ngModel)]="modelSolicitation.course.value"/>
                            <label>{{'valorpago' | translate}}*</label>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="!urlForAdminEdit">
                <div class="page-content__upload signature" style="margin:35px 0">
                    <div class="row align-items-center">
                        <div class="col-9">
                            <img *ngIf="!this.isNullOrUndefined(paintScreen.preview)"
                                 src="{{paintScreen!.preview}}" alt="screen">
                        </div>
                        <div class="col-3">
                            <button class="btn btn-primary" (click)="controlFlagPaint = !controlFlagPaint">
                                {{'openSignature'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <form action="#" class="page-content__form justify-content-center full">
                <div class="page-content__upload">
                    <svg-icon class="icon svg-icon" src="assets/svg/check.svg"
                              [ngClass]="{'svg-icon-success': !isNullOrUndefined(pathImageObj['primary'].path)}"></svg-icon>
                    <p>{{'payment-receipt'|translate}}</p>
                    <p>{{'file-types-img' | translate}}</p>
                    <label for="doc-1" class="btn btn-primary  page-content__upload__input mb-0">
                        <svg-icon src="assets/svg/attachment.svg"></svg-icon>
                        <span>Anexar</span>
                        <input type="file" id="doc-1" name="doc-1" required
                               onclick="this.value = null"
                               (change)="handlerUploadSingle($event, 'payment-receipt', 'primary')"
                               accept=".image/, .pdf, .png, .jpg, .jpeg"
                        />
                    </label>
                    <div *ngIf="!isNullOrUndefined(pathImageObj['primary'].path)">
                        {{pathImageObj['primary'].name}}
                        <svg-icon (click)="removeFile('payment-receipt', 'primary')" class="icon svg-icon"
                                  src="assets/svg/trash.svg">
                        </svg-icon>
                    </div>
                </div>
                <div class="page-content__upload">
                    <svg-icon class="icon svg-icon" src="assets/svg/check.svg"
                              [ngClass]="{'svg-icon-success': !isNullOrUndefined(pathImageObj['secondary'].path)}"></svg-icon>
                    <p>{{'copiaBoleto'|translate}}</p>
                    <p>{{'file-types-img' | translate}}</p>
                    <label for="doc-2" class="btn btn-primary  page-content__upload__input mb-0">
                        <svg-icon src="assets/svg/attachment.svg"></svg-icon>
                        <span>Anexar</span>
                        <input type="file" id="doc-2" name="doc-2" required
                               onclick="this.value = null"
                               (change)="handlerUploadSingle($event, 'billet', 'secondary')"
                               accept=".image/, .pdf, .png, .jpg, .jpeg"/>
                    </label>
                    <div *ngIf="!isNullOrUndefined(pathImageObj['secondary'].path)">
                        {{pathImageObj['secondary'].name}}
                        <svg-icon (click)="removeFile('billet', 'secondary')" class="icon svg-icon"
                                  src="assets/svg/trash.svg">
                        </svg-icon>
                    </div>
                </div>
            </form>

            <div class="page-content__buttons justify-content-center">
                <!--<button class="btn btn-warning" data-toggle="modal" data-target="#success">Salvar</button>-->
                <button class="btn btn-warning" (click)="onSaveRefund()">{{'salvar'|translate}}</button>
            </div>
        </ng-container>

    </div>
</div>

<div id="success" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <span class="modal-body-success">
                    <svg-icon src="assets/svg/success.svg"></svg-icon>
                    <p>{{'oksuasolicitacao'|translate}}
                        <strong>{{'reembolsonew'|translate}}</strong>{{'foirealizadacom'|translate}}
                        <strong>{{'sucesso'|translate}}</strong>.</p>
                    <button class="btn btn-block btn-warning mt-4" data-dismiss="modal">{{'fechar'|translate}}</button>
                </span>
            </div>
        </div>
    </div>
</div>

<div id="successEditAdmin" class="modal fade" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-body">
                <span class="modal-body-success">
                    <svg-icon src="assets/svg/success.svg"></svg-icon>
                    <p>{{'oksuaedicao'|translate}}
                        <strong>{{'reembolsonew'|translate}}</strong>{{'foirealizadacom'|translate}}
                        <strong>{{'sucesso'|translate}}</strong>.</p>
                    <button class="btn btn-block btn-warning mt-4" data-dismiss="modal">{{'fechar'|translate}}</button>
                </span>
            </div>
        </div>
    </div>
</div>

<app-paint-screen [(flagSignature)]="controlFlagPaint" (paintScreen)="paintScreen = $event"></app-paint-screen>

