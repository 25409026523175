import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {User} from '../../../generated/graphql';
import {StorageService} from "../../service/user-simulation.service";
import {Subscription} from "rxjs";

declare var $: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {

    user: User = new User();
    storageSubscription!: Subscription;
    simulatedUser;

    constructor(public router: Router,
                public translate: TranslateService,
                private storageService: StorageService,
                public userService: UserService
    ) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe({
            next: data => {
                this.user = data;
            }, error: err => super.onError(err)
        });

        this.storageSubscription = this.storageService.simulatedUser$.subscribe(user => {
            // console.log('CHANGE NO HEADER', user);
            this.simulatedUser = user;
        });

    }

    getSimulatedUser() {
        this.storageSubscription = this.storageService.simulatedUser$.subscribe(user => {

        });
    }

    actionNavToggle(): void {
        $('body').toggleClass('nav-open');
        $('.hamburger').toggleClass('open');
    }

    handleClearSimulatedUser() {
        this.storageService.clearSimulatedUser();
    }

}
