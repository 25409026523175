import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {Notification} from '../../model/user/notification';
import {QueryRef} from 'apollo-angular';
import {
    CurrentUserNotificationGQL,
    CurrentUserNotificationQuery,
    CurrentUserNotificationQueryVariables,
    RemoveAllUserNotificationByUserIdGQL,
    RemoveUserNotificationGQL,
    User,
} from '../../../generated/graphql';

@Component({
    selector: 'app-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss']
})
export class NotificationComponent extends BaseComponent implements OnInit {
    modelNotifications: Notification[] = [];
    listNotify: any = [];
    listNotifyQuery: QueryRef<CurrentUserNotificationQuery, CurrentUserNotificationQueryVariables>;
    user: User = new User();

    constructor(public router: Router,
                public translate: TranslateService,
                public notificationsService: UserService,
                public userService: UserService,
                private currentUserNotificationGQL: CurrentUserNotificationGQL,
                private removeAllUserNotificationByUserIdGQL: RemoveAllUserNotificationByUserIdGQL,
                private removeUserNotificationGQL: RemoveUserNotificationGQL) {
        super(router, translate);
    }

    ngOnInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data;
                if (!this.isNullOrUndefined(this.user?.id)) {
                    this.actionNotification();
                }
            }
        });

        /*this.notificationsService.notification.subscribe({
            next: data => {
                this.modelNotifications = data as Notification[];
            }, error: err => super.onError(err)
        });
        this.notificationsService.getNotification();*/
        // console.log('ngOnInit notify');
    }

    getUserNotify() {
        this.listNotifyQuery = this.currentUserNotificationGQL.watch();
        this.listNotifyQuery.valueChanges.subscribe(({data}) => {
            // console.log('getUserNotify');
            // console.log(data);
            setTimeout(() => {
                this.getUserNotify();
            }, 5000);
            this.modelNotifications = data.currentUserNotification;
        });
    }


    actionNotification() {
        console.log('actionNotification');
        this.getUserNotify();
        /*this.notificationsService.updateNotification(model.id).subscribe({
            next: data => {
                this.router.navigate(['/register/' + model.relationId]);
            }, error: err => super.onError(err)
        });*/
    }

    actionNotificationRemove(s: string) {
        this.removeUserNotificationGQL.mutate({id: s}).subscribe(
            (data) => {
                this.listNotifyQuery.refetch();
            });
    }

    deleteNotification() {
        this.removeAllUserNotificationByUserIdGQL.mutate({id: this.user.id}).subscribe(({data}) => {
            this.getUserNotify();
        }, error => super.onError(error));
    }
}
