<div class="page-header">
    <div class="container justify-content-center">
        <a [routerLink]="['/lista-de-solicitacoes']" class="page-header__back">
            <svg-icon src="assets/svg/back.svg"></svg-icon>
        </a>
        <h2 class="page-header__title"><strong>{{ 'treinamento.logsolicitacaotitle'| translate }}</strong></h2>
        <ng-container *ngIf="user?.profile === 'admin' && solicitationLog?.status === 'approved'">
            <div class="page-header__btn">
                <button class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 mr-md-0 fs-16"
                        [routerLink]="'/treinamentos-admin/' + solicitationLog.id">Editar formulário
                </button>
            </div>
        </ng-container>
    </div>
</div>

<div class="reason-rejected" *ngIf="solicitationLog?.status === 'rejected'">
    <div class="accordion" id="accordionExample" style="width: 100%">
        <div style="background: #D81212;">
            <button class="btn btn-block text-left review-button" type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <div class="d-flex justify-content-between" style="align-items: self-end">
                    <h4 class="text-primary font-weight-bolder"><strong>Reprovado por: {{ getNameRejected() }}</strong>
                    </h4>
                    <h4 class="text-primary font-weight-bolder"><strong>Motivo da Reprova</strong></h4>
                    <svg-icon src="assets/svg/expand.svg"></svg-icon>
                </div>
            </button>
            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                 data-parent="#accordionExample"
                 style="background: #FFA500;">
                <div class="card-body pt-0 pb-2" style="background: #D81212;">
                    <span class="text-primary" style="font-size: 18px"> {{ solicitationLog?.response }}</span>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="page-content" *ngIf="!isNullOrUndefined(solicitationLog,true)">
    <div class="container">
        <div class="page-content__status">
            <!-- VARIAÇÃO DE STATUS: approving, rejected e approved -->
            <div class="infos no-border" [ngClass]="solicitationLog?.status">
                <span class="number">
                    <span class="page-header__number mt-1">Nº <strong>{{ solicitationLog?.code }}</strong></span>
                </span>
                <span class="ml-3 text flex-column align-items-start tag tag-secondary tag-left color">
                    <span class="head small">Status</span>
                    <strong>{{ getStatusName(solicitationLog?.status) }}</strong>
                </span>
                <span class="text flex-column align-items-start mr-lg-auto">
                    <span class="head small">{{ 'atualizado' | translate }}</span>
                    <span class="color">{{ solicitationLog?.updatedAt | date:'dd/MM/yyyy':'UTC+0' }}</span>
                </span>
                <span class="text flex-column align-items-start mr-0">
                    <span class="head small">{{ 'novocadastro.criado' | translate }}</span>
                    <span class="text-primary">{{ solicitationLog?.createdAt | date:'dd/MM/yyyy':'UTC+0' }}</span>
                </span>
            </div>
            <div class="custom-nav full mt-3">
                <!-- VARIAÇÃO DE STATUS: active, error e done -->
                <ul class="nav nav-tabs custom-nav__menu">
                    <li class="nav-item" *ngFor="let s of solicitationLog?.solicitationStep;let i = index">
                        <span class="nav-link"
                              [ngClass]="{'active': i === solicitationLog?.step, 'done':s.status === 'approved', 'error':s.status === 'rejected'}">
                            <div class="bubble"></div>
                            <span class="tooltip-label label is-cursor"
                                  (mouseenter)="onShowTooltip(i, true)"
                                  (mouseleave)="onShowTooltip(i, false)">
                                {{getProfileNameByRole(s.role)}}</span>
                            <span class="responsible-name"*ngIf="showTooltip[i]">{{s.responsibleName}}</span>
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <ng-container>
            <ng-container *ngFor="let c of solicitationLog?.colaborators">
                <article class="page-content__details box mb-md-4"
                         *ngIf="solicitationLog?.colaborators.length === 1 && c.isRequester">
                    <div class="page-content__details__tab">{{ 'treinamento.requester'| translate }}</div>
                    <div class="row">
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{ 'treinamento.name' | translate }}</h4>
                            <p class="text">{{ c.name }}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2">
                            <h4 class="title">{{ 'treinamento.re' | translate }}</h4>
                            <p class="text">{{ c.re }}</p>
                        </div>
                    </div>
                    <ng-container *ngIf="false">
                        <hr>
                        <div class="row">
                            <div class="col-6 col-sm-4 col-lg-3">
                                <h4 class="title">{{ 'corpele'|translate }}</h4>
                                <p class="text">{{ c.skinColor?.name }}</p>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-3">
                                <h4 class="title">{{ 'generosex'|translate }}</h4>
                                <p class="text">{{ c.genderIdentity?.name }}</p>
                            </div>
                            <div class="col-6 col-sm-4 col-lg-3">
                                <h4 class="title">{{ 'orientsex'|translate }}</h4>
                                <p class="text">{{ c.sexualOrientation?.name }}</p>
                            </div>
                        </div>
                    </ng-container>
                    <hr>
                    <div class="row">
                        <div class="col-6 col-sm-4 col-lg-2">
                            <h4 class="title">{{ 'treinamento.unity' | translate }}</h4>
                            <p class="text">{{ c.unity.name }}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3 mt-sm-3 mt-lg-0">
                            <h4 class="title">{{ 'treinamento.departmentSector' | translate }}</h4>
                            <p class="text">{{ c.areaManager }}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-2 mt-sm-3 mt-lg-0">
                            <h4 class="title">{{ 'treinamento.branch' | translate }}</h4>
                            <p class="text">{{ c.ramal }}</p>
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{ 'treinamento.currentPosition' | translate }}</h4>
                            <p class="text">{{ c.currentJob }}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{ 'treinamento.admissionDate' | translate }}</h4>
                            <p class="text">{{ c.admissionDate | date:'dd/MM/yyyy':'UTC+0' }}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{ 'treinamento.costCenter'| translate }}</h4>
                            <p class="text">{{ c.costCenter }}</p>
                        </div>
                        <div class="col-6 col-sm-4 col-lg-3">
                            <h4 class="title">{{ 'treinamento.areaManager' | translate }}</h4>
                            <p class="text">{{ c.directorArea }}</p>
                        </div>
                    </div>
                </article>

            </ng-container>
            <article class="page-content__details box mb-md-4" *ngIf="solicitationLog?.colaborators.length > 1">
                <div class="page-content__details__tab">{{ 'treinamento.collaborator'| translate }}</div>
                <div class="row">
                    <div class="col-12">
                        <table class="table table-striped">
                            <thead>
                            <tr>
                                <th scope="col">{{ 'treinamento.name' | translate }}</th>
                                <th scope="col">{{ 'treinamento.re' | translate }}</th>
                                <th scope="col">{{ 'bolsa.nascimento' | translate }}</th>
                                <th scope="col">{{ 'treinamento.unity' | translate }}</th>
                                <th scope="col">{{ 'treinamento.departmentSector' | translate }}</th>
                                <th scope="col">{{ 'treinamento.competencyTest' | translate }}</th>
                                <th scope="col"
                                    *ngIf="user?.profile === 'hr_development' || user?.profile === 'admin'">
                                    Incluir
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <ng-container *ngFor="let c of solicitationLog?.colaborators">
                                <ng-container *ngIf="!c.isRequester">
                                    <tr *ngIf="solicitationLog?.step === 0 || (solicitationLog?.step > 0 && c.status !== 'rejected')">
                                        <th scope="row">{{ c.name }}</th>
                                        <td>{{ c.re }}</td>
                                        <td>{{ c.dateOfBirth | date: 'dd/MM/yyyy':'UTC+0' }}</td>
                                        <td>{{ c.unity.name }}</td>
                                        <td>{{ c.directorArea }}</td>
                                        <td *ngIf="user?.profile === 'admin'">
                                            <!--                                            {{c.competencyTest}}-->
                                            {{ "Não" }}
                                        </td>

                                        <td *ngIf="user?.profile === 'hr_development' || user?.profile === 'admin'">
                                            <div class="d-flex align-items-center">
                                                <p class="mb-0" style="font-size: 13px"
                                                   *ngIf="solicitationLog?.step === 0">
                                                    Não</p>
                                                <ng-container *ngIf="solicitationLog?.step === 0">
                                                    <div class="custom-control custom-switch"
                                                         style="padding-left: 2.75rem;">
                                                        <input (change)="handlerColaborators(c)"
                                                               [checked]="c.status ==='approved'"
                                                               [disabled]="solicitationLog?.status !== 'pending'"
                                                               type="checkbox" class="custom-control-input"
                                                               id="check_{{c.id}}" name="check_{{c.id}}"
                                                        >
                                                        <label class="custom-control-label"
                                                               for="check_{{c.id}}"></label>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="solicitationLog?.step === 3">
                                                    <div class="custom-control custom-switch">
                                                        <input (change)="handlerColaboratorsRegistrate(c)"
                                                               [checked]="c.status ==='registered'"
                                                               [disabled]="solicitationLog?.status !== 'pending'"
                                                               type="checkbox" class="custom-control-input"
                                                               id="check_b{{c.id}}" name="check_b{{c.id}}"
                                                        >
                                                        <label class="custom-control-label"
                                                               for="check_b{{c.id}}"></label>
                                                    </div>
                                                </ng-container>
                                                <p class="mb-0" style="font-size: 13px"
                                                   *ngIf="solicitationLog?.step === 0">
                                                    Sim</p>
                                            </div>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </article>
        </ng-container>

        <article class="page-content__details box mb-md-4" *ngIf="!isNullOrUndefined(solicitationLog?.course)">
            <div class="page-content__details__tab">{{ 'treinamento.course' | translate }}</div>
            <div class="row">
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'treinamento.courseName' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.course?.name }}</p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'treinamento.amountOfHours' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.course?.workload }} h</p>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'treinamento.startDate' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.course?.start | date:'dd/MM/yyyy':'UTC+0' }}</p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'treinamento.endDate' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.course?.end | date:'dd/MM/yyyy':'UTC+0' }}</p>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'treinamento.totalFee' | translate }}</h4>
                    <strong currencyMask
                            class="text">{{ solicitationLog?.course?.value | currency:getSymbolCurrency(solicitationLog?.course?.typeOfCurrency) }}</strong>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">Tipo do treinamento</h4>
                    <p class="text">{{ solicitationLog.course?.trainingType === 'lecture' ? 'Palestra' : (solicitationLog.course?.trainingType === 'course' ? 'Curso' : 'Congresso') }}</p>
                </div>
            </div>
        </article>

        <article class="page-content__details box mb-md-4" *ngIf="!isNullOrUndefined(solicitationLog?.institution)">
            <div class="page-content__details__tab">{{ 'treinamento.institution' | translate }}</div>
            <div class="row">
                <div class="col-12">
                    <h4 class="title">{{ 'treinamento.institutionName' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.institution.name }}</p>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'treinamento.emailAccount' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.institution.email }}</p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'treinamento.contactPhone' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.institution.phone | mask:'(99) 99999-9999 | (99) 9999-9999' }}</p>
                </div>
                <div class="col-6 col-sm-4">
                    <h4 class="title">{{ 'treinamento.nameOfResponsible' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.institution.responsible }}</p>
                </div>
            </div>
        </article>

        <!--        <article class="page-content__details box mb-md-4" *ngIf="!isNullOrUndefined(solicitationLog?.receipts)">-->
        <!--            <div class="page-content__details__tab">{{'bolsa.comprovantes' | translate}}</div>-->
        <!--            <div class="row justify-content-center pt-3">-->
        <!--                <div class="col-12 col-md-4 text-center" *ngFor="let r of solicitationLog?.receipts">-->
        <!--                    <h4 class="title large">{{getReceiptName(r.type)}}</h4>-->
        <!--                    <a [href]="r.url" target="_blank" class="attachment">-->
        <!--                        &lt;!&ndash;<img *ngIf="r.extension !== 'pdf'" src="{{r.url}}" alt="receipts"/>&ndash;&gt;-->
        <!--&lt;!&ndash;                        <svg-icon [svgStyle]="{'width':'30px','height':'30px'}" src="assets/svg/icon-metro-file-pdf.svg"></svg-icon>&ndash;&gt;-->
        <!--                        <svg-icon [svgStyle]="{'width':'30px','height':'30px'}" [src]="svgType(r.extension)"></svg-icon>-->
        <!--                        <p>{{r.name}}.{{r.extension}}</p>-->
        <!--                    </a>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </article>-->

        <article class="page-content__details box mb-md-4"
                 *ngIf="!isNullOrUndefined(solicitationLog?.receiptJustification)">
            <div class="page-content__details__tab">{{ 'treinamento.justification' | translate }}</div>
            <div class="row pt-md-4">
                <div class="col-md-8 mb-4">
                    <h4 class="title">{{ 'treinamento.helpDescribe' | translate }}</h4>
                    <p class="text">{{ solicitationLog?.receiptJustification }}</p>

                </div>
                <div class="col-md-4">
                    <div class="col-md-12 text-center" *ngFor="let r of solicitationLog?.receipts">
                        <h4 class="title large">{{ getReceiptName(r.type) }}</h4>
                        <a [href]="r.url" target="_blank" class="attachment d-flex flex-column align-items-center">
                            <img class="attach" *ngIf="r.extension !== 'pdf'" src="{{r.url}}" alt="receipts"/>
                            <!--                        <svg-icon [svgStyle]="{'width':'30px','height':'30px'}" src="assets/svg/icon-metro-file-pdf.svg"></svg-icon>-->
                            <svg-icon [svgStyle]="{'width':'30px','height':'30px'}"
                                      [src]="svgType(r.extension)"></svg-icon>
                            <p>{{ r.name }}.{{ r.extension }}</p>
                        </a>
                    </div>
                    <ng-container *ngIf="solicitationLog.isAcceptPolicy">
                        <p class="text mt-4 justify-content-center">Declaro estar ciente com a Política de
                            Treinamento</p>
                    </ng-container>
                </div>
            </div>
        </article>

        <article class="page-content__details box mb-md-4 d-flex"
                 *ngIf="!this.isNullOrUndefined(solicitationLog?.signature)">
            <div class="page-content__details__tab">{{ 'signature'|translate }}</div>
            <img class="signature" src="{{solicitationLog?.signature}}" alt="signature"/>
        </article>

        <ng-container *ngIf="user.profile === 'admin' && !isNullOrUndefined(solicitationLog?.adminObservation)">
            <article class="page-content__details box mb-md-4">
                <div class="page-content__details__tab">{{ 'observacao' | translate }}</div>
                <div class="row pt-md-4">
                    <div class="col-6 col-sm-6">
                        <h4 class="title">{{ 'observacao' | translate }}</h4>
                        <p class="text">{{ solicitationLog?.adminObservation }}</p>
                    </div>
                </div>
            </article>
        </ng-container>

        <div class="d-flex align-items-center flex-wrap mb-4 w-100"
             [ngClass]="{'justify-content-end' : user?.profile !== 'admin', 'justify-content-between' : user?.profile === 'admin'}">

            <div class="page-content__buttons mb-0 justify-content-center">
                <ng-container *ngIf="user?.profile === 'admin'">
                    <button class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 fs-18"
                            (click)="returnAction(solicitationLog.id)">{{ 'novocadastro2.retornar' | translate }}
                    </button>

                    <ng-container *ngIf="isNullOrUndefined(solicitationLog?.adminObservation)">
                        <button class="btn btn-primary btn-fixed btn-lg mb-3 mr-4 mr-md-0 fs-18"
                                (click)="showModal('observations')">
                            Adicionar Observação
                        </button>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    </div>
</section>