import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private SIMULATED_USER_KEY = 'SIMULATED_USER';

  private simulatedUserSubject = new BehaviorSubject<any>(null);

  simulatedUser$ = this.simulatedUserSubject.asObservable();

  constructor() {
    this.loadSimulatedUser();
  }

  private loadSimulatedUser() {
    const user = this.getSimulatedUser();
    this.simulatedUserSubject.next(user);
  }

  setSimulatedUser(value: any): void {
    localStorage.setItem(this.SIMULATED_USER_KEY, JSON.stringify(value));
    this.simulatedUserSubject.next(value);
  }

  getSimulatedUser(): any {
    const item = localStorage.getItem(this.SIMULATED_USER_KEY);
    return item ? JSON.parse(item) : null;
  }

  clearSimulatedUser(): void {
    localStorage.removeItem(this.SIMULATED_USER_KEY);
    this.simulatedUserSubject.next(null);
  }
}
