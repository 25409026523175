import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {
    Colaborator,
    CreateColaboratorGenderIdentityInput,
    CreateColaboratorInput, CreateColaboratorSexualOrientationInput,
    CreateColaboratorSkinColorInput,
    CreateColaboratorUnityInput,
    CreateCourseInput,
    CreateInstitutionInput, CreateLanguageSchoolInput,
    CreateSolicitationGQL,
    CreateSolicitationInput,
    EnumType, FindAllPoliticsGQL, FindAllPoliticsQuery, FindAllPoliticsQueryVariables,
    FindAllSalaryRangeGQL,
    FindAllSalaryRangeQuery,
    FindAllSalaryRangeQueryVariables,
    GenderIdentity,
    GetEnumGQL,
    GetEnumQuery,
    GetGenderIdentitiesGQL,
    GetGenderIdentitiesQuery,
    GetGenderIdentitiesQueryVariables,
    GetSexualOrientationsGQL,
    GetSexualOrientationsQuery,
    GetSexualOrientationsQueryVariables,
    GetSkinColorsGQL,
    GetSkinColorsQuery,
    GetSkinColorsQueryVariables,
    GetSolicitationGQL,
    GetSolicitationQuery,
    GetUnitiesGQL,
    GetUnitiesQuery, GetUserByReGQL, Politic,
    SalaryRange,
    SexualOrientation,
    SkinColor,
    Solicitation,
    Unity, UpdateSolicitationAdminGQL, UpdateSolicitationAdminInput,
    UpdateSolicitationGQL,
    UpdateSolicitationInput, User
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import * as moment from 'moment';
import {PaintScreen} from '../paint-screen/model/pain-screen';

declare var $: any;

@Component({
    selector: 'app-treinamentos',
    templateUrl: './treinamentos.component.html',
    styleUrls: ['./treinamentos.component.scss']
})
export class TreinamentosComponent extends BaseComponent implements OnInit, OnDestroy {

    modelDate = moment().format('DD/MM/yyyy');
    dataSolicitation: CreateSolicitationInput = new CreateSolicitationInput();
    model: Solicitation = new Solicitation();
    modelColaborator: CreateColaboratorInput = new CreateColaboratorInput();

    salaryRanges: SalaryRange [] = [];
    findAllSalaryRangeQuery: QueryRef<FindAllSalaryRangeQuery, FindAllSalaryRangeQueryVariables>;

    getSkin: SkinColor[] = [];
    getSkinQuery: QueryRef<GetSkinColorsQuery, GetSkinColorsQueryVariables>;

    getGender: GenderIdentity[] = [];
    getGenderQuery: QueryRef<GetGenderIdentitiesQuery, GetGenderIdentitiesQueryVariables>;

    getSexualOrientation: SexualOrientation[] = [];
    getSexualOrientationQuery: QueryRef<GetSexualOrientationsQuery, GetSexualOrientationsQueryVariables>;

    listUnity: any = []; // Observable<GetUnitiesQuery['getUnities']>;
    listUnityQuery: QueryRef<GetUnitiesQuery>;

    solicitationQuery: QueryRef<GetSolicitationQuery>;

    dropdownSettingsUnities = {};
    dropdownSettingsSkin = {};
    dropdownSettingsGender = {};
    dropdownSettingsSexualOrientation = {};

    dropdownListUnities = [];
    dropdownListSkin = [];
    dropdownListGender = [];
    dropdownListSexualOrientation = [];

    selectedItemsUnities = [];
    selectedItemsSkin = [];
    selectedItemsGender = [];
    selectedItemsSexualOrientation = [];

    dropdownListUnitiesColaborators = [];
    dropdownListSkinColaborators = [];
    dropdownListGenderColaborators = [];
    dropdownListSexualOrientationColaborators = [];

    selectedItemsUnitiesColaborators = [];
    selectedItemsSkinColaborators = [];
    selectedItemsGenderColaborators = [];
    selectedItemsSexualOrientationColaborators = [];

    step = 1;
    stepCurrent = 1;
    done = false;

    pathImageObj = {
        primary: {name: '', path: ''}
    };
    // pathImage: string | ArrayBuffer;
    file: File = null;
    listFiles = [];
    isUpload = false;
    numberSolicitation = 0;
    documentType = 'CNPJ';
    jumpRequester = 0;

    controlFlagPaint = false;
    paintScreen: PaintScreen = new PaintScreen();
    collaborator: User = new User(); // inicia uma instancia de colaborador para o getUSerByRe
    colaborators: CreateColaboratorInput = new CreateColaboratorInput();

    findAllPoliticsQuery: QueryRef<FindAllPoliticsQuery, FindAllPoliticsQueryVariables>;
    politic: Politic[] = [];
    user: User = new User();

    urlForAdminEdit: boolean;

    constructor(public router: Router,
                public router2: ActivatedRoute,
                public translate: TranslateService,
                public userService: UserService,
                private getUnitiesGQL: GetUnitiesGQL,
                private findAllSalaryRangeGQL: FindAllSalaryRangeGQL,
                private createSolicitationGQL: CreateSolicitationGQL,
                private updateSolicitationGQL: UpdateSolicitationGQL,
                private updateSolicitationAdminGQL: UpdateSolicitationAdminGQL,
                private getSkinColorsGQL: GetSkinColorsGQL,
                private getGenderIdentitiesGQL: GetGenderIdentitiesGQL,
                private getSexualOrientationsGQL: GetSexualOrientationsGQL,
                private findAllPoliticsGQL: FindAllPoliticsGQL,
                private getSolicitationGQL: GetSolicitationGQL,
                private getUserByReGQL: GetUserByReGQL,
    ) {
        super(router, translate);
        this.dataSolicitation.institution = new CreateInstitutionInput();
        this.dataSolicitation.colaborators = [];
        this.dataSolicitation.course = new CreateCourseInput();
    }

    ngOnInit(): void {

        this.dataSolicitation.type = 'training';
        this.dataSolicitation.institution.isOnline = false;
        this.dataSolicitation.course.typeOfCurrency = 'real';

        this.userService.user.subscribe((data) => {
            this.user = data as User;
            console.log('DATA DO USER', data);
        });

        this.router2.params.subscribe(url => {

            this.findAllPoliticsQuery = this.findAllPoliticsGQL.watch();
            this.findAllPoliticsQuery.valueChanges.subscribe(({data}) => {
                this.politic = data.findAllPolitics as Politic[];
            }, error => super.onError(error));

            if (!this.isNullOrUndefined(url) && !this.isNullOrUndefined(url.id)) {
                this.solicitationQuery = this.getSolicitationGQL.watch({id: url.id});
                this.solicitationQuery.valueChanges.subscribe(({data}) => {
                    this.model = data.getSolicitation as Solicitation;

                    this.urlForAdminEdit = this.router.url.includes('treinamentos-admin');

                    if (this.urlForAdminEdit) {
                        this.dataSolicitation.isAcceptPolicy = !this.isNullOrUndefined(this.model.isAcceptPolicy) ? this.model.isAcceptPolicy : this.dataSolicitation.isAcceptPolicy;
                        this.dataSolicitation.isAcceptTerms = !this.isNullOrUndefined(this.model.isAcceptTerms) ? this.model.isAcceptTerms : this.dataSolicitation.isAcceptTerms;
                    }

                    this.jumpRequester = this.model.colaborators.length > 1 ? 0 : 1;
                    const solicit = this.model.colaborators.find((e) => e.isRequester);
                    this.colaborators = {
                        activities: solicit.activities,
                        admissionDate: solicit.admissionDate,
                        areaManager: solicit.areaManager,
                        costCenter: solicit.costCenter,
                        currentJob: solicit.currentJob,
                        directorArea: solicit.directorArea,
                        id: solicit.id,
                        isRequester: solicit.isRequester,
                        name: solicit.name,
                        ramal: solicit.ramal,
                        email: solicit.email,
                        re: solicit.re,
                        salaryRange: solicit.salaryRange,
                        dateOfBirth: solicit.dateOfBirth,
                        unity: !this.isNullOrUndefined(solicit.unity?.id) ? {
                            id: solicit.unity?.id,
                            name: solicit.unity?.name
                        } as CreateColaboratorUnityInput : null,
                    } as CreateColaboratorInput;
                    this.dataSolicitation.colaborators = this.model.colaborators.map((m) => {
                        return {
                            activities: m.activities,
                            admissionDate: m.admissionDate,
                            areaManager: m.areaManager,
                            costCenter: m.costCenter,
                            currentJob: m.currentJob,
                            directorArea: m.directorArea,
                            id: m.id,
                            isRequester: m.isRequester,
                            name: m.name,
                            email: m.email,
                            ramal: m.ramal,
                            re: m.re,
                            salaryRange: m.salaryRange,
                            skinColor: !this.isNullOrUndefined(m.skinColor?.id) ? {id: m.skinColor?.id ?? ''} as CreateColaboratorSkinColorInput : null,
                            genderIdentity: !this.isNullOrUndefined(m.genderIdentity?.id) ? {id: m.genderIdentity?.id ?? ''} as CreateColaboratorGenderIdentityInput : null,
                            sexualOrientation: !this.isNullOrUndefined(m.sexualOrientation?.id) ? {id: m.sexualOrientation?.id ?? ''} as CreateColaboratorSexualOrientationInput : null,
                            dateOfBirth: m.dateOfBirth,
                            unity: {id: m.unity?.id ?? '', name: m.unity?.name ?? ''} as CreateColaboratorUnityInput,
                        } as CreateColaboratorInput;
                    });
                    this.dataSolicitation.course = {
                        attendingYear: this.model.course.attendingYear,
                        durationMonths: this.model.course.durationYear,
                        durationYear: this.model.course.durationYear,
                        end: this.model.course.end,
                        id: this.model.course.id,
                        language: this.model.course.language,
                        level: this.model.course.level,
                        name: this.model.course.name,
                        registrationDate: this.model.course.registrationDate,
                        start: this.model.course.start,
                        type: this.model.course.type,
                        typeOfCurrency: this.model.course.typeOfCurrency,
                        value: this.model.course.value,
                        workload: this.model.course.workload,
                        trainingType: this.model.course.trainingType,
                    };
                    this.dataSolicitation.institution = {
                        address: this.model.institution.address,
                        isOnline: this.model.institution.isOnline,
                        cnpj_cpf: this.model.institution.cnpj_cpf,
                        email: this.model.institution.email,
                        id: this.model.institution.id,
                        name: this.model.institution.name,
                        phone: this.model.institution.phone,
                        phone2: this.model.institution.phone2,
                        responsible: this.model.institution.responsible,
                        unity: this.model.institution.unity
                    };
                    this.dataSolicitation.languageSchool = this.model.languageSchool.map((e) => {
                        return {name: e.name, time: e.time} as CreateLanguageSchoolInput;
                    });
                    this.dataSolicitation.courseName = this.model.courseName;
                    this.dataSolicitation.courseStatus = this.model.courseStatus;
                    this.pathImageObj.primary = {name: this.model.receipts[0].name, path: this.model.receipts[0].url};
                    this.dataSolicitation.receiptJustification = this.model.receiptJustification;
                    this.dataSolicitation.type = this.model.type;
                    this.dataSolicitation.startDate = this.model.startDate;

                    if (super.isNullOrUndefined(this.dataSolicitation.type) && super.isNullOrUndefined(this.model.id)) {
                        this.router.navigate(['/solicitacoes']);
                    } else {
                        const c: CreateColaboratorInput = new CreateColaboratorInput();
                        c.isRequester = true;
                        if (this.dataSolicitation.colaborators.length === 0) {
                            this.dataSolicitation.colaborators.push(c);
                        }
                    }
                    this.getList();
                }, error => super.onError(error));
            } else {
                $('#questContributors').modal('show');
                this.userService.solicitation.subscribe({
                    next: data => {
                        const createSolicitation: CreateSolicitationInput = data as CreateSolicitationInput;
                        if (!super.isNullOrUndefined(createSolicitation)) {
                            this.dataSolicitation = createSolicitation;
                            this.dataSolicitation.type = 'training';

                            if (this.isNullOrUndefined(this.dataSolicitation.course)) {
                                this.dataSolicitation.course = new CreateCourseInput();
                            }

                            if (this.isNullOrUndefined(this.modelColaborator)) {
                                this.modelColaborator.unity = new CreateColaboratorUnityInput();
                            }

                            if (this.isNullOrUndefined(this.dataSolicitation.institution)) {
                                this.dataSolicitation.institution = new CreateInstitutionInput();
                                this.dataSolicitation.institution.isOnline = false;
                            }

                            if (this.isNullOrUndefined(this.dataSolicitation.colaborators)) {
                                this.dataSolicitation.colaborators = [];
                                const c: CreateColaboratorInput = new CreateColaboratorInput();
                                c.unity = new CreateColaboratorUnityInput();
                                c.isRequester = true;
                                this.dataSolicitation.colaborators.push(c);
                            }
                        }
                        this.getList();
                    }, error: err => super.onError(err)
                });
            }
        });


        this.getSalaries();
        this.dropdownSettingsUnities = {
            singleSelection: true,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 1,
            enableCheckAll: false,
            allowSearchFilter: true
        };

        this.dropdownSettingsSkin = {
            singleSelection: true,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 1,
            enableCheckAll: false,
            allowSearchFilter: true
        };

        this.dropdownSettingsGender = {
            singleSelection: true,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 1,
            enableCheckAll: false,
            allowSearchFilter: true
        };

        this.dropdownSettingsSexualOrientation = {
            singleSelection: true,
            idField: 'id',
            textField: 'name',
            itemsShowLimit: 1,
            enableCheckAll: false,
            allowSearchFilter: true
        };


        $(() => {
            const st = this;
            $('#myTab a').on('click', function (e) {
                e.preventDefault();
                e.stopImmediatePropagation();
                const isStepValid = {
                    1: () => {
                        return st.stepRequesterValidate();
                    },
                    2: () => {
                        return st.stepColaboratorsValidate();
                    },
                    3: () => {
                        return st.stepCoursesValidate();
                    },
                    4: () => {
                        return st.stepInstitutionValidate();
                    }
                };
                if (!isStepValid[st.step]()) {
                    return false;
                } else {
                    st.done = false;
                    st.step = st.stepCurrent;
                    st.userService.updateDataSolicitation(st.dataSolicitation);
                    $(this).tab('show');
                }
            });
        });
    }

    getUserByRe() {
        this.getUserByReGQL
            .watch({re: this.modelColaborator.re})
            .valueChanges
            .subscribe(({ data }) => {
                console.log('ADMISSION DATE', this.collaborator.admissionDate);
                this.collaborator = data.getUserByRe as User;
            });
    }

    getList() {
        this.listUnityQuery = this.getUnitiesGQL.watch();
        this.listUnityQuery.valueChanges.subscribe(({data}) => {
            this.listUnity = data.getUnities;
            this.dropdownListUnities = this.listUnity;
            this.dropdownListUnitiesColaborators = this.listUnity;
            this.selectedItemsUnities = this.dropdownListUnities.filter((x) => x.id === this.colaborators.unity?.id) ?? [];
            this.selectedItemsUnitiesColaborators = this.dropdownListUnitiesColaborators.filter((x) => x.id === this.modelColaborator.unity?.id) ?? [];
        });

        this.getSkinQuery = this.getSkinColorsGQL.watch();
        this.getSkinQuery.valueChanges.subscribe(({data}) => {
            this.getSkin = data.getSkinColors as SkinColor[];
            this.dropdownListSkin = this.getSkin;
            this.dropdownListSkinColaborators = this.getSkin;
            this.selectedItemsSkin = this.dropdownListSkin.filter((x) => x.id === this.colaborators.skinColor?.id) ?? [];
            this.selectedItemsSkinColaborators = this.dropdownListSkinColaborators.filter((x) => x.id === this.modelColaborator.skinColor?.id) ?? [];

        });

        this.getGenderQuery = this.getGenderIdentitiesGQL.watch();
        this.getGenderQuery.valueChanges.subscribe(({data}) => {
            this.getGender = data.getGenderIdentities as GenderIdentity[];
            this.dropdownListGender = this.getGender;
            this.dropdownListGenderColaborators = this.getGender;
            this.selectedItemsGender = this.dropdownListGender.filter((x) => x.id === this.colaborators.genderIdentity?.id) ?? [];
            this.selectedItemsGenderColaborators = this.dropdownListGenderColaborators.filter((x) => x.id === this.modelColaborator.genderIdentity?.id) ?? [];
        });

        this.getSexualOrientationQuery = this.getSexualOrientationsGQL.watch();
        this.getSexualOrientationQuery.valueChanges.subscribe(({data}) => {
            this.getSexualOrientation = data.getSexualOrientations as SexualOrientation[];
            this.dropdownListSexualOrientation = this.getSexualOrientation;
            this.dropdownListSexualOrientationColaborators = this.getSexualOrientation;
            this.selectedItemsSexualOrientation = this.dropdownListSexualOrientation.filter((x) => x.id === this.colaborators.sexualOrientation?.id) ?? [];
            this.selectedItemsSexualOrientationColaborators = this.dropdownListSexualOrientationColaborators.filter((x) => x.id === this.modelColaborator.sexualOrientation?.id) ?? [];
        });
    }

    ngOnDestroy() {
        this.destroyModal('questContributors');
        this.destroyModal('resume-treinamentos');
        this.destroyModal('contributors');
    }

    getSalaries() {
        this.findAllSalaryRangeQuery = this.findAllSalaryRangeGQL.watch();
        this.findAllSalaryRangeQuery.valueChanges.subscribe(({data}) => {
            this.salaryRanges = data.findAllSalaryRange as SalaryRange[];
        }, error => super.onError(error));
    }

    backStep(n) {
        this.step = n;
        $('#myTab li:nth-child(' + n + ') a').tab('show');
    }

    changeStep(n, jump = 0) {
        if (n > 5) {
            if (this.isNullOrUndefined(this.pathImageObj.primary.name)) {
                $('#resume-treinamentos').modal('hide');
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgUploadFile'), 'warning');
                return;
            }

            this.dataSolicitation.isAcceptTerms = false;
            if (this.jumpRequester === 1) {
                if (this.dataSolicitation.isAcceptPolicy === false || this.isNullOrUndefined(this.dataSolicitation.isAcceptPolicy)) {
                    this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningTerms'), 'warning');
                    return;
                }
            }

            if (this.isNullOrUndefined(this.dataSolicitation.receiptJustification)) {
                $('#resume-treinamentos').modal('hide');
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgJustification'), 'warning');
                return;
            }

            this.done = true;

            if (this.urlForAdminEdit) {
                this.model.isAcceptPolicy = !this.isNullOrUndefined(this.model.isAcceptPolicy) ? true : this.model.isAcceptPolicy;
                this.model.isAcceptTerms = !this.isNullOrUndefined(this.model.isAcceptTerms) ? true : this.model.isAcceptTerms;
                this.onUpdateSolicitationEditAdmin();

            } else {
                if (this.jumpRequester === 0) {
                    this.paintScreen.file = null;
                    this.dataSolicitation.isAcceptPolicy = false;
                    this.dataSolicitation.isAcceptTerms = false;
                } else if (this.isNullOrUndefined(this.paintScreen.file)) {
                    $('#resume-treinamentos').modal('hide');
                    this.showMessage(this.translate.instant('baseComponent.warningAttention'), 'Para continuar, é necessária uma assinatura.', 'warning');
                    return;
                }

                this.onSaveSolicitation();
            }
        } else {
            this.stepCurrent = n + jump;
            if (n === 4 && (this.isNullOrUndefined(this.dataSolicitation.course?.trainingType))) {
                this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningRequiredFields'), 'warning');
                return;
            }
            $('#myTab li:nth-child(' + (n + jump) + ') a').click();
        }
    }

    //SOLICITANTE

    onItemSelectUnities(item: any) {
        this.colaborators.unity = new Unity();
        this.colaborators.unity.id = item.id;
        this.colaborators.unity.name = item.name;
    }

    onItemDeselectUnities(item: any) {
        this.colaborators.unity.id = '';
        this.colaborators.unity.name = '';
    }

    onItemSelectSkin(item: any) {
        this.colaborators.skinColor = new CreateColaboratorSkinColorInput();
        this.colaborators.skinColor.id = item.id;
    }

    onItemDeselectSkin(item: any) {
        this.colaborators.skinColor.id = '';
    }

    onItemSelectSexualOrientation(item: any) {
        this.colaborators.sexualOrientation = new CreateColaboratorSexualOrientationInput();
        this.colaborators.sexualOrientation.id = item.id;
    }

    onItemDeselectSexualOrientation(item: any) {
        this.colaborators.sexualOrientation.id = '';
    }

    onItemSelectGender(item: any) {
        this.colaborators.genderIdentity = new CreateColaboratorGenderIdentityInput();
        this.colaborators.genderIdentity.id = item.id;
    }

    onItemDeselectGender(item: any) {
        this.colaborators.genderIdentity.id = '';
    }

    // MODAL COLABORADOR


    onItemSelectUnitiesColaborators(item: any) {
        this.modelColaborator.unity = new Unity();
        this.modelColaborator.unity.id = item.id;
        this.modelColaborator.unity.name = item.name;
    }

    onItemDeselectUnitiesColaborators(item: any) {
        this.modelColaborator.unity.id = '';
    }

    onItemSelectSkinColaborators(item: any) {
        this.modelColaborator.skinColor = {id: item.id};
    }

    onItemDeselectSkinColaborators(item: any) {
        this.modelColaborator.skinColor = null;
    }

    onItemSelectSexualOrientationColaborators(item: any) {
        this.modelColaborator.sexualOrientation = {id: item.id};
    }

    onItemDeselectSexualOrientationColaborators(item: any) {
        this.modelColaborator.sexualOrientation = null;
    }

    onItemSelectGenderColaborators(item: any) {
        this.modelColaborator.genderIdentity = {id: item.id};
    }

    onItemDeselectGenderColaborators(item: any) {
        this.modelColaborator.genderIdentity = null;
    }


    onClearRequesterDate(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.colaborators[str] = '';
            } else {
                this.colaborators[str] = moment(a, 'DD/MM/yyyy').format();
            }
        });
    }

    onClearDate(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.colaborators[str] = '';
            } else {
                this.colaborators[str] = moment(a, 'DD/MM/yyyy').format();
            }
        });
    }

    onClearColaboratorsDate(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.modelColaborator[str] = '';
            } else {
                this.modelColaborator[str] = moment(a, 'DD/MM/yyyy').format();
            }
        });
    }

    onClearCourseDate(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.dataSolicitation.course[str] = '';
            } else {
                this.dataSolicitation.course[str] = moment(a, 'DD/MM/yyyy').format();
            }
        });
    }

    onClearModelInstitution(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.dataSolicitation.institution[str] = '';
            }
        });
    }

    stepRequesterValidate(): boolean {
        let flag = true;
        const arrValidateFields = [
            {value: this.colaborators.name, text: this.translate.instant('nome') + '* <br />'},
            {value: this.colaborators.re, text: 'RE* <br />'},
            {value: this.colaborators.email, text: this.translate.instant('E-mail') + '* <br />'},
            // {
            //     value: this.colaborators.dateOfBirth,
            //     text: this.translate.instant('bolsa.nascimento') + '* <br />'
            // },
            {
                value: this.colaborators.unity?.id,
                text: this.translate.instant('bolsa.unidade') + '* <br />'
            },
            {
                value: this.colaborators.areaManager,
                text: this.translate.instant('bolsa.departamento') + '* <br />'
            },
            {
                value: this.colaborators.ramal,
                text: this.translate.instant('bolsa.ramal') + '* <br />'
            },
            // {
            //     value: this.colaborators.currentJob,
            //     text: this.translate.instant('bolsa.cargo') + '* <br />'
            // },
            // {
            //     value: this.colaborators.admissionDate,
            //     text: this.translate.instant('bolsa.admissao') + '* <br />'
            // },
            // {
            //     value: this.colaborators.costCenter,
            //     text: this.translate.instant('bolsa.centro') + '* <br />'
            // },
            // {
            //     value: this.colaborators.directorArea,
            //     text: this.translate.instant('bolsa.gerente') + '* <br />'
            // },
            // {
            //     value: this.colaborators.salaryRange,
            //     text: this.translate.instant('bolsa.faixa') + '* <br />'
            // },
        ];
        if (this.jumpRequester === 1) {
            this.colaborators.admissionDate = this.user.admissionDate;
            arrValidateFields.push(
                {
                    value: this.colaborators.dateOfBirth,
                    text: this.translate.instant('bolsa.nascimento') + '* <br />'
                },
                {
                    value: this.colaborators.currentJob,
                    text: this.translate.instant('bolsa.cargo') + '* <br />'
                },
                {
                    value: this.colaborators.admissionDate,
                    text: this.translate.instant('bolsa.admissao') + '* <br />'
                },
                {
                    value: this.colaborators.costCenter,
                    text: this.translate.instant('bolsa.centro') + '* <br />'
                },
                {
                    value: this.colaborators.directorArea,
                    text: this.translate.instant('bolsa.gerente') + '* <br />'
                }
            );
        }

        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')}: <br><br> ${stringError}`, 'warning');
            flag = false;
        }
        if (!super.isEMailValid(this.colaborators.email)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningMsgEmail')}: <br><br> ${stringError}`, 'warning');
            flag = false;
        }
        if (this.model.status !== 'review') {
            if (this.dataSolicitation.colaborators.length > 1) {
                const ind = this.dataSolicitation.colaborators.findIndex(o => o.isRequester);
                if (ind > -1) {
                    this.dataSolicitation.colaborators[ind] = this.colaborators;
                }
            } else {
                this.colaborators.isRequester = true;
                this.dataSolicitation.colaborators = [this.colaborators];
            }
        }
        return flag;
    }

    stepColaboratorsValidate(): boolean { // VALIDAR SE O USUARIO INSERIU COLABORADORES
        let flag = true;
        if (this.dataSolicitation.colaborators.length === 1) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningColaborators')}`, 'warning');
            flag = false;
        }
        return flag;
    }

    stepCoursesValidate(): boolean {
        let flag = true;
        const arrValidateFields = [
            {value: this.dataSolicitation.course.name, text: this.translate.instant('nome') + '* <br />'},
            {value: this.dataSolicitation.course.start, text: this.translate.instant('dataini') + '* <br />'},
            {value: this.dataSolicitation.course.end, text: this.translate.instant('datater') + '* <br />'},
            {
                value: this.dataSolicitation.course.workload,
                text: this.translate.instant('solicitacaotreinamento.cargahor') + '* <br />'
            },
            {
                value: this.dataSolicitation.course.value,
                text: this.translate.instant('solicitacaotreinamento.valormensa') + '* <br />'
            }
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')}: <br><br> ${stringError}`, 'warning');
            flag = false;
        }
        return flag;
    }

    stepInstitutionValidate(): boolean {
        let flag = true;
        const arrValidateFields = [
            {
                value: this.dataSolicitation.institution.name,
                text: this.translate.instant('solicitacaotreinamento.nomeinstitui') + '* <br />'
            },
            {
                value: this.dataSolicitation.institution.email,
                text: this.translate.instant('solicitacaotreinamento.emailcontato') + '* <br />'
            },
            {
                value: this.dataSolicitation.institution.cnpj_cpf,
                text: this.documentType + '* <br />'
            },
            {
                value: this.dataSolicitation.institution.phone,
                text: this.translate.instant('solicitacaotreinamento.telcontato') + '* <br />'
            },
            {
                value: this.dataSolicitation.institution.responsible,
                text: this.translate.instant('solicitacaotreinamento.nomeres') + '* <br />'
            }
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'),
                `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')}: <br><br> ${stringError}`, 'warning');
            flag = false;
        }
        if (!this.isEMailValid(this.dataSolicitation.institution.email)) {
            flag = false;
        }
        return flag;
    }

    onColaboratorsAdd(): void {
        if (!this.addColaboratorsValidate()) {
            return;
        }
        this.dataSolicitation.colaborators.push(this.modelColaborator);
        this.modelColaborator = new CreateColaboratorInput();
        this.selectedItemsUnitiesColaborators = [];
        this.selectedItemsGenderColaborators = [];
        this.selectedItemsSkinColaborators = [];
        this.selectedItemsSexualOrientationColaborators = [];
        $('#contributors').modal('hide');
    }

    onColaboratorsRemove(re: string): void {
        super.confirmMessage('Atenção', 'Deseja realmente excluir esse colaborador?', () => {
            const el = this.dataSolicitation.colaborators.filter((x) => x.re === re)[0];
            const index = this.dataSolicitation.colaborators.indexOf(el);
            if (index > -1) {
                this.dataSolicitation.colaborators.splice(index, 1);
            }
        });
    }

    addColaboratorsValidate(): boolean {
        let flag = true;

        this.modelColaborator.admissionDate = this.collaborator.admissionDate;

        const arrValidateFields = [
            {value: this.modelColaborator.name, text: this.translate.instant('nome') + '* <br />'},
            {value: this.modelColaborator.re, text: 'RE* <br />'}, {
                value: this.modelColaborator.email,
                text: this.translate.instant('E-mail') + '* <br />'
            },
            {value: this.modelColaborator.unity?.id, text: this.translate.instant('bolsa.unidade') + '* <br />'},
            {value: this.modelColaborator.areaManager, text: this.translate.instant('bolsa.departamento') + '* <br />'},
            {value: this.modelColaborator.ramal, text: this.translate.instant('bolsa.ramal') + '*<br />'},
            {value: this.modelColaborator.currentJob, text: this.translate.instant('bolsa.cargo') + '* <br />'},
            {value: this.modelColaborator.admissionDate, text: this.translate.instant('bolsa.admissao') + '* <br />'},
            {value: this.modelColaborator.costCenter, text: this.translate.instant('bolsa.centro') + '* <br />'},
            {value: this.modelColaborator.directorArea, text: this.translate.instant('bolsa.gerente') + '* <br />'},
            // {value: this.modelColaborator.skinColor, text: this.translate.instant('bolsa.CorDaPele') + '* <br />'},
            // {value: this.modelColaborator.genderIdentity, text: this.translate.instant('bolsa.genero') + '* <br />'},
            // {
            //     value: this.modelColaborator.sexualOrientation,
            //     text: this.translate.instant('bolsa.orientacao') + '* <br />'
            // },
            {value: this.modelColaborator.dateOfBirth, text: this.translate.instant('bolsa.nascimento') + '* <br />'},
        ];
        const stringError = this.validateField(arrValidateFields);
        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningRequiredFieldsIncomplete')}: <br><br> ${stringError}`, 'warning');
            flag = false;
        }
        // const el = this.dataSolicitation.colaborators.filter((x) => x.re === this.modelColaborator.re);
        // if (!super.isNullOrUndefined(el)) {
        //     this.showMessage(this.translate.instant('baseComponent.warningAttention'),
        //         this.translate.instant('baseComponent.warningREadd'), 'warning');
        //     flag = false;
        // }
        if (!super.isEMailValid(this.modelColaborator.email)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningMsgEmail')}: <br><br> ${stringError}`, 'warning');
            flag = false;
        }
        return flag;
    }

    onCreateSolicitation(): void {
        // @ts-ignore
        this.dataSolicitation.institution.cnpj = undefined;
        this.createSolicitationGQL.mutate({data: this.dataSolicitation}).subscribe(
            ({data}) => {
                const a = data.createSolicitation as Solicitation;
                this.numberSolicitation = a.code;
                this.actionUploadImage(a.id);
                this.showModal('approved');
            }, (err) => {
                this.toggleLoaderCustom(false);
            }
        );
    }

    handlerUploadSingle(event, type, element) {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 50000000) {
                super.showMessage(this.translate.instant('baseComponent.warningAttention'),
                    this.translate.instant('baseComponent.warningMsgUploadFileMaxSize'), 'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (file) => {
            // this.pathImage = file.target.result;
            this.pathImageObj[element].path = file.target.result;
            this.pathImageObj[element].name = files[0].name;
            this.listFiles = this.listFiles.filter((item) => item.type !== type);
            this.listFiles.push({file: files[0], path: file.target.result, type});
        };
        reader.readAsDataURL(event.target.files[0]);
    }

    actionUploadImage(id: string, router = '/solicitacoes') {
        if (this.jumpRequester === 0) {
            this.paintScreen.file = null;
            this.handlerUploadList(id).then(() => this.handlerFinish(router));
        }
        this.userService.upload(id, 'signature', this.paintScreen.file, 'signature').subscribe((data1) => {
            this.handlerUploadList(id).then(() => this.handlerFinish(router));
        }, error => super.onError(error));
    }

    async handlerUploadList(id): Promise<void> {
        for (const file of this.listFiles) {
            try {
                await this.userService.upload(id, file.type, file.file).toPromise();
                console.log(`Upload do arquivo ${file.name} concluído.`);
            } catch (error) {
                console.error(`Erro ao fazer upload do arquivo ${file.name}:`, error);
            }
        }
    }

    handlerFinish(router: string) {
        this.isUpload = true;
        $('#success').modal('show');
        this.dataSolicitation = new CreateSolicitationInput();
        this.router.navigate([router]);
        setTimeout(() => {
            this.toggleLoaderCustom(false);
        }, 500);
    }

    handlerFinishEditForm(router: string) {
        this.isUpload = true;
        setTimeout(() => {
            this.router.navigate([router]);
            this.toggleLoaderCustom(false);
        }, 500);
    }

    onSaveSolicitation() {
        this.toggleLoaderCustom(true);
        if (this.isNullOrUndefined(this.model?.id)) {
            this.onCreateSolicitation();
        } else {
            this.onUpdateSolicitation();
        }
    }

    onUpdateSolicitation() {
        // @ts-ignore
        this.dataSolicitation.institution.cnpj = undefined;
        const update = {...this.dataSolicitation, id: this.model.id} as UpdateSolicitationInput;
        this.updateSolicitationGQL.mutate({data: update}).subscribe(
            ({data}) => {
                const a = data.updateSolicitation as Solicitation;
                this.numberSolicitation = a.code;
                this.actionUploadImage(a.id, '/lista-de-solicitacoes');
                this.showModal('approved');
            }, (err) => {
                this.toggleLoaderCustom(false);
            }
        );
    }

    onUpdateSolicitationEditAdmin(): void {
        const update = {...this.dataSolicitation, id: this.model.id} as UpdateSolicitationAdminInput;

        this.updateSolicitationAdminGQL.mutate({data: update}).subscribe(({data}) => {
                // @ts-ignore
                this.dataSolicitation.institution.cnpj = undefined;
                const a = data.updateSolicitationAdmin as Solicitation;
                this.numberSolicitation = a.code;
                this.handlerUploadList(a.id).then(() => this.handlerFinishEditForm('/lista-de-solicitacoes'));
                this.showModal('approved');
            },
            (err) => {
                this.toggleLoaderCustom(false);
            }
        );
    }

    removeFile(type: string, element: string) {
        this.pathImageObj[element].name = '';
        this.pathImageObj[element].path = '';
        for (let i = 0; i < this.listFiles.length; i++) {
            if (this.listFiles[i].type === type) {
                this.listFiles.splice(i, 1);
                i--;
            }
        }
    }

    saveConfirm() {
        $('#resume-treinamentos').modal();
    }

    getSkinById(id: string) {
        return this.dropdownListSkin.filter(x => x.id === id)[0]?.name;
    }

    getIdentityGenderById(id: string) {
        return this.dropdownListGender.filter(x => x.id === id)[0]?.name;
    }

    getSexualOrientationById(id: string) {
        return this.dropdownListSexualOrientation.filter(x => x.id === id)[0]?.name;
    }

}
