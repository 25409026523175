import { Component, OnInit } from '@angular/core';
import {BaseComponent} from "../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {SolicitationLogService} from "../../service/solicitation-log.service";
import {
  ChangeSolicitationStatusGQL,
  CreateAdminObservationGQL,
  FlowChangeGQL,
  GetSolicitationQuery,
  ReturnActionStepGQL,
  Solicitation,
  SolicitationLogByIdGQL,
  UpdateColaboratorStatusGQL,
  User
} from "../../../generated/graphql";
import {NgxSpinnerService} from "ngx-spinner";
import {UserService} from "../../service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {QueryRef} from "apollo-angular";
import {ChangeColaborator, ChangeSolicitation} from "../../model/model";

@Component({
  selector: 'app-log-idioma',
  templateUrl: './log-idioma.component.html',
  styleUrls: ['./log-idioma.component.scss']
})
export class LogIdiomaComponent extends BaseComponent implements OnInit {

  solicitationLog: Solicitation = new Solicitation();
  parmId: string;
  user: User = new User();
  showTooltip: boolean[] = [];
  yearNow: any;
  solicitationQuery: QueryRef<GetSolicitationQuery>;
  documentType = 'CNPJ';
  flagLanguageCouses = false;

  constructor(public router: Router,
              public router2: ActivatedRoute,
              private solicitationLogService: SolicitationLogService,
              public loading: NgxSpinnerService,
              public userService: UserService,
              public translate: TranslateService) {
    super(router, translate);
  }

  ngOnInit() {

    this.router2.params.subscribe(url => {
          if (!this.isNullOrUndefined(url)) {
            this.parmId = url.id;
            this.userService.user.subscribe({
              next: data => {
                this.user = data;
                this.getSolicitationLog();
              }, error: err => super.onError(err)
            });

            this.yearNow = (new Date()).getFullYear();

            this.handlerTitlesBoxVertical();
            setTimeout(() => {
              this.handlerTitlesBoxVertical();
            }, 1000);
          }
        }
    );
  }

  getSolicitationLog() {
    this.solicitationLogService.getSolicitationLog(this.parmId).subscribe(solicitation => {
      this.solicitationLog = solicitation;
      this.handlerFlagLanguageCoures();
      console.log('SOLICITATION LOG', solicitation);
    });
  }

  svgType(type) {
    switch (type) {
      case 'xlsx':
      case 'xls':
        return 'assets/svg/icon-metro-file-excel.svg';
      case 'docx':
      case 'doc':
      case 'txt':
        return 'assets/svg/icon-metro-file-word.svg';
      case 'png':
      case 'jpg':
        return 'assets/svg/icon-metro-file-image.svg';
      case 'ppt':
      case 'pptx':
        return 'assets/svg/icon-metro-file-powerpoint.svg';
      default:
        return 'assets/svg/icon-metro-file-pdf.svg';
    }
  }

  getNameRejected() {
    let lastRejectedUser = null;
    for (let i = this.solicitationLog?.solicitationStep.length - 1; i >= 0; i--) {
      const step = this.solicitationLog?.solicitationStep[i];
      if (step.status === 'rejected') {
        lastRejectedUser = step.user?.name;
        break;
      }
    }
    return lastRejectedUser;
  }

  handlerFlagLanguageCoures() {
    let countFill = 0;
    this.solicitationLog.languageSchool.map((x) => {
      if (!this.isNullOrUndefined(x.name)) {
        countFill++;
      }
    });
    this.flagLanguageCouses = countFill > 0;
  }

  onShowTooltip(index: number, show: boolean): void {
    this.showTooltip[index] = show;
  }

}
